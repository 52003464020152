export const getters = {
  getMigrationOptionsCustomerAssets: (state) => {
    return state.migrationOptionsCustomerAssets;
  },
  getMigrationPreferenceCustomerAssets: (state) => {
    return state.migrationPreferenceCustomerAssets;
  },
  getZbMoneyAccountData: (state) => {
    return state.zbMoneyAccountData
  }
}

export default getters;
