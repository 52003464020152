export const AUTOMATION_STATUSES = {
  failureStatuses: [
    'incorrect_data_format',
    'incorrect_entity_mapping',
    'missing_required_field',
    'response_validation_failed',
    'unexpected_website_response'
  ],
  initialStatuses: [
    'created',
    'started'
  ],
  retryStatuses: [
    'all_accounts_in_use',
    'entity_name_unacceptable',
    'failed_to_process_request',
    'filing_system_unavailable',
    'finished',
    'incorrect_email_address',
    'login_failed',
    'sos_payment_failed_system_error',
    'sos_payment_failed_unknown',
    'sos_timed_out'
  ],
  runningStatuses: [
    'running',
    'sent_to_partner',
  ],
  successStatuses: [
    'sos_filing_successful'
  ]
}

export const ENTITY_CHECK_AVAILABILITY = {
  jurisdictions: {
    'CA': ['llc', 'wfg_initial_report']
  }
}

// Consider FAUTO-17 and tech debt 
export const SOI_ORDER_TASK_TYPES = {
  wfg_initial_report: "soi_wfg_initial_report",
  llc_annual_report: "soi_llc_annual_report",
  llc_amendment: "soi_llc_amendment",
  change_of_registered_agent: "soi_cora",
};

export const AUTOMATED_SOI_ORDER_TASK_TYPES = {
  wfg_initial_report: "soi_wfg_initial_report",
  llc_annual_report: "soi_llc_annual_report",
};

export const ORDER_TASK_TYPES_ALLOWED_FOR_AUTOMATION = [
  'corp_annual_report',
  'llc_annual_report',
  'llc_formation',
  'standard_good_standing',
]

export const QUEUE_LOGIN_ITEMS_SUBSTATUS = {
  proccessing_request_acccess: "proccessing_request_acccess",
  pending_grant_access: "pending_grant_access",
  processing_grant_access: "processing_grant_access",
  failed_in_started: "failed_in_started",
  failed_processing_request_access: "failed_processing_request_access",
  failed_in_pending_grant_access: "failed_in_pending_grant_access",
  failed_processing_grant_access: "failed_processing_grant_access"
};

export const AUTOMATED_PROCESS_TYPE = {
  EVIDENCE_EXTRACTION: "evidence_extraction",
  REQUEST_AGENCY_LOGIN_ACCOUNT: "request_agency_login_account",
  GRANT_AGENCY_LOGIN_ACCOUNT: "grant_agency_login_account"
};
