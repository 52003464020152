import { generateSortFunction } from '../utils';

export default {
    setPartnerRequests: (state, partnerRequests) => {
      // Sort partner requests by jurisdiction ASC, service ASC
      const partnerRequestSort = generateSortFunction([
        { accessor: (pr) => pr.jurisdiction, ascending: true },
        { accessor: (pr) => pr.partner_type, ascending: true }
      ]);
      partnerRequests.sort(partnerRequestSort);
      state.partnerRequests = partnerRequests;
    },
    setPartnerRequestDomain: (state, partnerRequestDomains) => {
      state.partnerRequestDomains = partnerRequestDomains;
    },
    loadingPartnerRequests: (state, loadingPartnerRequests) => {
      state.loadingPartnerRequests = loadingPartnerRequests;
    }
  };
