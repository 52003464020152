export function initialState () {
  return {
// export const initialState = {
    annualReportEligibility: true,
    domainAvailable: false,
    domainSelected: false,
    businessEntity: {},
    businessEntityName: '',
    businessEntityType: '',
    entity_name: '',
    jurisdiction: '',
    selectedProducts: [],
    order: {
      account: '',
      business_entity_uuid: '',
      order: {
        order_status: 'complete',
        order_items: [],
        order_data: []
      }
    },
    orderUUID: '',
    validationProducts: []
  }
}

export default {
  ...initialState(),

  /* not specific to order */
  allProducts: {},
  // basic_email was removed from activationProducts to facilitate plan upgrades.  is there a reason it should be reinstated?
  activationProducts: ['insurance', 'banking', 'accounting'],
  webToolsProducts: ['domain_name_reg', 'domain_name_privacy', 'basic_email'],
  coupons: [],
  jurisdictionPricing: [],
  plans: {},
  productPricing: [],
  products: {},
  productFees: {},
  productPrices: {},
}
