export const FILING_SUBMISSION_STATUSES = {
  INCOMPLETE: "incomplete",
  PENDING: "pending",
  COMPLETED: "completed",
  FAILED: "failed",
  REJECTED: "rejected",
  FILING_UPDATE_NOT_FOUND: "not_found",
};

export const FILING_SUBMISSION_REQUESTS = {
  PROCESSING: 'processing',
}

export const BUTTON_STATE = {
  ENABLED: true,
  DISABLED: false,
};
