import { initialState } from './state.js'

export const mutations = {
    resetWebServicesState: (state) => {
        Object.assign(state, initialState());
    },
    setBusinessEntityWebsites: (state, websites) => {
      state.websites = websites.results;
    },
    setBusinessEntityDomains: (state, domains) => {
        state.domains = domains.results;
    },
    setBusinessEntityEmails: (state, emails) => {
        state.emails = emails;
    },
    setWebsite: (state, website) => {
        state.website = website;
    },
    setWebsiteDomain: (state, websiteDomain) => {
        state.website.website_domain = websiteDomain;
    },
    setWebsiteUrl: (state, websiteUrl) => {
        state.website.website_url = websiteUrl;
    },
    setZenbusinessDNS: (state, zenbusinessDNS) => {
        state.website.zenbusiness_dns = zenbusinessDNS;
    },
};

export default {};
