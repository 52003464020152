import forEach from 'lodash/forEach';
import isNull from 'lodash/isNull';
import moment from 'moment';
import httpclient from '@/store/httpclient';
import messages from '@/assets/messages';
import router from '@/router';
import { httpRequestErrorHandling } from '@/store/modules/utils';
import state from './state';
import authService from '../../../auth/AuthService';


function addPlanItem(order, productName, price, fees, term, period, orderTasks) {
  const item = {
    service: productName,
    price,
    fees,
    jurisdiction: state.jurisdiction
  };
  let data = {
    service: productName,
    jurisdiction: state.jurisdiction,
    entity_name: state.business_entity_name,
    created_by: authService.getLoggedInEmail()
  };
  if (state.webToolsProducts.includes(productName)) {
    data = orderTasks && orderTasks.order_task_data ? orderTasks.order_task_data : data;
  }

  // 1. If its recurring, set the term and period
  // 2. For annual_report_service, term and period values added manually because
  // api/service-products/prices-and-fees isn’t returning the price object for that product.
  if (term || productName === 'annual_report_service') {
    item.term = order.order_item.term;
    item.period = order.order_item.period;
  }

  // add the contained products to the order
  state.order.order.order_items.push(item);
  state.order.order.order_data.push(data);
}

function addBundleItem(productName, price, fees, term, period) {
  const item = {
    service: productName,
    price,
    fees,
    term,
    period,
    jurisdiction: state.jurisdiction
  };
  let data = {
    service: productName,
    jurisdiction: state.jurisdiction,
    entity_name: state.business_entity_name,
    created_by: authService.getLoggedInEmail()
  };

  // add the contained products to the order
  state.order.order.order_items.push(item);
  state.order.order.order_data.push(data);
}

async function preparePlanOrder(value, businessEntityUuid) {

  const response = await httpclient.get(`/api/order-tasks/business-entity/${businessEntityUuid}`)
    .catch((error) => {
      console.log(error);
    })
  const businessEntityTasks = response.data;
  const planName = value.order_data.service;

  // update all the contents of the plan
  const productsInPlan = state.plans[state.jurisdiction][planName].products;
  for (let i = 0; i < productsInPlan.length; i++) {
    const productName = productsInPlan[i];
    // filters out insurance, banking, and accounting since those are post purchase activation products
    if (!state.activationProducts.includes(productName)) {
      const pricing = state.jurisdictionPricing[productName];

      let orderTasks = businessEntityTasks.filter(task => task.order_task_type == productName);
      orderTasks = orderTasks.length ? orderTasks[0] : [];
      addPlanItem(value, productName, 0, 0, pricing && pricing.term ? pricing.term : 0, pricing && pricing.period ? pricing.period : 0, orderTasks);
    }
  }

  const autoIncludes = state.plans[state.jurisdiction][planName].auto_includes;
  for (let i = 0; i < autoIncludes.length; i++) {
    const productName = autoIncludes[i];
    // filters out insurance, banking, and accounting since those are post purchase activation products
    if (!state.activationProducts.includes(productName)) {
      const pricing = state.jurisdictionPricing[productName];

      let orderTasks = businessEntityTasks.filter(task => task.order_task_type == productName);
      orderTasks = orderTasks.length ? orderTasks[0] : [];
      addPlanItem(value, productName, pricing.price, pricing.fees, pricing.term, pricing.period, orderTasks);
    }
  }

  // Note that we only ever provide the trial end parameter from the plan
  // creation product.  If we ever support trial ends for other products
  // then we'll have to deal with the fact that this is global parameter
  // on the order and not per product.  Further impact of this would be
  // that if multiple products are combined on one order that they would all
  // be set to the same billing trial as what's defined on the plan product
  if (value.setTrialEnd && value.trialEnd) {
    const trialEndDate = Date.parse(value.trialEnd);
    const now = new Date();
    // if trial end is in the past, reset to now + 30 seconds otherwise the order will fail
    if (trialEndDate < now) {
      value.trialEnd = new Date(now.getTime() + 30000).toISOString();
    }

    state.order.order.trial_end = moment(new Date(value.trialEnd)).format('YYYY-MM-DD HH:mm:ssZZ');

  } else {
    delete state.order.order.trial_end;
  }
}

export default {
  fetchProducts({ commit }) {
    return httpclient.get('/api/service-products')
      .then((response) => {
        commit('setProducts', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchProductsV2({ commit }) {
    return httpclient.get('/api/v2/service-products')
      .then((response) => {
        commit('setProducts', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchPlans({ commit }) {
    return httpclient.get('/api/service-products/plans')
      .then((response) => {
        commit('setPlans', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchProductPricing({ commit }) {
    return httpclient.get('/api/service-products/prices-and-fees')
      .then((response) => {
        commit('setProductPricing', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchProductFees({ commit }, { product, jurisdiction }) {
    if (!product || !jurisdiction) return
    return httpclient.get(`/api/service-products/service-fees?service=${product}&state=${jurisdiction}`)
      .then((response) => {
        commit('setProductFees', { fees: response.data, product });
        return { success: true, fees: response.data };
      })
      .catch((e) => {
        httpRequestErrorHandling(e, commit)
        return { success: false, fees: [] };
      });
  },
  fetchProductPrices({ commit }, { product, affiliate }) {
    if (!product) return
    return httpclient.get(`/api/v3/affiliate-pricing?related_service_pricing_service=${product}${affiliate?'&affiliate='+affiliate:''}`)
      .then((response) => {
        commit('setProductPrices', { prices: response.data.results, product });
        return { success: true, prices: response.data.results };
      })
      .catch((e) => {
        httpRequestErrorHandling(e, commit);
        return { success: false, prices: [] };
      });
  },
  clearProductFeesAndPrices({commit}) {
    commit('clearProductPrices');
    commit('clearProductFees');
  },
  fetchCoupons({ commit }) {
    return httpclient.get('/api/coupons')
      .then((response) => {
        commit('setCoupons', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  checkDomainAvailability({ commit }, { domainName }) {
    return httpclient.get(`/api/v2/domains/is-available?n=${domainName}`)
      .then((response) => {
        // TODO Fix response parsing to be more predictable. It should use the explicit
        // response of the API to determine its output. Right now if the URL starts
        // returning HTML, we see all as available.
        if (response === undefined) {
          commit('setdomainAvailable', 'unavailable');
        } else if (response.status === 200) {
          if (response.data?.available) {
            commit('setdomainAvailable', 'available');
          } else {
            commit('setdomainAvailable', 'unavailable');
          }
        } else {
          commit('setdomainAvailable', 'unavailable');
        }
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  isNewBusinessEntity({ commit }, { jurisdictionFilingDate }) {
    let isNewBE = true;
    if (jurisdictionFilingDate) {
      const filingDateYear = Number(jurisdictionFilingDate.substr(0, jurisdictionFilingDate.indexOf('-')));
      const currentYear = new Date().getFullYear();

      const fd = new Date(jurisdictionFilingDate);
      const arCutoffDate = new Date(currentYear.toString().concat('-12-01'));

      isNewBE = (filingDateYear === currentYear) && (fd < arCutoffDate);
      if (isNewBE) {
        commit('setAnnualReportEligibility', !isNewBE);
        commit('setAlertMessage', messages.createOrder.annualReportEligibility, {root: true});
      }
    }
    return isNewBE;
  },
  checkAnnualReportEligibility({ commit }, { entityType, jurisdictionCode }) {
    return httpclient.get(`api/compliance/check-eligibility/annual_report/${entityType}/${jurisdictionCode}`)
      .then((response) => {
        if (response.status === 200) {
          commit('setAnnualReportEligibility', response.data.eligible);

          if (!response.data.eligible) {
            commit('setAlertMessage', messages.createOrder.annualReportEligibility, { root: true });
          }
        } else {
          commit('setAnnualReportEligibility', true);
        }
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  async prepareOrder({ commit }, { productData }) {
    const referenceDataPromises = [];
    forEach(productData, (value) => {
      if (isNull(value)) return; // continue
      value.order_data.entity_name = state.businessEntityName;
      value.order_data.business_entity_name = state.businessEntityName;
      value.order_data.created_by = authService.getLoggedInEmail();
      state.order.order.order_data.push(value.order_data);
      state.order.order.order_items.push(value.order_item);

      if (value.isEinCreation) {
        commit('setOrderStatus', 'incomplete');
      }

      if (value.isOperatingAgreement) {
        value.order_data.business_entity_type = state.businessEntityType.toUpperCase();
        value.order_data.entity_type = state.businessEntityType;
        value.order_data.business_entity_name = state.businessEntityName;
      }

      if (value.isWebToolsBundle) {
        const websiteToolsNonPRProducts = ['static_website']
        for(let i = 0; i < websiteToolsNonPRProducts.length; i++) {
          addBundleItem(websiteToolsNonPRProducts[i], 0, 0, value.order_item.term, value.order_item.period)
        }
      }

      if (value.isPlan) {
        referenceDataPromises.push(
            preparePlanOrder(value, state.businessEntity.business_entity_uuid)
        );
      }
    });
    await Promise.all(referenceDataPromises);
  },
  fetchPrices({ commit }) {
    return httpclient.get('/api/v3/affiliate-pricing')
      .then((response) => {
        commit('setProductPricing', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  createOrder({ commit }) {
    state.order.order.order_tracking = {
      flow_name: 'zb',
      device_type: 'desktop',
      purchase_source: 'zengarden',
      test_id: null,
      created_by: authService.getLoggedInEmail()
    };
    return httpclient.post('/api/v2/orders', state.order)
      .then((response) => {
        commit('setAlertMessage', messages.createOrder.success, { root: true });
        router.push({ name: 'Order', params: { uuid: response.data.order_uuid } });
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 402) {
          commit('setAlertMessage', messages.createOrder.cardError, { root: true });
        } else {
          httpRequestErrorHandling(error, commit, messages.createOrder)
        }
      });
  },
  setBadOrderAlert({ commit }) {
    commit('setAlertMessage', messages.createOrder.dataError, { root: true });
  },
  clearBadOrderAlert({ commit }) {
    commit('setAlertMessage', '', { root: true });
  },
  setBadOrderDomainAlert({ commit }) {
    commit('setAlertMessage', messages.createOrder.domainError, { root: true });
  },
  resetOrderData({ commit }) {
    commit('resetOrderData');
  },
  addValidationProduct({ commit }, validationProduct) {
    commit('addValidationProduct', validationProduct);
  },
  removeValidationProduct({ commit }, validationProduct) {
    commit('removeValidationProduct', validationProduct);
  },
  updateValidationProduct({ commit }, validationProduct) {
    commit('updateValidationProduct', validationProduct);
  }
};

