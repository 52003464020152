import httpclient from '../../httpclient';

export default {
  fetchVersions({ commit }) {
    return httpclient.get('/api/versions')
      .then((response) => {
        commit('setVersions', response.data);
      });
  }
};
