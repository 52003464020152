import axios from 'axios';
import { v4 as uuid } from 'uuid';

import authService from '../auth/AuthService';

axios.interceptors.request.use((config) => {
  // Check for expired session before sending requests
  if (authService.isAuthenticated()) {
    return config;
  }

  // Log user out if expired
  authService.logout({
    redirectTo: window.location.pathname
  });

  throw new axios.Cancel();
}, (error) => Promise.reject(error));

axios.interceptors.response.use((response) => response, (error) => {
  const originalRequest = error.config;

  // Check for session expiration in API response, logout if expired
  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    return authService.logout({
      redirectTo: window.location.pathname
    });
  }

  return Promise.reject(error);
});

const extendConfig = (config) => {
  const token = authService.getAuthToken();

  const headers = {
    'content-type': 'application/json',
    'x-idempotency-key': uuid()
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return {
    headers,
    ...config
  };
};

export default {
  get: async (path, config) => axios.get(path, extendConfig(config, path)),
  delete: async (path, config) => axios.delete(path, extendConfig(config)),
  put: async (path, payload, config) => axios.put(path, payload, extendConfig(config)),
  patch: async (path, payload, config) => axios.patch(path, payload, extendConfig(config)),
  post: async (path, payload, config) => axios.post(path, payload, extendConfig(config)),
}
