import Vue from 'vue';
import Vuex from 'vuex';
import { createResource, PaginationPlugin, resource } from 'vuex-pagination';
import { parseResponseError } from '../../../components/utils/global-utils.js';
import { allSettled, httpRequestErrorHandling, validateParams } from '../utils';
import cloneDeep from 'lodash/cloneDeep';
import messages from '../../../assets/messages';
import httpclient from '../../httpclient';

Vue.use(Vuex);
Vue.use(PaginationPlugin);

export default {
  fetchServiceFees({ commit }) {
    return httpclient.get('/api/service-products')
      .then((response) => {
        commit('setProducts', response.data);
        httpclient.get('/api/service-products/service-fees')
          .then((response) => {
            commit('setServiceFees', response.data);
          });
      }).catch((error)=>{
        httpRequestErrorHandling(error, commit)
      })
  },
  fetchServicePricing({ commit }) {
    return httpclient.get('/api/service-products')
      .then((response) => {
        commit('setProducts', response.data);
        httpclient.get('/api/service-products/service-pricing')
          .then((response) => {
            commit('setServicePricing', response.data);
          });
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchServicePricingV2({ commit }) {
    return httpclient.get('/api/v2/service-products/service-pricing?get_all=true')
      .then((response) => {
        commit('setServicePricingV2', response.data.results);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchPlanOptionsV2({ commit }) {
    return httpclient.get('/api/v2/plan-options?get_all=true')
      .then((response) => {
        commit('setPlanOptionsV2', response.data.results);
        commit('setPlanOptions', response.data.results);
        return response;
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  patchPlanOptionsV2({ commit }, { planUuid, payload }) {
    return httpclient
      .patch(`/api/v2/plan-options/${planUuid}`, payload)
      .then((response) => {
        if (response.status !== 200) {
          commit("setAlertMessage", messages.patchPlanOption.error, {
            root: true,
          });
        } else {
          commit("setAlertMessage", messages.patchPlanOption.success, {
            root: true,
          });
        }
      })
      .catch((e) => {
        httpRequestErrorHandling(e, commit);
      });
  },
  fetchPlanProductsV2({ commit }) {
    return httpclient.get('/api/v2/plans?get_all=true')
      .then((response) => {
        commit('setPlanProductsV2', response.data.results);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchAffiliatesV2({ commit }, { deleted }) {
    // I can't believe the API's deleted param is case sensitive but it appears to be, still I prefer to pass around
    // a boolean instead of a string
    return httpclient.get('/api/v2/affiliates', { params: { deleted: deleted ? 'True' : 'False'} })
      .then((response) => {
        if(deleted){
          commit('setDeactivatedAffiliates', response.data);
        } else {
          commit('setAffiliates', response.data);
        }
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  setDeactivatedAffiliates({ commit }, {deactivated_affiliates}) {
    commit('setDeactivatedAffiliates', deactivated_affiliates);
  },
  async fetchServiceProductsV2({ commit }, { fields, getAll }) {
    return httpclient.get('/api/v2/service-products', { params: { fields: fields.join(','), get_all: getAll } })
      .then((response) => {
        commit('setProducts', response.data.results);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  async fetchCouponsV2({ commit }, { fields, getAll }) {
    return httpclient.get('/api/v2/coupons', { params: { fields: fields.join(','), get_all: getAll } })
      .then((response) => {
        commit('setCoupons', response.data.results);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  updateServicePricing({ commit }, data) {
    return httpclient.post('/api/service-products/service-pricing/update-amount', data)
      .then(() => {
        commit('setAlertMessage', messages.updateAmountServicePricing.success, { root: true });
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.updateAmountServicePricing)});
  },
  updateServicePricingV2({ commit }, { uuid, active }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
    } else {
      return httpclient.patch(`/api/v2/service-products/service-pricing/${uuid}`, { active_flag: active })
        .then(() => {
          commit('setAlertMessage', messages.updateAmountServicePricing.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.updateAmountServicePricing)});
    }
  },
  fetchReportFieldConfig({ commit }) {
    return httpclient.get('/api/v2/compliance/report-field-configs')
      .then((response) => {
        commit('setReportFieldConfig', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchReportFieldConfigByJurisdiction({ commit }, { jurisdictionCode }) {
    return httpclient.get(`/api/v2/compliance/report-field-configs?jurisdiction_code=${jurisdictionCode}`)
      .then((response) => {
        commit('setReportFieldConfig', response.data[jurisdictionCode]);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  updateReportFieldConfig({ commit }, data) {
    return httpclient.post('/api/v2/compliance/report-field-configs/edit', data)
      .then(() => {
          commit('setAlertMessage', messages.updateAmmendmentReportFieldConfig.success, { root: true });
        }
      )
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.updateAmmendmentReportFieldConfig)});
  },
  addReportField({ commit }, data) {
    return httpclient.post('/api/v2/compliance/report-field-configs', data)
      .then(() => {
          commit('setAlertMessage', messages.addAmmendmentReportField.success, { root: true });
        }
      )
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.addAmmendmentReportField)});
  },
  createProduct({ commit }, data) {
    return httpclient.post('/api/v2/service-products', data)
      .then((response) => {
          commit('setAlertMessage', messages.createProduct.success, { root: true });
          return response.data;
        })
      .catch((error) => {
        const { response } = error;
        const createProductError = cloneDeep(messages.createProduct.error);
        if (response && response.status !== 500) createProductError.secondary = parseResponseError(response.data);
        httpRequestErrorHandling(error, commit, createProductError)
      });
  },
  fetchAllCoupons({ commit }) {
    return httpclient.get('/api/v2/coupons', { params: { get_all: 'true' } })
      .then((response) => {
        commit('setCoupons', response.data.results);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchAllProducts({ commit }) {
    return httpclient.get('/api/v2/service-products', { params: { get_all: 'true' } })
      .then((response) => {
        commit('setProducts', response.data.results);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  createCoupon({ commit }, data) {
    return httpclient.post('/api/v2/coupons', data)
      .then((response) => {
        commit('setAlertMessage', messages.createCoupon.success, { root: true });
        return response;
      })
      .catch((error) => {
        const { response } = error;
        const createCouponError = cloneDeep(messages.createCoupon.error);
        if (response) {
          const { error: message } = response.data.external_coupon_id[0];
          createCouponError.secondary = message;
        }
        commit('setAlertMessage', createCouponError, { root: true });
      });
  },
  createAffiliate({ commit }, data) {
    return httpclient.post('/api/v2/create-new-affiliate', data)
      .then((response) => {
          commit('setAlertMessage', messages.createAffiliate.success, { root: true });
          return response.data;
        })
      .catch((error) => {
        const { response } = error;
        const createAffiliateError = cloneDeep(messages.createAffiliate.error);
        if (response && response.status !== 500) createAffiliateError.secondary = parseResponseError(response.data);
        commit('setAlertMessage', createAffiliateError, { root: true });
      });
  },
  deactivateAffiliate({ commit }, data) {
    if(!validateParams.UUID(data.affiliate_partner_uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
    } else{
      return httpclient.delete('/api/v2/update-affiliate', {data:{affiliate_partner_uuid:data.affiliate_partner_uuid}})
        .then((response) => {
            commit('setAlertMessage', messages.deactivateAffiliate.success, { root: true });
            return response.data;
          })
        .catch((error) => {
          const { response } = error;
          const deactivateAffiliateError = cloneDeep(messages.deactivateAffiliate.error);
          if (response && response.status !== 500) deactivateAffiliateError.secondary = parseResponseError(response.data);
          commit('setAlertMessage', deactivateAffiliateError, { root: true });
        });
    }
  },
  activateAffiliate({ commit }, data) {
    if(!validateParams.UUID(data.affiliate_partner_uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
    }else {
      return httpclient.patch('/api/v2/update-affiliate', data)
        .then((response) => {
            commit('setAlertMessage', messages.activateAffiliate.success, { root: true });
            return response.data;
          })
        .catch((error) => {
          const { response } = error;
          const activateAffiliateError = cloneDeep(messages.activateAffiliate.error);
          if (response && response.status !== 500) activateAffiliateError.secondary = parseResponseError(response.data);
          commit('setAlertMessage', activateAffiliateError, { root: true });
        });
    }
  },
  createAffiliatePlanOption({ commit }, data) {
    return httpclient.post('/api/v2/affiliate-plan-options', data)
      .then((response) => {
        commit('setAlertMessage', messages.createAffiliatePlanOption.success, { root: true });
        return response;
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.createAffiliatePlanOption)});
  },
  createServicePricing({ commit }, data) {
    return httpclient.post('/api/v2/service-products/service-pricing', data)
      .then((response) => {
        commit('setAlertMessage', messages.createServicePricing.success, { root: true });
        return response;
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.createServicePricing)});
  },
  createPlanOption({ commit }, planOption) {
    return httpclient.post('/api/v2/plan-options', planOption)
      .then((response) => {
        commit('setAlertMessage', messages.createPlanOption.success, { root: true });
        return response;
      })
      .catch((error) => {
        console.log('Request Body:', planOption);
        console.log('Error:', error);
        httpRequestErrorHandling(error, commit, messages.createPlanOption)
      })
  },
  createAffiliatePricing({ commit }, data) {
    return httpclient.post('/api/v2/affiliate-pricing', data)
      .then((response) => {
        commit('setAlert', true, { root: true });
        commit('setAlertMessage', messages.createAffiliatePricing.success, { root: true });
        return response;
      })
      .catch((error) => {
        const { response } = error;
        if(response) {
          const addPricingError = cloneDeep(messages.createAffiliatePricing.error);
          const affiliateError = response.data.affiliate ? response.data.affiliate[0] : null;
          const pricingError = response.data.service_pricing ? response.data.service_pricing[0] : null;
          let { error: message } = affiliateError;
          if (pricingError) {
            message = pricingError;
          }
          addPricingError.secondary = message;
          commit('setAlert', true, { root: true });
          commit('setAlertMessage', addPricingError, { root: true });
        } else {
          httpRequestErrorHandling(error, commit)
        }
      });
  },
  deactivateAffiliatePlan({ commit }, { affiliatePlanOptionUUID }) {
    if(!validateParams.UUID(affiliatePlanOptionUUID)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      const url = `api/v3/deactivate-plan/${affiliatePlanOptionUUID}`
      return httpclient.delete(url)
        .then(() => {
          commit('setAlertMessage', messages.deactivateAffiliatePlanOption.success, { root: true });
        })
        .catch(() => {
          commit('setAlertMessage', messages.deactivateAffiliatePlanOption.error, {root: true})
        });
    }
  },
  createServiceProductPlans({ commit }, data) {
    const createPromises = data.map((datum) => httpclient.post('/api/v2/service-products/plans', datum));
    return allSettled(createPromises)
      .then((results) => {
        const creationFailed = results.some((result) => result.status === 'rejected');
        if (creationFailed) {
          console.warn('Creation of some SPP objects failed unexpectedly');
          return allSettled(results.filter((result) => result.status === 'fulfilled')
            .map((result) => httpclient.delete(`/api/v2/service-products/plans/${result.value.data.service_product_plan_uuid}`)))
            .then((results) => {
              const deletionFailed = results.some((result) => result.status === 'rejected');
              if (deletionFailed) {
                console.error('Failed to delete some SPP objects after creation of others failed!');
                commit('setAlert', true, { root: true });
                commit('setAlertMessage', messages.createServiceProductPlans.cleanupError, { root: true });
              } else {
                commit('setAlert', true, { root: true });
                commit('setAlertMessage', messages.createServiceProductPlans.error, { root: true });
              }
            });
        }
        commit('setAlert', true, { root: true });
        commit('setAlertMessage', messages.createServiceProductPlans.success, { root: true });
      });
  },
  fetchJurisdictionStates({ commit }) {
    return httpclient.get('/api/v2/c-corp-stock/jurisdiction-definitions-states')
      .then((response) => {
        commit('setJurisdictionStates', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchCCorpFields({ commit }, { jurisdiction }) {
    let url = '/api/v2/ccorp-stock-fields?get_all=true'

    if (jurisdiction && jurisdiction.jurisdiction_definition_uuid) {
      url = `${url}?jurisdiction_uuid=${jurisdiction.jurisdiction_definition_uuid}`
    }

    return httpclient.get(url)
      .then((response) => {
        commit('setCCorpJurisdictionFields', {data: response.data.results, jurisdiction});
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  addCCorpField({ commit }, { element, jurisdiction }) {

    const payload = {
      ...element, jurisdiction_uuid: jurisdiction.jurisdiction_definition_uuid
    }

    return httpclient.post('/api/v2/ccorp-stock-fields', payload)
      .then(() => {
        commit('setAlertMessage', messages.ccorpAddFieldMessage.success, { root: true });
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.ccorpAddFieldMessage)});
  },
  updateCCorpField({ commit }, { element, jurisdiction, term }) {
    const payload = {
      ...element, jurisdiction_uuid: jurisdiction.jurisdiction_definition_uuid, term_uuid: term.uuid
    }

    return httpclient.put(`/api/v2/ccorp-stock-fields/${element.field_uuid}`, payload)
      .then(() => {
        commit('setAlertMessage', messages.ccorpUpdateFieldMessage.success, { root: true });
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.ccorpUpdateFieldMessage)});
  },
  deleteCCorpField({ commit }, {field_uuid}) {
    return httpclient.delete(`/api/v2/ccorp-stock-fields/${field_uuid}`)
      .then((response) => {
        commit('setAlertMessage', messages.ccorpDeleteFieldMessage.success, { root: true });
        return response.data;
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchCCorpFees({ commit }, jurisdiction) {
    let url = '/api/v2/ccorp-stock-fees?get_all=true'

    if (jurisdiction && jurisdiction.jurisdiction_definition_uuid) {
      url = `${url}?jurisdiction_uuid=${jurisdiction.jurisdiction_definition_uuid}`
    }

    return httpclient.get(url)
      .then((response) => {
        commit('setCCorpJurisdictionFees', {data: response.data.results, jurisdiction});
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  addCCorpFee({ commit }, { element, jurisdiction }) {
    const payload = {
      ...element, jurisdiction_uuid: jurisdiction.jurisdiction_definition_uuid
    }

    return httpclient.post('/api/v2/ccorp-stock-fees', payload)
      .then(() => {
        commit('setAlertMessage', messages.ccorpAddFeeMessage.success, { root: true });
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.ccorpAddFeeMessage)});
  },
  updateCCorpFee({ commit }, {element, jurisdiction, term}) {
    const payload = {
      ...element, jurisdiction_uuid: jurisdiction.jurisdiction_definition_uuid, term_uuid: term.uuid
    }

    return httpclient.put(`/api/v2/ccorp-stock-fees/${element.fee_uuid}`, payload)
      .then(() => {
        commit('setAlertMessage', messages.ccorpUpdateFeeMessage.success, { root: true });
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.ccorpUpdateFeeMessage)});
  },
  deleteCCorpFee({ commit }, { fee_uuid }) {
    return httpclient.delete(`/api/v2/ccorp-stock-fees/${fee_uuid}`)
      .then((response) => {
        commit('setAlertMessage', messages.ccorpDeleteFeeMessage.success, { root: true });
        return response.data;
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchCCorpTerms({ commit }) {
    return httpclient.get('/api/v2/ccorp-stock-terms?get_all=true')
      .then((response) => {
        commit('setCCorpTerms', response.data.results);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  addCCorpTerm({ commit }, { new_term }) {

    return httpclient.post('/api/v2/ccorp-stock-terms', new_term)
      .then(() => {
        commit('setAlertMessage', messages.ccorpAddTermMessage.success, { root: true });
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.ccorpAddTermMessage)});
  },
  deleteCCorpTerm({ commit }, { term_uuid }) {

    return httpclient.delete(`/api/v2/ccorp-stock-terms/${term_uuid}`)
      .then((response) => {
        commit('setAlertMessage', messages.ccorpDeleteFeeMessage.success, { root: true });
        return response.data;
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  createCCorpCalculator({ commit }, { calculator }) {
    return httpclient.post('/api/v3/c-corp-calculators', calculator)
      .then((response) => {
        commit('setAlertMessage', messages.ccorpCreateCalculatorMessage.success, { root: true });
        resource('ccorpStockCalculators').refresh();
        return {successful: true, uuid: response.data.uuid};
      })
      .catch((e) => {
        httpRequestErrorHandling(e, commit, messages.ccorpCreateCalculatorMessage);
        return {successful: false, uuid: null};
      });
  },
  updateCCorpCalculator({ commit }, { calculator, uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
    } else {
      return httpclient.put('/api/v3/c-corp-calculators/' + uuid, calculator)
        .then((response) => {
          commit('setAlertMessage', messages.ccorpCreateCalculatorMessage.success, {root: true});
          resource('ccorpStockCalculators').refresh();
          return {successful: true, uuid: response.data.uuid};
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit, messages.ccorpCreateCalculatorMessage);
          return {successful: false, uuid: null};
        });
    }
  },
  addFeeToCCorpCalculator({commit}, {ccorpCalculatorUuid, feeUuid}) {
    const url = `/api/v3/c-corp-calculator/fees/${ccorpCalculatorUuid}`
    const payload = {
      "c_corp_fee": feeUuid
    }
    if (!validateParams.UUID(ccorpCalculatorUuid) || !validateParams.UUID(feeUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
    } else {
      return httpclient.post(url, payload)
        .then(() => {
          return true;
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit, messages.ccorpCreateCalculatorMessage);
          return false;
        });
    }
  },
  removeFeeFromCCorpCalculator({commit}, {ccorpCalculatorUuid, feeUuid}) {
    const url = `/api/v3/c-corp-calculator/fees/${ccorpCalculatorUuid}`
    const payload = { data: {
      "c_corp_fee": feeUuid
    }}
    if (!validateParams.UUID(ccorpCalculatorUuid) || !validateParams.UUID(feeUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
    } else {
      return httpclient.delete(url, payload)
        .then(() => {
          return true;
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit, messages.ccorpCreateCalculatorMessage);
          return false;
        });
    }
  },
  addFieldToCCorpCalculator({commit}, {ccorpCalculatorUuid, fieldUuid}) {
    const url = `/api/v3/c-corp-calculator/fields/${ccorpCalculatorUuid}`
    const payload = {
      "c_corp_field": fieldUuid
    }
    if (!validateParams.UUID(ccorpCalculatorUuid) || !validateParams.UUID(fieldUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
    } else {
      return httpclient.post(url, payload)
        .then(() => {
          return true;
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit, messages.ccorpCreateCalculatorMessage);
          return false;
        });
    }
  },
  removeFieldFromCCorpCalculator({commit}, {ccorpCalculatorUuid, fieldUuid}) {
    const url = `/api/v3/c-corp-calculator/fields/${ccorpCalculatorUuid}`
    const payload = { data: {
        "c_corp_field": fieldUuid
      }}
    if (!validateParams.UUID(ccorpCalculatorUuid) || !validateParams.UUID(fieldUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
    } else {
      return httpclient.delete(url, payload)
        .then(() => {
          return true;
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit, messages.ccorpCreateCalculatorMessage);
          return false;
        });
    }
  },
  computeCCorpCalculator({commit}, payload) {
    const url = `/api/v2/c-corp-calculator/compute`
    if (!payload) {
      httpRequestErrorHandling(new Error(messages.validationParameters), commit, messages.validationParameters);
    } else {
      return httpclient.post(url, payload)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit, messages.ccorpComputeCalculatorMessage);
          // We will show more details about the error in the interface.
          throw e;
        });
    }
  },
};

// Resources for paginated records
function getPageFetcher(endpointUrl) {
  async function fetchPage(opts) {
    const params = {
      page: opts.page,
      page_size: opts.pageSize,
      ...opts.args
    };
    return httpclient.get(endpointUrl, { params })
      .then((response) => ({
        total: response.data.count,
        data: response.data.results
      }))
      .catch(() => ({
        total: 0,
        data: []
      }));
  }
  return fetchPage;
}

createResource('affiliatePricing', getPageFetcher('/api/v2/affiliate-pricing'));
createResource('affiliatePricingV3', getPageFetcher('/api/v3/affiliate-pricing'));
createResource('coupons', getPageFetcher('/api/v2/coupons'));
createResource('affiliatePlanOptions', getPageFetcher('/api/v2/affiliate-plan-options'));
createResource('planOptions', getPageFetcher('/api/v2/plan-options'));
createResource('products', getPageFetcher('/api/v2/service-products'));
createResource('servicePricing', getPageFetcher('/api/v2/service-products/service-pricing'));
createResource('serviceProductPlans', getPageFetcher('/api/v2/service-products/plans'));
createResource('ccorpStockCalculators', getPageFetcher('/api/v3/c-corp-calculators'));
