import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import vueHeadful from 'vue-headful';
import VueMaterial from 'vue-material';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VueTruncate from 'vue-truncate-filter';
import SortedTablePlugin from 'vue-sorted-table';
import Multiselect from 'vue-multiselect';
import vSelect from 'vue-select';
import authService from './auth/AuthService';
import manifest from './manifest';
import store from './store';
import router from './router';
import App from './App';
import { initAvo } from './client/avoRudderstack';

import 'vue-material/dist/vue-material.min.css'
import './assets/styles/main.scss';

if (window.Sentry) {
  window.Sentry.configureScope((scope) => {
    scope.setTag('environment', manifest.environment);
  });
}

initAvo();

Vue.config.productionTip = false;
Vue.use(require('vue-moment'));

Vue.use(VueClipboard);
Vue.use(VueMaterial);
Vue.use(VueTruncate);
Vue.use(SortedTablePlugin);
Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      },
      hide: {
        enabled: false
      }
    }
  }
});
Vue.component('tippy', TippyComponent);
Vue.component('vue-headful', vueHeadful);
Vue.component('multiselect', Multiselect);
Vue.component('v-select', vSelect);

Vue.directive('click-outside', {
  bind: function (el, binding, node) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        node.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  watch: {
    $route() {
      // On route check, record activity
      // This only happens for subsequent route changes, not the initial load
      authService.extendSession();
    }
  },
  render: (h) => h(App),
}).$mount('#app');
