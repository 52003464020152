<template>
  <Modal @closeModal="$emit('closeJiraModal')" :showOverflow="true" modalClass="jira-modal-dialog">
    <template v-slot:title>
      JIRA Submission for Triage

    </template>
    <template v-slot:body>
      <ZgGoodJobBanner></ZgGoodJobBanner>
      <br />
      <p class="mb-3">
        <strong>Have you checked the <a href="https://counsl.atlassian.net/wiki/spaces/TEC/pages/1778745500" target="_blank" style="color:blue;">Customer Success FAQ</a>?</strong>   
      </p>
      <div>
        <div class="form-group">
          <label for="problemSummary">Problem summary <em class="is-required">*</em></label>
          <input type="text" class="form-control" id="problemSummary" rows="1" v-model="jiraFormData.summary" ref="summary" 
            placeholder="Example: Customer Dashboard always results in a blank screen and the customer cannot run their business.">
          <p class="form-error">A summary of the problem is required.</p>
        </div>
        <div class="form-group">
          <label for="problemSummary">Describe the problem <em class="is-required">*</em></label>
          <textarea class="form-control" id="problemSummary" rows="4" v-model="jiraFormData.description" ref="description"
            placeholder="Describe the problem with as many details as possible. What was the customer attempting to do?  What did they expect to happen? What actually happened?"></textarea>
          <p class="form-error">A short description of the problem is required.</p>
        </div>
    

      <div class="form-grid">
          <div class="form-group">
          <div class="form-label">
              <label for="systemSource">Website or App <em class="is-required">*</em></label>
              <button class="help-button"><i class="fas fa-question"></i> <span>Is the customer experiencing the problem on the website or in the App?</span></button>
            </div>
            <select class="form-control" id="systemSource" v-model="jiraFormData.systemSource" ref="systemSource">
              <option v-for="systemSource in systemSources" :key="systemSource" :value="systemSource">{{ systemSource }}</option>
            </select>

            <p class="form-error">Please let us know where the problem is being encountered.</p>
          </div>
          <div class="form-group">
            <div class="form-label">
              <label for="platform">Device<em class="is-required">*</em></label>
              <button class="help-button"><i class="fas fa-question"></i> <span>What device is the problem being experienced on?</span></button>
            </div>
            <select class="form-control" id="platform" v-model="jiraFormData.platform">
              <option v-for="platform in platforms" :key="platform" :value="platform">{{ platform }}</option>
            </select>
            <p class="form-error">The impact level is required.</p>
          </div>
        </div>

        <div class="form-grid">
          <div class="form-group">
            <div class="form-label">
              <label for="problemSource">Product/Service/Process <em class="is-required">*</em></label>
              <button class="help-button"><i class="fas fa-question"></i> <span>Select the product, service or process the issue is related to.</span></button>
            </div>
            <select class="form-control" id="problemSource" v-model="jiraFormData.problemSource" ref="problemSource">
              <option v-for="problemSource in problemSources" :key="problemSource" :value="problemSource">{{ problemSource }}</option>
            </select>
            <p class="form-error">Please let us know where the problem is being encountered.</p>
            <p class="form-error">The impact level is required.</p>
          </div>
           <div class="form-group">
            <div class="form-label">
              <label for="browser">Browser<em class="is-required">*</em></label>
              <button class="help-button"><i class="fas fa-question"></i> <span>What browser was the customer using when they experienced the problem?</span></button>
            </div>
            <select class="form-control" id="browser" v-model="jiraFormData.browser">
              <option v-for="browser in browsers" :key="browser" :value="browser">{{ browser }}</option>
            </select>
            <p class="form-error">The impact level is required.</p>
          </div>
        </div>
        <div class="form-grid">
          <div class="form-group">
          <div class="form-label">
              <label for="urgencyLevel">Customer Impact <em class="is-required">*</em>
              </label>
              <button class="help-button"><i class="fas fa-question"></i> <span>How is the customer impacted?
              </span></button>
            </div>
            <select class="form-control" id="urgencyLevel" v-model="jiraFormData.urgencyLevel" ref="urgencyLevel">
              <option v-for="urgency in urgencyLevels" :key="urgency.name" :value="urgency.value" >{{ urgency.name }}</option>
            </select>
            <p class="form-error">The impact level is required.</p>
          </div>
          <div class="form-group">
          <div class="form-label">
            <label for="customerNumber">Number of Customers Impacted <em class="is-required">*</em></label>
            <button class="help-button"><i class="fas fa-question"></i> <span>How many customers are impacted?
              </span></button>
            </div>
            <select class="form-control" id="customerNumber" v-model="jiraFormData.customerNumber" ref="customerNumber">
              <option v-for="customerNumber in customerNumbers" :key="customerNumber.name" :value="customerNumber.value" >{{ customerNumber.name }}</option>
            </select>
            <p class="form-error">The impact level is required.</p>
          </div>
        </div>

        <div class="form-grid">
          <div class="form-group">
          <div class="form-label">
            <label for="businessImpact">Business Impact <em class="is-required">*</em></label>
            <button class="help-button"><i class="fas fa-question"></i> <span>What is the potential risk to Zenbusiness?
              </span></button>
            </div>
            <select class="form-control" id="businessImpact" v-model="jiraFormData.businessImpact" ref="businessImpact">
              <option v-for="businessImpact in businessImpacts" :key="businessImpact.name" :value="businessImpact.value" >{{ businessImpact.name }}</option>
            </select>
            <p class="form-error">The impact level is required.</p>
          </div>
          <div class="form-group">
          <div class="form-label">
            <label for="customerSentiment">Customer Sentiment <em class="is-required">*</em></label>
            <button class="help-button"><i class="fas fa-question"></i> <span>How is the customer feeling?
              </span></button>
            </div>
            <select class="form-control" id="customerSentiment" v-model="jiraFormData.customerSentiment" ref="customerSentiment">
              <option v-for="customerSentiment in customerSentiments" :key="customerSentiment.name" :value="customerSentiment.value" >{{ customerSentiment.name }}</option>
            </select>
            <p class="form-error">The impact level is required.</p>
          </div>
        </div>

        <div class="form-grid">
          <div class="form-group">
            <label for="ZGLink">ZenGarden Link</label>
            <input type="text" class="form-control" id="ZGLink" v-model="jiraFormData.zgLink">
          </div>
          <div class="form-group">
            <label for="SFLink">{{ SFLinkPrefix }} Salesforce Link</label>
            <input type="text" class="form-control" id="SFLink" v-model="jiraFormData.sfLink">
          </div>
        </div>

        <div class="form-grid">
          <div class="form-group">
            <label for="customerEmail">Customer email</label>
            <input type="email" class="form-control" id="customerEmail" v-model="jiraFormData.customerEmail">
          </div>
          <div class="form-group">
            <label for="SFCaseLink">Salesforce Case Link</label>
            <input type="text" class="form-control" id="SFCaseLink" v-model="jiraFormData.sfCaseLink">
          </div>
        </div>
        <div class="form-grid">
          <div class="form-group" style="display: none">
            <label for="attachments">Attachments</label>
            <input type="file" class="form-control" id="attachments" multiple>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <ZgButton
          class="zg-btn-default IF_YOU_ARE_READING_THIS_PLEASE_TELL_CARL_KELLYANNE_SAYS_HELLO"
          :isEnable="!isSubmitting"
          :isSubmitting="isSubmitting"
          :disabled="btnDisabled"
          @click="submitJiraTicket">
          <template v-slot:title>
            Submit
          </template>
        </ZgButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Modal from '../common/Modal.vue'
import ZgButton from '@/components/common/Generics/ZgButton.vue';
import ZgGoodJobBanner from '../jira/GoodJobBanner.vue'
import manifest from '../../manifest'

export default {
  name: 'JiraModal',

  components: { Modal, ZgButton, ZgGoodJobBanner },

  data () {
    return {
      accountUuid: null,
      isSubmitting: false,
      SFLinkPrefix: '',
      problemSources: [
        'Purchase Funnel', 'Payment', 'Post-Purchase Questionnaire', 'Dashboard', 'Login or MFA', 'Automated Email (Receipt, Reminders, Marketing)',
        'Zenbusiness Banking', 'Bank of America','Zenbusiness Money (Essential and Pro)','Zenbusiness Merchant Accounts','Web Tools', 'Education', 'Personalizaton', 'ZenGarden', 'SalesForce',  'Zenbusiness Mobile App'
         
      ],
      systemSources: [
        'Website', 'Native Mobile App'
      ],
      platforms: [
        'Windows Desktop or Laptop', 'Mac Desktop or Laptop', 'ZB Android App', 'ZB iOS App', 'Tablet'
      ],
      browsers: [
        'Chrome', 'Safari', 'Edge', 'Firefox', 'Other'
      ],
      urgencyLevels: [
        {name: 'Customer cannot make a purchase', value: 4},
        {name: 'Customer purchased and now having an issue', value: 3},
        {name: 'CS team cannot fulfill/process order', value: 2},
        {name: 'Other request', value: 1}
      ],
      customerNumbers: [
        {name: '1', value: 1},
        {name: '2-10', value: 2},
        {name: '11-25', value: 3},
        {name: '25+', value: 4}
      ],
      businessImpacts: [
        {name: 'Legal Risk', value: 4},
        {name: 'Financial Risk', value: 3},
        {name: 'Reputation Risk', value: 2},
        {name: 'No Known Risk', value: 1}
      ],
      customerSentiments: [
        {name: 'Calm', value: 1},
        {name: 'Frustrated', value: 2},
        {name: 'Angry', value: 3},
        {name: 'Threatening Cancelation', value: 4}
      ],
      jiraFormData: {
        browser: null,
        customerEmail: null,
        customerNumber: null,
        customerNumberText: null,
        customerSentiment: null,
        customerSentimentText: null,
        businessImpact: null,
        businessImpactText: null,
        description: null,
        platform: null,
        problemSources: null,
        sfLink: null,
        summary: null,
        systemSource: null,
        urgencyLevel: null,
        urgencyLevelText: null,
        zgLink: null,
        sfCaseLink: null,
      }
    }
  },

  computed: {
    ...mapState({
      account: (state) => state.accounts.account,
      businessEntity: (state) => state.businessEntities.businessEntity,
      order: (state) => state.orders.order,
      btnDisabled: function() { 
        return this.jiraFormData.summary === null || this.jiraFormData.description === null || this.jiraFormData.urgencyLevel === null || this.jiraFormData.problemSource === undefined ||
          this.jiraFormData.businessImpact === null || this.jiraFormData.systemSource === null || this.jiraFormData.platform === null || this.jiraFormData.browser === null ||
          this.jiraFormData.customerNumber === null || this.jiraFormData.customerSentiment === null
      }
    })
  },

  methods: {
    ...mapActions({
      fetchAccount: 'accounts/fetchAccount',
      postJiraTicket: 'integrations/postJiraTicket',
      postAttachment: 'integrations/postAttachment'
    }),
    setVmodelsByRoute() {
      const routePath = this.$route.path
      if (routePath.includes('/account/')) {
        this.SFLinkPrefix = 'Account - ';
        this.jiraFormData.sfLink = `${manifest.clients.salesforce.host}lightning/r/${this.account.account_sf_id}/view`;
        this.accountUuid = this.account?.account_uuid;
      }
      if (routePath.includes('/business-entity/')) {
        this.SFLinkPrefix = 'Business Entity - ';
        this.jiraFormData.sfLink = `${manifest.clients.salesforce.host}lightning/r/${this.businessEntity.business_entity_sf_id}/view`;
        this.accountUuid = this.businessEntity?.account_uuid;
      }
      if (routePath.includes('/order/')) {
        this.SFLinkPrefix = 'Opportunity - ';
        this.jiraFormData.sfLink = `${manifest.clients.salesforce.host}lightning/r/${this.order.opportunity_sf_id}/view`;
        this.accountUuid = this.order?.account_uuid;
      }
    },
    setInitialValues() {
      this.setVmodelsByRoute();
      this.jiraFormData.zgLink = window.location.href;

      if (this.accountUuid) {
        // make sure correct account data is synced before continuing
        this.fetchAccount({ accountUuid: this.accountUuid })
          .then(() => {
            this.jiraFormData.customerEmail = this.account.account_email
          });
      }
    },
    submitJiraTicket() {
      // weak validation #HACKATHON
      const requiredFields = [
        'summary',
        'description',
        'problemSource',
        'urgencyLevel',
      ]
      const validate = requiredFields.map(field => {
        const el = this.$refs[field]
        if (this.jiraFormData[field]) {
          if (el.classList.contains('is-error')) el.classList.remove('is-error')
        } else {
          el.classList.add('is-error')
        }
        return this.jiraFormData[field]
      })

      this.customerSentiments.forEach( (sentiment) =>  {
        if (sentiment.value === this.jiraFormData.customerSentiment) {
          this.jiraFormData.customerSentimentText = sentiment.name;
        }
      })
      this.customerNumbers.forEach((number) => {
        if (number.value === this.jiraFormData.customerNumber) {
          this.jiraFormData.customerNumberText = number.name;
        }
      })
      this.urgencyLevels.forEach((level) => {
        if (level.value === this.jiraFormData.urgencyLevel) {
          this.jiraFormData.urgencyLevelText = level.name;
        }
      })
      this.businessImpacts.forEach((impact) => {
        if (impact.value === this.jiraFormData.businessImpact) {
          this.jiraFormData.businessImpactText = impact.name;
        }
      })

      if (validate.every( x => x)) {
        this.isSubmitting = true;
        this.postJiraTicket(this.jiraFormData)
        .then((response) => {
            if (response) {
              const filesElement = document.getElementById('attachments')
              const files = filesElement.files
              for (const file of files) {
                const fileInfo = {
                  filename: file.name,
                  contentType: file.type,
                  knownLength: file.size
                }
                const data = new FormData()
                data.append('file', file, fileInfo)
                data.append('fileName', file.name)
                data.append('key', response.data.key)
                this.postAttachment(data)
              }
            }
          })
          .finally( () => {
            this.$emit('closeJiraModal')
            this.isSubmitting = false;
          });
      }
    },
  },

  created()  {
    this.setInitialValues()
  }

};
</script>

<style lang="scss">
  .modal .jira-modal-dialog {
    max-width: 96vw;

    @media (min-width: 768px) {
      max-width: 80vw;
    }

    @media (min-width: 1024px) {
      max-width: 65vw;
    }

    @media (min-width: 1536px) {
      max-width: 50vw;
    }
  }
</style>

<style lang="scss" scoped>
a {
  color: blue
}
.form-group {
    font-size: .875rem;
    margin-bottom: 1rem;
    position: relative;

    .is-required {
      color: $clr-danger;
    }

    label {
      display: block;
      font-size: .875rem;
      font-weight: 500;
      line-height: 1.25rem;
    }
  }

  .form-label {
    display: flex;
    align-items: self-end;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .help-button {
    background: none;
    border-width: 0;
    margin-bottom: -0.125rem;
    padding: 0;

    .fas {
      background: $clr-white;
      border-radius: 1.5rem;
      color: $clr-font-secondary;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .65rem;
      height: 1.5rem;
      opacity: .65;
      width: 1.5rem;
    }

    &:hover {
      .fas {
        background: $clr-primary-ltr;
        color: $clr-primary-dkr;
        opacity: 1;
      }

      span {
        display: block;
      }
    }

    span {
      background: $clr-primary-ltr;
      border-radius: 3px;
      color: $clr-primary-dkr;
      display: none;
      font-size: .75rem;
      font-weight: 500;
      line-height: 1.35;
      margin-top: .2rem;
      padding: em(11) em(14);
      position: absolute;
      right: 1px;
      text-align: left;
    }
  }

  .form-control {
    font-size: .875rem;
    margin-top: 0.25rem;

    &:focus {
      --zg-focus-color: #{$clr-primary};
      border-color: var(--zg-focus-color);
      box-shadow: 0 0 0 0px #fff, 0 0 0 1px var(--zg-focus-color), 0 0 #0000, 0 0 #0000;
      outline: 2px solid transparent;
      outline-offset: 2px;
    }

    &.is-error {
      border-color: $clr-danger;

      &:focus {
        --zg-focus-color: #{$clr-danger};
      }

      + .form-error {
        display: block;
      }
    }
  }

  select.form-control {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    print-color-adjust: exact;
  }

  .form-error {
    color: $clr-font-secondary;
    display: none;
    font-style: italic;
    margin-top: 0.25rem;
  }

  .form-grid {
    @media (min-width: 640px) {
      display: grid;
      grid-template-columns: repeat(2,minmax(0,1fr));
      column-gap: 1rem;
      row-gap: 1.5rem;
    }
  }

  .modal-body {
    a {
      color: $clr-primary-dk;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .modal-footer {
    .zg-btn-default {
      padding: em(7) em(12)
    }
  }

</style>
