<template>
  <div class="row">
    <div class="mx-auto col-12 col-md-8 col-lg-6 d-flex flex-column">
      <CircleLogoTeal logoSize="60" class="mt-3 mt-md-5" />
      <h3 class="mt-3 mt-md-5" v-if="isAuthenticated">Welcome {{ nickname }}</h3>
      <p class="mt-3 mt-md-2 sub-title">Have a great day!</p>
      <DogContactImg class="mt-3 mt-md-5"/>
      <p class="mt-3 mt-md-5"><b>ZenGarden v3.3.3</b></p>
      <p class="mb-5 mb-md-0">Made remotely by ZenBusiness © 2021</p>
    </div>
  </div>
</template>

<script>
import authService from '../../auth/AuthService';
import CircleLogoTeal from '@/components/common/images/CircleLogoTeal.vue';
import DogContactImg from '@/components/common/images/DogContactImg.vue';

export default {
  name: 'Login',

  components: { CircleLogoTeal, DogContactImg },

  computed: {
    nickname() {
      return authService.getFirstName() || 'User';
    },
    isAuthenticated() {
      return authService.isAuthenticated();
    }
  }
};
</script>

<style lang="scss" scoped>
  h3 {
    font-size: rem(40);
    font-weight: 700;
  }
  p {
    padding: 0;
  }
  .sub-title {
    color: $stone-3;
  }
</style>
