export const validateCompanyNameLength = (messages, entities, max_length=60) => {
  const MAX_LENGTH = max_length;
  if(entities) {
    for (const elem of entities) {
      if (elem?.company_name?.length > MAX_LENGTH) {
        messages.push(formatMessage(elem.company_name, MAX_LENGTH));
      }
    }
  }
};

export const validateNumberManagers = (messages, managers) => {
  const MAX_LENGTH = 8;
  if (managers?.length > MAX_LENGTH) {
    messages.push("Too many Managers. Paper filing required.");
  }
};

export const formatMessage = (name, expectedLength) => {
  return `${name} is currently ${name.length} characters long. It cannot exceed ${expectedLength} characters.`;
};

export const validateAddressLength = (messages, entities) => {
  const MAX_LENGTH = 45;
  for (const elem of entities) {
    const address_1 = elem?.address_1;
    const address_2 = elem?.address_2;
    if (!address_1 || !address_2) {
      return;
    }
    let address = address_1;
    if (address_2 && address_2.length > 0) {
      address = `${address}, ${address_2}`;
    }
    if (address.length > MAX_LENGTH) {
      messages.push(formatMessage(address, MAX_LENGTH));
    }
  }
};

export const validateEntityNameLength = (messages, entityName) => {
  const MAX_LENGTH = 120;
  if (entityName?.length > MAX_LENGTH) {
    messages.push(formatMessage(entityName, MAX_LENGTH));
  }
};

export const validateFullnameLengthVariant1 = (messages, entities) => {
  // Validation for managers and members.
  const MAX_LENGTH = 60;
  for (const elem of entities) {
    const firstName = elem?.first_name;
    const lastName = elem?.last_name;
    const middleName = elem?.middle_name;
    const suffix = elem?.suffix;
    if (!firstName || !lastName) {
      return;
    }
    let fullName = `${lastName}, ${firstName}`;
    if (middleName && middleName.length > 0) {
      fullName = `${fullName} ${middleName[0]}`;
    }
    if (suffix && suffix.length > 0) {
      fullName = `${fullName} ${suffix}`;
    }
    if (fullName.length > MAX_LENGTH) {
      messages.push(formatMessage(fullName, MAX_LENGTH));
    }
  }
};

export const validateFullnameLengthVariant2 = (messages, entities) => {
  // Validation for registered agent and authorized signatory.
  const MAX_LENGTH = 60;
  for (const elem of entities) {
    const firstName = elem?.first_name;
    const lastName = elem?.last_name;
    const middleName = elem?.middle_name;
    const suffix = elem?.suffix;
    if (!firstName || !lastName) {
      return;
    }
    let fullName = firstName;
    if (middleName && middleName.length > 0) {
      fullName = `${fullName} ${middleName}`;
    }
    fullName = `${fullName} ${lastName}`;
    if (suffix && suffix.length > 0) {
      fullName = `${fullName}, ${suffix}`;
    }
    if (fullName.length > MAX_LENGTH) {
      messages.push(formatMessage(fullName, MAX_LENGTH));
    }
  }
};

export const returnMissingFields = (fields, object) => {
  return fields.filter((field) => {
    const properties = field.split(".");
    let parameter = object;
    for (const property of properties) {
      const value = parameter[property];
      if (value || typeof value === "boolean") {
        if (typeof value === "string" || value instanceof String) {
          if (value.length === 0) {
            // If the value is string but empty.
            return true;
          }
        }
        // If there are chain of properties.
        parameter = value;
      } else {
        // If there isn't value
        return true;
      }
    }
    return false;
  });
};

export const genericMissingFieldsMessage = (missingFields) => {
  const titleMissingField = `This order cannot be processed until the following fields be complete:`;
  const listMissingFields = missingFields.map((field) => {return `${field}`});
  return {
    main: titleMissingField,
    items: listMissingFields
  }
}