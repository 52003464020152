import cloneDeep from 'lodash/cloneDeep';
import httpclient from '../../httpclient';
import messages from '../../../assets/messages';
import {
  generateDocumentV2, commitResponseToStore, openDocument, validateParams, httpRequestErrorHandling
} from '../utils';
import state from './state';
import moment from 'moment';
import authService from '../../../auth/AuthService';


const automationPrefix = '/api';

export default {
  fetchAutomationConfig({ commit }) {
    return httpclient.get(`${automationPrefix}/v2/automated-filing-config`)
      .then((response) => {
        commit('setAutomationConfig', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchOrderTracking({ commit }, { uuid }) {
    commit('setLoadingOrderTasks', true);
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID);
    } else {
      return httpclient.get(`/api/v2/order-tracking/${uuid}`)
        .then((response) => {
          commit('setOrderTracking', response.data);
          return response.data;
        })
        .catch((e) => {
          if(e.response.status !== 404) {
            httpRequestErrorHandling(e, commit);
          } else {
            console.warn(`Order Tracking ${uuid} not found.`);
          }
          });
    }
  },
  fetchOrders({ commit }) {
    return httpclient.get('/api/orders')
      .then((response) => {
        commit('setOrders', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchOrdersByAccount({ commit }, { accountUuid }) {
    if(!validateParams.UUID(accountUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/orders/account/${accountUuid}`)
        .then((response) => {
          commit('setOrders', response.data);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchOrder({ dispatch, commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/orders/${uuid}`).then((response) => {
        commit('setOrder', response.data);
        const VGS_HOST = window.zgInit.VGS_HOST
        httpclient.get(`${VGS_HOST}/api/v3/business-entities/${state.order.business_entity_uuid}`)
          .then((response) => {
            commit('setBusinessEntity', response.data);
          });
        dispatch('businessEntities/fetchDocuments', { uuid: state.order.business_entity_uuid }, { root: true });
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchSOSStatus({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`${automationPrefix}/v2/automated-filing?order_uuid=${uuid}`)
        .then((response) => {
          commit('setSOSStatus', response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.log('Automated filing job not found.');
          }
          httpRequestErrorHandling(error, commit)
        });
    }
  },
  fetchAutomatedFilingTracking({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      commit('setAutomatedFilingTracking', []);

      return httpclient.get(`${automationPrefix}/v2/automated-filing/${uuid}/tracking`)
        .then(commitResponseToStore(commit, 'setAutomatedFilingTracking'))
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchOrderTasks({ commit }, { uuid }) {
    commit('setLoadingOrderTasks', true);
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/orders/${uuid}/order-tasks`)
        .then((response) => {
          commit('setOrderTasks', response.data);
          return response.data;
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchOrderTask({ commit }, { orderTaskUuid }) {
    if(!validateParams.UUID(orderTaskUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/orders/task/${orderTaskUuid}`)
        .then((response) => {
          commit('setOrderTask', response.data);
          return response?.data;
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchBusinessLicenseReport({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/business-license-reports?business_entity_uuid=${uuid}`)
        .then((response) => {
          commit('setBusinessLicenseReport', response.data);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  createOrderNote({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/orders/${uuid}/notes`, { order_note: state.newOrderNote })
        .then(() => {
          commit('setOrderNoteCreatedBy', null);
          commit('setOrderNoteContent', null);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  cancelOrder({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.delete(`/api/orders/${uuid}`)
        .then(() => {
          commit('setAlertMessage', messages.cancelOrder.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.cancelOrder)});
    }
  },
  downgradeOrder({ commit }, {
    uuid, oldServiceProductUuid, newAffiliatePlanOptionUuid, jurisdiction, refundDifference, subscriptionStartTime, applyCoupon
  }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      const body = {
        old_service_product_uuid: oldServiceProductUuid,
        new_affiliate_plan_option_uuid: newAffiliatePlanOptionUuid,
        jurisdiction,
        refund_difference: refundDifference,
        apply_plan_coupon: applyCoupon
      };
      if (subscriptionStartTime) {
        body.subscription_start_time = moment(new Date(subscriptionStartTime)).format('YYYY-MM-DD HH:mm:ssZZ');
      }
      return httpclient.post(`/api/v2/orders/${uuid}/downgrade`, body)
        .then((response) => {
          const { refunded } = response.data;
          if (refundDifference && !refunded) {
            commit('setAlertMessage', messages.downgradeOrder.refundNotApplied, { root: true });
          } else {
            commit('setAlertMessage', messages.downgradeOrder.success, { root: true });
          }
        })
        .catch((error) => {
          const { response } = error;
          const downgradeOrderError = cloneDeep(messages.downgradeOrder.error);
          if (response.status === 400) {
            const { error: message } = response.data;
            downgradeOrderError.primary = `Unable to downgrade plan in order: ${message}`;
          }
          httpRequestErrorHandling(error, commit, downgradeOrderError)
        });
    }
  },
  cancelOrderTask({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      const cancelData = {
        cancel_reason: 'other',
        cancel_reason_other: 'zg_cancel'
      };
      // TODO Delete should NOT have a payload. This goes against HTTP specification.
      return httpclient.delete(`/api/order-tasks/${uuid}`, { data: cancelData })
        .then(() => {
          commit('setAlertMessage', messages.cancelOrderTask.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.cancelOrderTask)});
    }
  },
  updateOrderTask({ commit }, { orderTask }) {
    if(!validateParams.UUID(orderTask.order_task_uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.patch(`/api/order-tasks/${orderTask.order_task_uuid}`, { order_task_data: orderTask.order_task_data })
        .then((response) => {
          commit('setOrderTask', response.data);
          commit('setAlertMessage', messages.updateOrderTask.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.updateOrderTask)});
    }
  },
  updateOrderSsn({ commit }, { orderUuid, orderSsn }) {
    if(!validateParams.UUID(orderUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.patch(`/api/orders/${orderUuid}/ssn`, { ssn: orderSsn })
        .then(() => {
          commit('setAlertMessage', messages.updateOrderTask.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.updateOrderTask)});
    }
  },
  fetchTransactionalBillingItems({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/billing/order/${uuid}/items`)
        .then((response) => {
          commit('setTransactionalBillingItems', response.data);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.updateOrderTask)});
    }
  },
  fetchRefunds({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/billing/order/${uuid}/refunds`)
        .then((response) => {
          commit('setRefunds', response.data);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchSubscriptionBillingItems({ commit }, { uuid, commitToStore = true }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/billing/order/${uuid}/subscriptions`)
        .then((response) => {
          return commitToStore ? commit('setSubscriptionBillingItems', response.data) : response.data;
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchOrderBillingItemPromotions({ commit }, { orderBillingItemUuid: uuid, commitToStore = true }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/billing/order-billing-items/${uuid}/promotions`)
        .then((response) => {
          return commitToStore ? commit('setOrderBillingItemPromotions', response.data) : response.data;
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchBillingInvoices({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/billing/order/${uuid}/invoices`)
        .then((response) => {
          commit('setBillingInvoices', response.data);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  refundOrderBillingItem({ commit }, { uuid, data }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/billing/order/${uuid}/refund-item`, data)
        .then(() => {
          commit('setAlertMessage', messages.issueRefund().success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.issueRefund(e.response.data))});
    }
  },
  refundAllTransactional({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/billing/order/${uuid}/refund-all-trans`)
        .then(() => {
          commit('setAlertMessage', messages.issueRefund().success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.issueRefund(e.response.data))});
    }
  },
  refundBillingInvoice({ commit }, { uuid, data }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/billing/order/${uuid}/refund-invoice`, data)
        .then((response) => {
          commit('setAlertMessage', messages.issueRefund().success, { root: true });
          return response
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit, messages.issueRefund(e.response.data))
          return e.response
        });
    }
  },
  incompleteOrderReminder({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/email/incomplete-order-reminder/${uuid}`)
        .then(() => {
          commit('setAlertMessage', messages.incompleteOrderReminder.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.incompleteOrderReminder)});
    }
  },
  resendOrderReceipt({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/email/resend-receipt/${uuid}`)
      .then(() => {
        commit('setAlertMessage', messages.resendEmail.success, { root: true });
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.resendEmail)});
    }
  },
  resendWelcomeEmail({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/email/resend-welcome/${uuid}`)
        .then(() => {
          commit('setAlertMessage', messages.resendWelcome.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.resendWelcome)});
    }
  },
  checkCanGenerateFormation({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/documents/api/v1/formation/supported/${uuid}`)
        .then((response) => {
          commit('setCanGenerateFormation', response.data.supported);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  generateFormation({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/documents/api/v1/formation/${uuid}`)
        .then((generateFormationResponse) => {
          commit('setAlertMessage', messages.SOOStatus.success, { root: true });

          // Open Document in new link
          const url = generateFormationResponse.data.file_url;
          let fileHandle = url.split('/')
          fileHandle = fileHandle[fileHandle.length - 1];
          openDocument(generateFormationResponse, fileHandle, url);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  startAutomatedFiling({ commit }, { uuid, test, reloadPage, bypassRaAddressConflict=null,filingType=null }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      const payload = {
        email: authService.getLoggedInEmail(),
        order_task_uuid: uuid,
        test,
      };
      if (filingType) {
        payload['filing_type'] = filingType;
      }
      if (bypassRaAddressConflict === true) {
        payload['bypass_ra_address_verification'] = true;
      }else if(bypassRaAddressConflict === false){
        payload['bypass_ra_address_verification'] = false;
      }

      return httpclient.post(`${automationPrefix}/v2/automated-filing`, payload)
      .then(() => {
        commit('setAlertMessage', messages.automatedSosStatus.success, { root: true });
        if (reloadPage) {
          location.reload();
        }
      })
      .catch((error) => {
        messages.automatedSosStatus.error.secondary = error.response.data;
        httpRequestErrorHandling(error, commit, messages.automatedSosStatus)
      });
    }
  },
  syncAutomatedFiling({ commit }, { uuid }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {

      return httpclient.post(`${automationPrefix}/v3/automated-filing/${uuid}/sync-from-provider`, { test: false })
        .then(() => {
          commit('setAlertMessage', messages.syncAutomatedFilingStatus.success, { root: true });
          location.reload();
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.syncAutomatedFilingStatus)});
    }
  },
  generateSOO({ commit }, { businessEntityUuid }) {
    if(!validateParams.UUID(businessEntityUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      generateDocumentV2(commit, 'soo', {
        business_entity_uuid: businessEntityUuid,
        create_document_obj: true
      }, false);
    }
  },
  generateSS4({ commit }, { orderUuid, businessEntityUuid }) {
    if(!validateParams.UUID(orderUuid) || !validateParams.UUID(businessEntityUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      let designeeName = authService.getNameAndInitialLastNameLetter();
      if (designeeName === undefined) {
        designeeName = '';
      }

      generateDocumentV2(commit, 'ss4', {
        formation_order_uuid: orderUuid,
        designee_name: designeeName,
        business_entity_uuid: businessEntityUuid
      });
    }
  },
  generateF2553({ commit }, { businessEntityUuid }) {
    if(!validateParams.UUID(businessEntityUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      generateDocumentV2(commit, 'f2553', { business_entity_uuid: businessEntityUuid });
    }
  },
  fetchRegisteredAgentAddresses({ commit }) {
    // this originally called /registered-agents/addresses - which is an older, untested endpoint
    // that didn't include contact_uuid
    // addresses also returned the result as a dictionary keyed jurisdiction:registered_agent
    // whereas /contacts just returns all the registered agents in a list
    // to preserve ZenGarden's intended access structure we reduce /addresses back into a dictionary of the expected form
    return httpclient.get('/api/registered-agents/v2/contacts')
      .then(response => {
        let allRAs = response.data.results
        let rasByJurisdiction = allRAs.reduce((intermediateByJuris, ra) => ({...intermediateByJuris, [ra.registered_agent_jurisdiction]: ra}), {})
        commit('setRegisteredAgentAddresses', rasByJurisdiction)
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchCancelAndRefundReasons({ commit }, params) {
    let url = '/api/billing/refund-reasons';
    const typeReason = params?.typeReason;
    if(typeReason) {
      url = `${url}?type_reason=${typeReason}`
    }
    const handleResponse = function (response) {
      const data = response.data;
      if(typeReason == 'cancel') {
        commit('setCancelReasons', data);
      } else if(typeReason == 'refund') {
        commit('setRefundReasons', data);
      } else {
        commit('setCancelAndRefundReasons', data);
      }
      return data;
    };
    handleResponse.bind({typeReason});
    return httpclient.get(url)
      .then(handleResponse)
      .catch((e) => { httpRequestErrorHandling(e, commit)} );
  },
  clearCancelAndRefundReasons({ commit }, { typeReason }) {
    if(typeReason == 'cancel') {
      commit('clearCancelReasons');
    } else if(typeReason == 'refund') {
      commit('clearRefundReasons');
    } else {
      commit('clearCancelAndRefundReasons');
    }
  },
  processCompletedOrder({ commit }, { orderUuid }) {
    if(!validateParams.UUID(orderUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post('/api/v2/orders/process-completed-order', { order_uuid: orderUuid })
        .then(() => {
          commit('setAlertMessage', messages.processCompletedOrder.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.processCompletedOrder)});
    }
  },
  sendOrderToSalesforce({ commit }, { orderUuid }) {
    if(!validateParams.UUID(orderUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post('/api/v2/salesforce/send_order_to_salesforce', { order_uuid: orderUuid })
        .then(() => {
          commit('setAlertMessage', messages.sendOrderToSalesforce.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.sendOrderToSalesforce)});
    }
  },
  generatePartnerRequests({ commit }, { orderUuid }) {
    if(!validateParams.UUID(orderUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/v2/orders/${orderUuid}/process-order-for-partner-requests`)
        .then(() => {
          commit('setAlertMessage', messages.generatePartnerRequests.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.generatePartnerRequests)});
    }
  },
  fetchPlans({ commit }) {
    return httpclient.get('/api/service-products/plans')
      .then((response) => {
        commit('setPlans', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.generatePartnerRequests)});
  },
  editRefundInvoiceReason({ commit }, { uuid, data }) {
    if(!validateParams.UUID(uuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.patch(`/api/billing/refunds/${uuid}`, data)
        .then(() => {
          commit('setAlertMessage', messages.editRefundInvoiceReason.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit, messages.editRefundInvoiceReason)});
    }
  },
  fetchOrderTasksByAccount({ commit }, { accountUuid, isCancledExcluded = true, bypassStoreCommit = false }) {
    if(!validateParams.UUID(accountUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      const handler = bypassStoreCommit ? (response) => response : commitResponseToStore(
        commit,
        'setOrderTasks',
        (response) => response.data.results
      )
      return httpclient.get(`/api/v2/order-tasks?account_uuid=${accountUuid}&exclude_canceled=${isCancledExcluded}&page_size=100`)
        .then(handler)
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchEligibleDowngradePlans({ commit }, {orderTaskUuid}) {
    if (!validateParams.UUID(orderTaskUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      const url = `/api/v2/order-tasks/${orderTaskUuid}/plans-for-downgrade`
      return httpclient.get(url)
        .then((response) => {
          commit('setEligibleDowngradePlans', response.data);
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit, messages.generatePartnerRequests)
        });
    }
  },
  fetchOrderTaskV2({ commit }, { orderTaskUuid }) {
    if (!validateParams.UUID(orderTaskUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      const url = `/api/v2/order-tasks/${orderTaskUuid}`
      return httpclient.get(url)
        .then((response) => {
          commit('setOrderTask', response.data);
          return { successful: true, data: response.data };
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit, messages.generatePartnerRequests);
          return { successful: false, data: null };
        });
    }
  },
  fetchEntityStatusAtSOS({ commit }, { orderTaskUuid }) {
    return httpclient.get(`${automationPrefix}/v1/sos-search/entity-search/${orderTaskUuid}`, {
    })
      .then((response) => {
        commit('setEntityStatusAtSOS', response.data);
      })
      .catch((e) => {
        commit('setEntityStatusAtSOS', []);
        if (e.response.status !== 404) {
          httpRequestErrorHandling(e, commit);
        }
      });
  },
  checkEntityStatusAtSOS({ commit }, { orderTaskUuid, filingNumber, jurisdiction, searchCriteria = "filing_number" }) {
    return httpclient.post(`${automationPrefix}/v1/sos-search/entity-search`, {
      order_task_uuid: orderTaskUuid,
      filing_number: filingNumber,
      jurisdiction_code: jurisdiction,
      search_criteria: searchCriteria
    })
      .then((response) => {
        commit('setAlertMessage', messages.entityStatusAtSOS.success, { root: true });
        commit('setEntityStatusAtSOS', [response.data]);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  clearOrderTask({ commit }) {
    commit('clearOrderTask');
  },
  // 4 actions to manage the reprocess of failed orders
  reprocessOrderWithoutTasks({ commit }) {
    return httpclient.get('/api/v2/orders/reprocess-order-without-tasks')
      .then((response) => {
        commit('setReprocessOrderWithoutTasks', response.data);
      });
  },
  reprocessOrderTasksCreated({ commit }) {
    return httpclient.get('/api/v2/orders/reprocess-order-tasks-created')
      .then((response) => {
        commit('setReprocessOrderTasksCreated', response.data);
      });
  },
  reprocessCompletedOrder({ commit }) {
    return httpclient.get('/api/v2/orders/reprocess-completed-order')
      .then((response) => {
        commit('setReprocessCompletedOrder', response.data);
      });
  },
  missedChargeEvents({ commit }) {
    return httpclient.get('/api/billing/missed-charge-events')
      .then((response) => {
        commit('setMissedChargeEvents', response.data);
      });
  },
  submitOrderBillingItemChange({ commit }, { uuid, changeType }){
    return httpclient.post('/api/v2/order-billing/change', {
      order_billing_item_uuid: uuid,
      change_type: changeType,
      change_source: {
        id: authService.getLoggedInEmail(),
        is_internal: true,
        source: 'zengarden'
      }
    })
      .then((response) => {
        commit('setAlertMessage', messages.submitOrderBillingItemChange.success, { root: true });
        return response
      })
      .catch((e) => {
        httpRequestErrorHandling(e, commit, messages.submitOrderBillingItemChange)
        return e.response
      });
  },
  bulkSendOrdersToSalesforce({ commit }) {
    return httpclient.post("/api/v2/orders/bulk-send-order-to-salesforce")
      .then((response) => {
        commit('setBulkSendOrdersToSalesforce', response.data);
      });
  }
};
