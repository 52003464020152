const NAICS = {
  11: 'Agriculture, Forestry, Fishing and Hunting',
  111110: 'Soybean Farming',
  111120: 'Oilseed (except Soybean) Farming',
  111130: 'Dry Pea and Bean Farming',
  111140: 'Wheat Farming',
  111150: 'Corn Farming',
  111160: 'Rice Farming',
  111191: 'Oilseed and Grain Combination Farming',
  111199: 'All Other Grain Farming',
  111211: 'Potato Farming',
  111219: 'Other Vegetable (except Potato) and Melon Farming',
  111310: 'Orange Groves',
  111320: 'Citrus (except Orange) Groves',
  111331: 'Apple Orchards',
  111332: 'Grape Vineyards',
  111333: 'Strawberry Farming',
  111334: 'Berry (except Strawberry) Farming',
  111335: 'Tree Nut Farming',
  111336: 'Fruit and Tree Nut Combination Farming',
  111339: 'Other Noncitrus Fruit Farming',
  111411: 'Mushroom Production',
  111419: 'Other Food Crops Grown Under Cover',
  111421: 'Nursery and Tree Production',
  111422: 'Floriculture Production',
  111910: 'Tobacco Farming',
  111920: 'Cotton Farming',
  111930: 'Sugarcane Farming',
  111940: 'Hay Farming',
  111991: 'Sugar Beet Farming',
  111992: 'Peanut Farming',
  111998: 'All Other Miscellaneous Crop Farming',
  112111: 'Beef Cattle Ranching and Farming',
  112112: 'Cattle Feedlots',
  112120: 'Dairy Cattle and Milk Production',
  112130: 'Dual-Purpose Cattle Ranching and Farming',
  112210: 'Hog and Pig Farming',
  112310: 'Chicken Egg Production',
  112320: 'Broilers and Other Meat Type Chicken Production',
  112330: 'Turkey Production',
  112340: 'Poultry Hatcheries',
  112390: 'Other Poultry Production',
  112410: 'Sheep Farming',
  112420: 'Goat Farming',
  112511: 'Finfish Farming and Fish Hatcheries',
  112512: 'Shellfish Farming',
  112519: 'Other Aquaculture',
  112910: 'Apiculture',
  112920: 'Horses and Other Equine Production',
  112930: 'Fur-Bearing Animal and Rabbit Production',
  112990: 'All Other Animal Production',
  113110: 'Timber Tract Operations',
  113210: 'Forest Nurseries and Gathering of Forest Products',
  113310: 'Logging',
  114111: 'Finfish Fishing',
  114112: 'Shellfish Fishing',
  114119: 'Other Marine Fishing',
  114210: 'Hunting and Trapping',
  115111: 'Cotton Ginning',
  115112: 'Soil Preparation, Planting, and Cultivating',
  115113: 'Crop Harvesting, Primarily by Machine',
  115114: 'Postharvest Crop Activities (except Cotton Ginning)',
  115115: 'Farm Labor Contractors and Crew Leaders',
  115116: 'Farm Management Services',
  115210: 'Support Activities for Animal Production',
  115310: 'Support Activities for Forestry',
  21: 'Mining, Quarrying, and Oil and Gas Extraction',
  211120: 'Crude Petroleum Extraction',
  211130: 'Natural Gas Extraction',
  212111: 'Bituminous Coal and Lignite Surface Mining',
  212112: 'Bituminous Coal Underground Mining',
  212114: 'Surface Coal Mining',
  212115: 'Underground Coal Mining',
  212210: 'Iron Ore Mining',
  212220: 'Gold Ore and Silver Ore Mining',
  212221: 'Gold Ore Mining',
  212222: 'Silver Ore Mining',
  212230: 'Copper, Nickel, Lead, and Zinc Mining',
  212290: 'Other Metal Ore Mining',
  212291: 'Uranium-Radium-Vanadium Ore Mining',
  212299: 'All Other Metal Ore Mining',
  212311: 'Dimension Stone Mining and Quarrying',
  212312: 'Crushed and Broken Limestone Mining and Quarrying',
  212313: 'Crushed and Broken Granite Mining and Quarrying',
  212319: 'Other Crushed and Broken Stone Mining and Quarrying',
  212321: 'Construction Sand and Gravel Mining',
  212322: 'Industrial Sand Mining',
  212323: 'Kaolin, Clay, and Ceramic and Refractory Minerals Mining',
  212324: 'Kaolin and Ball Clay Mining',
  212325: 'Clay and Ceramic and Refractory Minerals Mining',
  212390: 'Other Nonmetallic Mineral Mining and Quarrying',
  212391: 'Potash, Soda, and Borate Mineral Mining',
  212392: 'Phosphate Rock Mining',
  212393: 'Other Chemical and Fertilizer Mineral Mining',
  212399: 'All Other Nonmetallic Mineral Mining',
  213111: 'Drilling Oil and Gas Wells',
  213112: 'Support Activities for Oil and Gas Operations',
  213113: 'Support Activities for Coal Mining',
  213114: 'Support Activities for Metal Mining',
  213115: 'Support Activities for Nonmetallic Minerals (except Fuels) Mining',
  22: 'Utilities',
  221111: 'Hydroelectric Power Generation',
  221112: 'Fossil Fuel Electric Power Generation',
  221113: 'Nuclear Electric Power Generation',
  221114: 'Solar Electric Power Generation',
  221115: 'Wind Electric Power Generation',
  221116: 'Geothermal Electric Power Generation',
  221117: 'Biomass Electric Power Generation',
  221118: 'Other Electric Power Generation',
  221121: 'Electric Bulk Power Transmission and Control',
  221122: 'Electric Power Distribution',
  221210: 'Natural Gas Distribution',
  221310: 'Water Supply and Irrigation Systems',
  221320: 'Sewage Treatment Facilities',
  221330: 'Steam and Air-Conditioning Supply',
  23: 'Construction',
  236115: 'New Single-Family Housing Construction (except For-Sale Builders)',
  236116: 'New Multifamily Housing Construction (except For-Sale Builders)',
  236117: 'New Housing For-Sale Builders',
  236118: 'Residential Remodelers',
  236210: 'Industrial Building Construction',
  236220: 'Commercial and Institutional Building Construction',
  237110: 'Water and Sewer Line and Related Structures Construction',
  237120: 'Oil and Gas Pipeline and Related Structures Construction',
  237130: 'Power and Communication Line and Related Structures Construction',
  237210: 'Land Subdivision',
  237310: 'Highway, Street, and Bridge Construction',
  237990: 'Other Heavy and Civil Engineering Construction',
  238110: 'Poured Concrete Foundation and Structure Contractors',
  238120: 'Structural Steel and Precast Concrete Contractors',
  238130: 'Framing Contractors',
  238140: 'Masonry Contractors',
  238150: 'Glass and Glazing Contractors',
  238160: 'Roofing Contractors',
  238170: 'Siding Contractors',
  238190: 'Other Foundation, Structure, and Building Exterior Contractors',
  238210: 'Electrical Contractors and Other Wiring Installation Contractors',
  238220: 'Plumbing, Heating, and Air-Conditioning Contractors',
  238290: 'Other Building Equipment Contractors',
  238310: 'Drywall and Insulation Contractors',
  238320: 'Painting and Wall Covering Contractors',
  238330: 'Flooring Contractors',
  238340: 'Tile and Terrazzo Contractors',
  238350: 'Finish Carpentry Contractors',
  238390: 'Other Building Finishing Contractors',
  238910: 'Site Preparation Contractors',
  238990: 'All Other Specialty Trade Contractors',
  31: 'Manufacturing',
  311111: 'Dog and Cat Food Manufacturing',
  311119: 'Other Animal Food Manufacturing',
  311211: 'Flour Milling',
  311212: 'Rice Milling',
  311213: 'Malt Manufacturing',
  311221: 'Wet Corn Milling',
  311224: 'Soybean and Other Oilseed Processing',
  311225: 'Fats and Oils Refining and Blending',
  311230: 'Breakfast Cereal Manufacturing',
  311313: 'Beet Sugar Manufacturing',
  311314: 'Cane Sugar Manufacturing',
  311340: 'Nonchocolate Confectionery Manufacturing',
  311351: 'Chocolate and Confectionery Manufacturing from Cacao Beans',
  311352: 'Confectionery Manufacturing from Purchased Chocolate',
  311411: 'Frozen Fruit, Juice, and Vegetable Manufacturing',
  311412: 'Frozen Specialty Food Manufacturing',
  311421: 'Fruit and Vegetable Canning',
  311422: 'Specialty Canning',
  311423: 'Dried and Dehydrated Food Manufacturing',
  311511: 'Fluid Milk Manufacturing',
  311512: 'Creamery Butter Manufacturing',
  311513: 'Cheese Manufacturing',
  311514: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing',
  311520: 'Ice Cream and Frozen Dessert Manufacturing',
  311611: 'Animal (except Poultry) Slaughtering',
  311612: 'Meat Processed from Carcasses',
  311613: 'Rendering and Meat Byproduct Processing',
  311615: 'Poultry Processing',
  311710: 'Seafood Product Preparation and Packaging',
  311811: 'Retail Bakeries',
  311812: 'Commercial Bakeries',
  311813: 'Frozen Cakes, Pies, and Other Pastries Manufacturing',
  311821: 'Cookie and Cracker Manufacturing',
  311824: 'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour',
  311830: 'Tortilla Manufacturing',
  311911: 'Roasted Nuts and Peanut Butter Manufacturing',
  311919: 'Other Snack Food Manufacturing',
  311920: 'Coffee and Tea Manufacturing',
  311930: 'Flavoring Syrup and Concentrate Manufacturing',
  311941: 'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing',
  311942: 'Spice and Extract Manufacturing',
  311991: 'Perishable Prepared Food Manufacturing',
  311999: 'All Other Miscellaneous Food Manufacturing',
  312111: 'Soft Drink Manufacturing',
  312112: 'Bottled Water Manufacturing',
  312113: 'Ice Manufacturing',
  312120: 'Breweries',
  312130: 'Wineries',
  312140: 'Distilleries',
  312230: 'Tobacco Manufacturing',
  313110: 'Fiber, Yarn, and Thread Mills',
  313210: 'Broadwoven Fabric Mills',
  313220: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
  313230: 'Nonwoven Fabric Mills',
  313240: 'Knit Fabric Mills',
  313310: 'Textile and Fabric Finishing Mills',
  313320: 'Fabric Coating Mills',
  314110: 'Carpet and Rug Mills',
  314120: 'Curtain and Linen Mills',
  314910: 'Textile Bag and Canvas Mills',
  314994: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills',
  314999: 'All Other Miscellaneous Textile Product Mills',
  315110: 'Hosiery and Sock Mills',
  315120: 'Apparel Knitting Mills',
  315190: 'Other Apparel Knitting Mills',
  315210: 'Cut and Sew Apparel Contractors',
  315220: 'Men’s and Boys’ Cut and Sew Apparel Manufacturing',
  315240: 'Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing',
  315250: 'Cut and Sew Apparel Manufacturing (except Contractors)',
  315280: 'Other Cut and Sew Apparel Manufacturing',
  315990: 'Apparel Accessories and Other Apparel Manufacturing',
  316110: 'Leather and Hide Tanning and Finishing',
  316210: 'Footwear Manufacturing',
  316990: 'Other Leather and Allied Product Manufacturing',
  316992: 'Women’s Handbag and Purse Manufacturing',
  316998: 'All Other Leather Good and Allied Product Manufacturing',
  32: 'Manufacturing',
  321113: 'Sawmills',
  321114: 'Wood Preservation',
  321211: 'Hardwood Veneer and Plywood Manufacturing',
  321212: 'Softwood Veneer and Plywood Manufacturing',
  321213: 'Engineered Wood Member (except Truss) Manufacturing',
  321214: 'Truss Manufacturing',
  321215: 'Engineered Wood Member Manufacturing',
  321219: 'Reconstituted Wood Product Manufacturing',
  321911: 'Wood Window and Door Manufacturing',
  321912: 'Cut Stock, Resawing Lumber, and Planing',
  321918: 'Other Millwork (including Flooring)',
  321920: 'Wood Container and Pallet Manufacturing',
  321991: 'Manufactured Home (Mobile Home) Manufacturing',
  321992: 'Prefabricated Wood Building Manufacturing',
  321999: 'All Other Miscellaneous Wood Product Manufacturing',
  322110: 'Pulp Mills',
  322120: 'Paper Mills',
  322121: 'Paper (except Newsprint) Mills',
  322122: 'Newsprint Mills',
  322130: 'Paperboard Mills',
  322211: 'Corrugated and Solid Fiber Box Manufacturing',
  322212: 'Folding Paperboard Box Manufacturing',
  322219: 'Other Paperboard Container Manufacturing',
  322220: 'Paper Bag and Coated and Treated Paper Manufacturing',
  322230: 'Stationery Product Manufacturing',
  322291: 'Sanitary Paper Product Manufacturing',
  322299: 'All Other Converted Paper Product Manufacturing',
  323111: 'Commercial Printing (except Screen and Books)',
  323113: 'Commercial Screen Printing',
  323117: 'Books Printing',
  323120: 'Support Activities for Printing',
  324110: 'Petroleum Refineries',
  324121: 'Asphalt Paving Mixture and Block Manufacturing',
  324122: 'Asphalt Shingle and Coating Materials Manufacturing',
  324191: 'Petroleum Lubricating Oil and Grease Manufacturing',
  324199: 'All Other Petroleum and Coal Products Manufacturing',
  325110: 'Petrochemical Manufacturing',
  325120: 'Industrial Gas Manufacturing',
  325130: 'Synthetic Dye and Pigment Manufacturing',
  325180: 'Other Basic Inorganic Chemical Manufacturing',
  325193: 'Ethyl Alcohol Manufacturing',
  325194: 'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing',
  325199: 'All Other Basic Organic Chemical Manufacturing',
  325211: 'Plastics Material and Resin Manufacturing',
  325212: 'Synthetic Rubber Manufacturing',
  325220: 'Artificial and Synthetic Fibers and Filaments Manufacturing',
  325311: 'Nitrogenous Fertilizer Manufacturing',
  325312: 'Phosphatic Fertilizer Manufacturing',
  325314: 'Fertilizer (Mixing Only) Manufacturing',
  325315: 'Compost Manufacturing',
  325320: 'Pesticide and Other Agricultural Chemical Manufacturing',
  325411: 'Medicinal and Botanical Manufacturing',
  325412: 'Pharmaceutical Preparation Manufacturing',
  325413: 'In-Vitro Diagnostic Substance Manufacturing',
  325414: 'Biological Product (except Diagnostic) Manufacturing',
  325510: 'Paint and Coating Manufacturing',
  325520: 'Adhesive Manufacturing',
  325611: 'Soap and Other Detergent Manufacturing',
  325612: 'Polish and Other Sanitation Good Manufacturing',
  325613: 'Surface Active Agent Manufacturing',
  325620: 'Toilet Preparation Manufacturing',
  325910: 'Printing Ink Manufacturing',
  325920: 'Explosives Manufacturing',
  325991: 'Custom Compounding of Purchased Resins',
  325992: 'Photographic Film, Paper, Plate, and Chemical Manufacturing',
  325998: 'All Other Miscellaneous Chemical Product and Preparation Manufacturing',
  326111: 'Plastics Bag and Pouch Manufacturing',
  326112: 'Plastics Packaging Film and Sheet (including Laminated) Manufacturing',
  326113: 'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing',
  326121: 'Unlaminated Plastics Profile Shape Manufacturing',
  326122: 'Plastics Pipe and Pipe Fitting Manufacturing',
  326130: 'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing',
  326140: 'Polystyrene Foam Product Manufacturing',
  326150: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing',
  326160: 'Plastics Bottle Manufacturing',
  326191: 'Plastics Plumbing Fixture Manufacturing',
  326199: 'All Other Plastics Product Manufacturing',
  326211: 'Tire Manufacturing (except Retreading)',
  326212: 'Tire Retreading',
  326220: 'Rubber and Plastics Hoses and Belting Manufacturing',
  326291: 'Rubber Product Manufacturing for Mechanical Use',
  326299: 'All Other Rubber Product Manufacturing',
  327110: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing',
  327120: 'Clay Building Material and Refractories Manufacturing',
  327211: 'Flat Glass Manufacturing',
  327212: 'Other Pressed and Blown Glass and Glassware Manufacturing',
  327213: 'Glass Container Manufacturing',
  327215: 'Glass Product Manufacturing Made of Purchased Glass',
  327310: 'Cement Manufacturing',
  327320: 'Ready-Mix Concrete Manufacturing',
  327331: 'Concrete Block and Brick Manufacturing',
  327332: 'Concrete Pipe Manufacturing',
  327390: 'Other Concrete Product Manufacturing',
  327410: 'Lime Manufacturing',
  327420: 'Gypsum Product Manufacturing',
  327910: 'Abrasive Product Manufacturing',
  327991: 'Cut Stone and Stone Product Manufacturing',
  327992: 'Ground or Treated Mineral and Earth Manufacturing',
  327993: 'Mineral Wool Manufacturing',
  327999: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing',
  33: 'Manufacturing',
  331110: 'Iron and Steel Mills and Ferroalloy Manufacturing',
  331210: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel',
  331221: 'Rolled Steel Shape Manufacturing',
  331222: 'Steel Wire Drawing',
  331313: 'Alumina Refining and Primary Aluminum Production',
  331314: 'Secondary Smelting and Alloying of Aluminum',
  331315: 'Aluminum Sheet, Plate, and Foil Manufacturing',
  331318: 'Other Aluminum Rolling, Drawing, and Extruding',
  331410: 'Nonferrous Metal (except Aluminum) Smelting and Refining',
  331420: 'Copper Rolling, Drawing, Extruding, and Alloying',
  331491: 'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding',
  331492: 'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)',
  331511: 'Iron Foundries',
  331512: 'Steel Investment Foundries',
  331513: 'Steel Foundries (except Investment)',
  331523: 'Nonferrous Metal Die-Casting Foundries',
  331524: 'Aluminum Foundries (except Die-Casting)',
  331529: 'Other Nonferrous Metal Foundries (except Die-Casting)',
  332111: 'Iron and Steel Forging',
  332112: 'Nonferrous Forging',
  332114: 'Custom Roll Forming',
  332117: 'Powder Metallurgy Part Manufacturing',
  332119: 'Metal Crown, Closure, and Other Metal Stamping (except Automotive)',
  332215: 'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing',
  332216: 'Saw Blade and Handtool Manufacturing',
  332311: 'Prefabricated Metal Building and Component Manufacturing',
  332312: 'Fabricated Structural Metal Manufacturing',
  332313: 'Plate Work Manufacturing',
  332321: 'Metal Window and Door Manufacturing',
  332322: 'Sheet Metal Work Manufacturing',
  332323: 'Ornamental and Architectural Metal Work Manufacturing',
  332410: 'Power Boiler and Heat Exchanger Manufacturing',
  332420: 'Metal Tank (Heavy Gauge) Manufacturing',
  332431: 'Metal Can Manufacturing',
  332439: 'Other Metal Container Manufacturing',
  332510: 'Hardware Manufacturing',
  332613: 'Spring Manufacturing',
  332618: 'Other Fabricated Wire Product Manufacturing',
  332710: 'Machine Shops',
  332721: 'Precision Turned Product Manufacturing',
  332722: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing',
  332811: 'Metal Heat Treating',
  332812: 'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers',
  332813: 'Electroplating, Plating, Polishing, Anodizing, and Coloring',
  332911: 'Industrial Valve Manufacturing',
  332912: 'Fluid Power Valve and Hose Fitting Manufacturing',
  332913: 'Plumbing Fixture Fitting and Trim Manufacturing',
  332919: 'Other Metal Valve and Pipe Fitting Manufacturing',
  332991: 'Ball and Roller Bearing Manufacturing',
  332992: 'Small Arms Ammunition Manufacturing',
  332993: 'Ammunition (except Small Arms) Manufacturing',
  332994: 'Small Arms, Ordnance, and Ordnance Accessories Manufacturing',
  332996: 'Fabricated Pipe and Pipe Fitting Manufacturing',
  332999: 'All Other Miscellaneous Fabricated Metal Product Manufacturing',
  333111: 'Farm Machinery and Equipment Manufacturing',
  333112: 'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing',
  333120: 'Construction Machinery Manufacturing',
  333131: 'Mining Machinery and Equipment Manufacturing',
  333132: 'Oil and Gas Field Machinery and Equipment Manufacturing',
  333241: 'Food Product Machinery Manufacturing',
  333242: 'Semiconductor Machinery Manufacturing',
  333243: 'Sawmill, Woodworking, and Paper Machinery Manufacturing',
  333244: 'Printing Machinery and Equipment Manufacturing',
  333248: 'All Other Industrial Machinery Manufacturing',
  333249: 'Other Industrial Machinery Manufacturing',
  333310: 'Commercial and Service Industry Machinery Manufacturing',
  333314: 'Optical Instrument and Lens Manufacturing',
  333316: 'Photographic and Photocopying Equipment Manufacturing',
  333318: 'Other Commercial and Service Industry Machinery Manufacturing',
  333413: 'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing',
  333414: 'Heating Equipment (except Warm Air Furnaces) Manufacturing',
  333415: 'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing',
  333511: 'Industrial Mold Manufacturing',
  333514: 'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing',
  333515: 'Cutting Tool and Machine Tool Accessory Manufacturing',
  333517: 'Machine Tool Manufacturing',
  333519: 'Rolling Mill and Other Metalworking Machinery Manufacturing',
  333611: 'Turbine and Turbine Generator Set Units Manufacturing',
  333612: 'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing',
  333613: 'Mechanical Power Transmission Equipment Manufacturing',
  333618: 'Other Engine Equipment Manufacturing',
  333912: 'Air and Gas Compressor Manufacturing',
  333914: 'Measuring, Dispensing, and Other Pumping Equipment Manufacturing',
  333921: 'Elevator and Moving Stairway Manufacturing',
  333922: 'Conveyor and Conveying Equipment Manufacturing',
  333923: 'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing',
  333924: 'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing',
  333991: 'Power-Driven Handtool Manufacturing',
  333992: 'Welding and Soldering Equipment Manufacturing',
  333993: 'Packaging Machinery Manufacturing',
  333994: 'Industrial Process Furnace and Oven Manufacturing',
  333995: 'Fluid Power Cylinder and Actuator Manufacturing',
  333996: 'Fluid Power Pump and Motor Manufacturing',
  333997: 'Scale and Balance Manufacturing',
  333998: 'All Other Miscellaneous General Purpose Machinery Manufacturing',
  334111: 'Electronic Computer Manufacturing',
  334112: 'Computer Storage Device Manufacturing',
  334118: 'Computer Terminal and Other Computer Peripheral Equipment Manufacturing',
  334210: 'Telephone Apparatus Manufacturing',
  334220: 'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing',
  334290: 'Other Communications Equipment Manufacturing',
  334310: 'Audio and Video Equipment Manufacturing',
  334412: 'Bare Printed Circuit Board Manufacturing',
  334413: 'Semiconductor and Related Device Manufacturing',
  334416: 'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing',
  334417: 'Electronic Connector Manufacturing',
  334418: 'Printed Circuit Assembly (Electronic Assembly) Manufacturing',
  334419: 'Other Electronic Component Manufacturing',
  334510: 'Electromedical and Electrotherapeutic Apparatus Manufacturing',
  334511: 'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing',
  334512: 'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use',
  334513: 'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables',
  334514: 'Totalizing Fluid Meter and Counting Device Manufacturing',
  334515: 'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals',
  334516: 'Analytical Laboratory Instrument Manufacturing',
  334517: 'Irradiation Apparatus Manufacturing',
  334519: 'Other Measuring and Controlling Device Manufacturing',
  334610: 'Manufacturing and Reproducing Magnetic and Optical Media',
  334613: 'Blank Magnetic and Optical Recording Media Manufacturing',
  334614: 'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing',
  335110: 'Electric Lamp Bulb and Part Manufacturing',
  335121: 'Residential Electric Lighting Fixture Manufacturing',
  335122: 'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing',
  335129: 'Other Lighting Equipment Manufacturing',
  335131: 'Residential Electric Lighting Fixture Manufacturing',
  335132: 'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing',
  335139: 'Electric Lamp Bulb and Other Lighting Equipment Manufacturing',
  335210: 'Small Electrical Appliance Manufacturing',
  335220: 'Major Household Appliance Manufacturing',
  335311: 'Power, Distribution, and Specialty Transformer Manufacturing',
  335312: 'Motor and Generator Manufacturing',
  335313: 'Switchgear and Switchboard Apparatus Manufacturing',
  335314: 'Relay and Industrial Control Manufacturing',
  335910: 'Battery Manufacturing',
  335911: 'Storage Battery Manufacturing',
  335912: 'Primary Battery Manufacturing',
  335921: 'Fiber Optic Cable Manufacturing',
  335929: 'Other Communication and Energy Wire Manufacturing',
  335931: 'Current-Carrying Wiring Device Manufacturing',
  335932: 'Noncurrent-Carrying Wiring Device Manufacturing',
  335991: 'Carbon and Graphite Product Manufacturing',
  335999: 'All Other Miscellaneous Electrical Equipment and Component Manufacturing',
  336110: 'Automobile and Light Duty Motor Vehicle Manufacturing',
  336111: 'Automobile Manufacturing',
  336112: 'Light Truck and Utility Vehicle Manufacturing',
  336120: 'Heavy Duty Truck Manufacturing',
  336211: 'Motor Vehicle Body Manufacturing',
  336212: 'Truck Trailer Manufacturing',
  336213: 'Motor Home Manufacturing',
  336214: 'Travel Trailer and Camper Manufacturing',
  336310: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
  336320: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
  336330: 'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
  336340: 'Motor Vehicle Brake System Manufacturing',
  336350: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
  336360: 'Motor Vehicle Seating and Interior Trim Manufacturing',
  336370: 'Motor Vehicle Metal Stamping',
  336390: 'Other Motor Vehicle Parts Manufacturing',
  336411: 'Aircraft Manufacturing',
  336412: 'Aircraft Engine and Engine Parts Manufacturing',
  336413: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing',
  336414: 'Guided Missile and Space Vehicle Manufacturing',
  336415: 'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing',
  336419: 'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing',
  336510: 'Railroad Rolling Stock Manufacturing',
  336611: 'Ship Building and Repairing',
  336612: 'Boat Building',
  336991: 'Motorcycle, Bicycle, and Parts Manufacturing',
  336992: 'Military Armored Vehicle, Tank, and Tank Component Manufacturing',
  336999: 'All Other Transportation Equipment Manufacturing',
  337110: 'Wood Kitchen Cabinet and Countertop Manufacturing',
  337121: 'Upholstered Household Furniture Manufacturing',
  337122: 'Nonupholstered Wood Household Furniture Manufacturing',
  337124: 'Metal Household Furniture Manufacturing',
  337125: 'Household Furniture (except Wood and Metal) Manufacturing',
  337126: 'Household Furniture (except Wood and Upholstered) Manufacturing',
  337127: 'Institutional Furniture Manufacturing',
  337211: 'Wood Office Furniture Manufacturing',
  337212: 'Custom Architectural Woodwork and Millwork Manufacturing',
  337214: 'Office Furniture (except Wood) Manufacturing',
  337215: 'Showcase, Partition, Shelving, and Locker Manufacturing',
  337910: 'Mattress Manufacturing',
  337920: 'Blind and Shade Manufacturing',
  339112: 'Surgical and Medical Instrument Manufacturing',
  339113: 'Surgical Appliance and Supplies Manufacturing',
  339114: 'Dental Equipment and Supplies Manufacturing',
  339115: 'Ophthalmic Goods Manufacturing',
  339116: 'Dental Laboratories',
  339910: 'Jewelry and Silverware Manufacturing',
  339920: 'Sporting and Athletic Goods Manufacturing',
  339930: 'Doll, Toy, and Game Manufacturing',
  339940: 'Office Supplies (except Paper) Manufacturing',
  339950: 'Sign Manufacturing',
  339991: 'Gasket, Packing, and Sealing Device Manufacturing',
  339992: 'Musical Instrument Manufacturing',
  339993: 'Fastener, Button, Needle, and Pin Manufacturing',
  339994: 'Broom, Brush, and Mop Manufacturing',
  339995: 'Burial Casket Manufacturing',
  339999: 'All Other Miscellaneous Manufacturing',
  42: 'Wholesale Trade',
  423110: 'Automobile and Other Motor Vehicle Merchant Wholesalers',
  423120: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
  423130: 'Tire and Tube Merchant Wholesalers',
  423140: 'Motor Vehicle Parts (Used) Merchant Wholesalers',
  423210: 'Furniture Merchant Wholesalers',
  423220: 'Home Furnishing Merchant Wholesalers',
  423310: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
  423320: 'Brick, Stone, and Related Construction Material Merchant Wholesalers',
  423330: 'Roofing, Siding, and Insulation Material Merchant Wholesalers',
  423390: 'Other Construction Material Merchant Wholesalers',
  423410: 'Photographic Equipment and Supplies Merchant Wholesalers',
  423420: 'Office Equipment Merchant Wholesalers',
  423430: 'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers',
  423440: 'Other Commercial Equipment Merchant Wholesalers',
  423450: 'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers',
  423460: 'Ophthalmic Goods Merchant Wholesalers',
  423490: 'Other Professional Equipment and Supplies Merchant Wholesalers',
  423510: 'Metal Service Centers and Other Metal Merchant Wholesalers',
  423520: 'Coal and Other Mineral and Ore Merchant Wholesalers',
  423610: 'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers',
  423620: 'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers',
  423690: 'Other Electronic Parts and Equipment Merchant Wholesalers',
  423710: 'Hardware Merchant Wholesalers',
  423720: 'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
  423730: 'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
  423740: 'Refrigeration Equipment and Supplies Merchant Wholesalers',
  423810: 'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers',
  423820: 'Farm and Garden Machinery and Equipment Merchant Wholesalers',
  423830: 'Industrial Machinery and Equipment Merchant Wholesalers',
  423840: 'Industrial Supplies Merchant Wholesalers',
  423850: 'Service Establishment Equipment and Supplies Merchant Wholesalers',
  423860: 'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers',
  423910: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
  423920: 'Toy and Hobby Goods and Supplies Merchant Wholesalers',
  423930: 'Recyclable Material Merchant Wholesalers',
  423940: 'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers',
  423990: 'Other Miscellaneous Durable Goods Merchant Wholesalers',
  424110: 'Printing and Writing Paper Merchant Wholesalers',
  424120: 'Stationery and Office Supplies Merchant Wholesalers',
  424130: 'Industrial and Personal Service Paper Merchant Wholesalers',
  424210: 'Drugs and Druggists’ Sundries Merchant Wholesalers',
  424310: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers',
  424320: 'Men’s and Boys’ Clothing and Furnishings Merchant Wholesalers',
  424330: 'Women’s, Children’s, and Infants’ Clothing and Accessories Merchant Wholesalers',
  424340: 'Footwear Merchant Wholesalers',
  424350: 'Clothing and Clothing Accessories Merchant Wholesalers',
  424410: 'General Line Grocery Merchant Wholesalers',
  424420: 'Packaged Frozen Food Merchant Wholesalers',
  424430: 'Dairy Product (except Dried or Canned) Merchant Wholesalers',
  424440: 'Poultry and Poultry Product Merchant Wholesalers',
  424450: 'Confectionery Merchant Wholesalers',
  424460: 'Fish and Seafood Merchant Wholesalers',
  424470: 'Meat and Meat Product Merchant Wholesalers',
  424480: 'Fresh Fruit and Vegetable Merchant Wholesalers',
  424490: 'Other Grocery and Related Products Merchant Wholesalers',
  424510: 'Grain and Field Bean Merchant Wholesalers',
  424520: 'Livestock Merchant Wholesalers',
  424590: 'Other Farm Product Raw Material Merchant Wholesalers',
  424610: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
  424690: 'Other Chemical and Allied Products Merchant Wholesalers',
  424710: 'Petroleum Bulk Stations and Terminals',
  424720: 'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)',
  424810: 'Beer and Ale Merchant Wholesalers',
  424820: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
  424910: 'Farm Supplies Merchant Wholesalers',
  424920: 'Book, Periodical, and Newspaper Merchant Wholesalers',
  424930: 'Flower, Nursery Stock, and Florists’ Supplies Merchant Wholesalers',
  424940: 'Tobacco and Tobacco Product Merchant Wholesalers',
  424950: 'Paint, Varnish, and Supplies Merchant Wholesalers',
  424990: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers',
  425110: 'Business to Business Electronic Markets',
  425120: 'Wholesale Trade Agents and Brokers',
  44: 'Retail Trade',
  441110: 'New Car Dealers',
  441120: 'Used Car Dealers',
  441210: 'Recreational Vehicle Dealers',
  441222: 'Boat Dealers',
  441227: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers',
  441228: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers',
  441310: 'Automotive Parts and Accessories Stores',
  441320: 'Tire Dealers',
  441330: 'Automotive Parts and Accessories Retailers',
  441340: 'Tire Dealers',
  442110: 'Furniture Stores',
  442210: 'Floor Covering Stores',
  442291: 'Window Treatment Stores',
  442299: 'All Other Home Furnishings Stores',
  443141: 'Household Appliance Stores',
  443142: 'Electronics Stores',
  444110: 'Home Centers',
  444120: 'Paint and Wallpaper Stores',
  444130: 'Hardware Stores',
  444140: 'Hardware Stores',
  444180: 'Other Building Material Dealers',
  444190: 'Other Building Material Dealers',
  444210: 'Outdoor Power Equipment Stores',
  444220: 'Nursery, Garden Center, and Farm Supply Stores',
  444230: 'Outdoor Power Equipment Stores',
  444240: 'Nursery, Garden Center, and Farm Supply Stores',
  445110: 'Supermarkets and Other Grocery (except Convenience) Stores',
  445120: 'Convenience Stores',
  445131: 'Convenience Retailers',
  445132: 'Vending Machine Operators',
  445210: 'Meat Markets',
  445220: 'Fish and Seafood Markets',
  445230: 'Fruit and Vegetable Markets',
  445240: 'Meat Retailers',
  445250: 'Fish and Seafood Retailers',
  445291: 'Baked Goods Stores',
  445292: 'Confectionery and Nut Stores',
  445298: 'All Other Specialty Food Retailers',
  445299: 'All Other Specialty Food Stores',
  445310: 'Beer, Wine, and Liquor Stores',
  445320: 'Beer, Wine, and Liquor Retailers',
  446110: 'Pharmacies and Drug Stores',
  446120: 'Cosmetics, Beauty Supplies, and Perfume Stores',
  446130: 'Optical Goods Stores',
  446191: 'Food (Health) Supplement Stores',
  446199: 'All Other Health and Personal Care Stores',
  447110: 'Gasoline Stations with Convenience Stores',
  447190: 'Other Gasoline Stations',
  448110: 'Men’s Clothing Stores',
  448120: 'Women’s Clothing Stores',
  448130: 'Children’s and Infants’ Clothing Stores',
  448140: 'Family Clothing Stores',
  448150: 'Clothing Accessories Stores',
  448190: 'Other Clothing Stores',
  448210: 'Shoe Stores',
  448310: 'Jewelry Stores',
  448320: 'Luggage and Leather Goods Stores',
  449110: 'Furniture Retailers',
  449121: 'Floor Covering Retailers',
  449122: 'Window Treatment Retailers',
  449129: 'All Other Home Furnishings Retailers',
  449210: 'Electronics and Appliance Retailers',
  45: 'Retail Trade',
  451110: 'Sporting Goods Stores',
  451120: 'Hobby, Toy, and Game Stores',
  451130: 'Sewing, Needlework, and Piece Goods Stores',
  451140: 'Musical Instrument and Supplies Stores',
  451211: 'Book Stores',
  451212: 'News Dealers and Newsstands',
  452210: 'Department Stores',
  452311: 'Warehouse Clubs and Supercenters',
  452319: 'All Other General Merchandise Stores',
  453110: 'Florists',
  453210: 'Office Supplies and Stationery Stores',
  453220: 'Gift, Novelty, and Souvenir Stores',
  453310: 'Used Merchandise Stores',
  453910: 'Pet and Pet Supplies Stores',
  453920: 'Art Dealers',
  453930: 'Manufactured (Mobile) Home Dealers',
  453991: 'Tobacco Stores',
  453998: 'All Other Miscellaneous Store Retailers (except Tobacco Stores)',
  454110: 'Electronic Shopping and Mail-Order Houses',
  454210: 'Vending Machine Operators',
  454310: 'Fuel Dealers',
  454390: 'Other Direct Selling Establishments',
  455110: 'Department Stores',
  455211: 'Warehouse Clubs and Supercenters',
  455219: 'All Other General Merchandise Retailers',
  456110: 'Pharmacies and Drug Retailers',
  456120: 'Cosmetics, Beauty Supplies, and Perfume Retailers',
  456130: 'Optical Goods Retailers',
  456191: 'Food (Health) Supplement Retailers',
  456199: 'All Other Health and Personal Care Retailers',
  457110: 'Gasoline Stations with Convenience Stores',
  457120: 'Other Gasoline Stations',
  457210: 'Fuel Dealers',
  458110: 'Clothing and Clothing Accessories Retailers',
  458210: 'Shoe Retailers',
  458310: 'Jewelry Retailers',
  458320: 'Luggage and Leather Goods Retailers',
  459110: 'Sporting Goods Retailers',
  459120: 'Hobby, Toy, and Game Retailers',
  459130: 'Sewing, Needlework, and Piece Goods Retailers',
  459140: 'Musical Instrument and Supplies Retailers',
  459210: 'Book Retailers and News Dealers',
  459310: 'Florists',
  459410: 'Office Supplies and Stationery Stores',
  459420: 'Gift, Novelty, and Souvenir Stores',
  459510: 'Used Merchandise Retailers',
  459910: 'Pet and Pet Supplies Retailers',
  459920: 'Art Dealers',
  459930: 'Manufactured (Mobile) Home Dealers',
  459991: 'Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers',
  459999: 'All Other Miscellaneous Retailers',
  48: 'Transportation and Warehousing',
  481111: 'Scheduled Passenger Air Transportation',
  481112: 'Scheduled Freight Air Transportation',
  481211: 'Nonscheduled Chartered Passenger Air Transportation',
  481212: 'Nonscheduled Chartered Freight Air Transportation',
  481219: 'Other Nonscheduled Air Transportation',
  482111: 'Line-Haul Railroads',
  482112: 'Short Line Railroads',
  483111: 'Deep Sea Freight Transportation',
  483112: 'Deep Sea Passenger Transportation',
  483113: 'Coastal and Great Lakes Freight Transportation',
  483114: 'Coastal and Great Lakes Passenger Transportation',
  483211: 'Inland Water Freight Transportation',
  483212: 'Inland Water Passenger Transportation',
  484110: 'General Freight Trucking, Local',
  484121: 'General Freight Trucking, Long-Distance, Truckload',
  484122: 'General Freight Trucking, Long-Distance, Less Than Truckload',
  484210: 'Used Household and Office Goods Moving',
  484220: 'Specialized Freight (except Used Goods) Trucking, Local',
  484230: 'Specialized Freight (except Used Goods) Trucking, Long-Distance',
  485111: 'Mixed Mode Transit Systems',
  485112: 'Commuter Rail Systems',
  485113: 'Bus and Other Motor Vehicle Transit Systems',
  485119: 'Other Urban Transit Systems',
  485210: 'Interurban and Rural Bus Transportation',
  485310: 'Taxi Service',
  485320: 'Limousine Service',
  485410: 'School and Employee Bus Transportation',
  485510: 'Charter Bus Industry',
  485991: 'Special Needs Transportation',
  485999: 'All Other Transit and Ground Passenger Transportation',
  486110: 'Pipeline Transportation of Crude Oil',
  486210: 'Pipeline Transportation of Natural Gas',
  486910: 'Pipeline Transportation of Refined Petroleum Products',
  486990: 'All Other Pipeline Transportation',
  487110: 'Scenic and Sightseeing Transportation, Land',
  487210: 'Scenic and Sightseeing Transportation, Water',
  487990: 'Scenic and Sightseeing Transportation, Other',
  488111: 'Air Traffic Control',
  488119: 'Other Airport Operations',
  488190: 'Other Support Activities for Air Transportation',
  488210: 'Support Activities for Rail Transportation',
  488310: 'Port and Harbor Operations',
  488320: 'Marine Cargo Handling',
  488330: 'Navigational Services to Shipping',
  488390: 'Other Support Activities for Water Transportation',
  488410: 'Motor Vehicle Towing',
  488490: 'Other Support Activities for Road Transportation',
  488510: 'Freight Transportation Arrangement',
  488991: 'Packing and Crating',
  488999: 'All Other Support Activities for Transportation',
  49: 'Transportation and Warehousing',
  491110: 'Postal Service',
  492110: 'Couriers and Express Delivery Services',
  492210: 'Local Messengers and Local Delivery',
  493110: 'General Warehousing and Storage',
  493120: 'Refrigerated Warehousing and Storage',
  493130: 'Farm Product Warehousing and Storage',
  493190: 'Other Warehousing and Storage',
  51: 'Information',
  511110: 'Newspaper Publishers',
  511120: 'Periodical Publishers',
  511130: 'Book Publishers',
  511140: 'Directory and Mailing List Publishers',
  511191: 'Greeting Card Publishers',
  511199: 'All Other Publishers',
  511210: 'Software Publishers',
  512110: 'Motion Picture and Video Production',
  512120: 'Motion Picture and Video Distribution',
  512131: 'Motion Picture Theaters (except Drive-Ins)',
  512132: 'Drive-In Motion Picture Theaters',
  512191: 'Teleproduction and Other Postproduction Services',
  512199: 'Other Motion Picture and Video Industries',
  512230: 'Music Publishers',
  512240: 'Sound Recording Studios',
  512250: 'Record Production and Distribution',
  512290: 'Other Sound Recording Industries',
  513110: 'Newspaper Publishers',
  513120: 'Periodical Publishers',
  513130: 'Book Publishers',
  513140: 'Directory and Mailing List Publishers',
  513191: 'Greeting Card Publishers',
  513199: 'All Other Publishers',
  513210: 'Software Publishers',
  515111: 'Radio Networks',
  515112: 'Radio Stations',
  515120: 'Television Broadcasting',
  515210: 'Cable and Other Subscription Programming',
  516110: 'Radio Broadcasting Stations',
  516120: 'Television Broadcasting Stations',
  516210: 'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers',
  517111: 'Wired Telecommunications Carriers',
  517112: 'Wireless Telecommunications Carriers (except Satellite)',
  517121: 'Telecommunications Resellers',
  517122: 'Agents for Wireless Telecommunications Services',
  517311: 'Wired Telecommunications Carriers',
  517312: 'Wireless Telecommunications Carriers (except Satellite)',
  517410: 'Satellite Telecommunications',
  517810: 'All Other Telecommunications',
  517911: 'Telecommunications Resellers',
  517919: 'All Other Telecommunications',
  518210: 'Data Processing, Hosting, and Related Services',
  519110: 'News Syndicates',
  519120: 'Libraries and Archives',
  519130: 'Internet Publishing and Broadcasting and Web Search Portals',
  519190: 'All Other Information Services',
  519210: 'Libraries and Archives',
  519290: 'Web Search Portals and All Other Information Services',
  52: 'Finance and Insurance',
  521110: 'Monetary Authorities-Central Bank',
  522110: 'Commercial Banking',
  522120: 'Savings Institutions',
  522130: 'Credit Unions',
  522180: 'Savings Institutions and Other Depository Credit Intermediation',
  522190: 'Other Depository Credit Intermediation',
  522210: 'Credit Card Issuing',
  522220: 'Sales Financing',
  522291: 'Consumer Lending',
  522292: 'Real Estate Credit',
  522293: 'International Trade Financing',
  522294: 'Secondary Market Financing',
  522298: 'All Other Nondepository Credit Intermediation',
  522299: 'International, Secondary Market, and All Other Nondepository Credit Intermediation',
  522310: 'Mortgage and Nonmortgage Loan Brokers',
  522320: 'Financial Transactions Processing, Reserve, and Clearinghouse Activities',
  522390: 'Other Activities Related to Credit Intermediation',
  523110: 'Investment Banking and Securities Dealing',
  523120: 'Securities Brokerage',
  523130: 'Commodity Contracts Dealing',
  523140: 'Commodity Contracts Brokerage',
  523150: 'Investment Banking and Securities Intermediation',
  523160: 'Commodity Contracts Intermediation',
  523210: 'Securities and Commodity Exchanges',
  523910: 'Miscellaneous Intermediation',
  523920: 'Portfolio Management',
  523930: 'Investment Advice',
  523940: 'Portfolio Management and Investment Advice',
  523991: 'Trust, Fiduciary, and Custody Activities',
  523999: 'Miscellaneous Financial Investment Activities',
  524113: 'Direct Life Insurance Carriers',
  524114: 'Direct Health and Medical Insurance Carriers',
  524126: 'Direct Property and Casualty Insurance Carriers',
  524127: 'Direct Title Insurance Carriers',
  524128: 'Other Direct Insurance (except Life, Health, and Medical) Carriers',
  524130: 'Reinsurance Carriers',
  524210: 'Insurance Agencies and Brokerages',
  524291: 'Claims Adjusting',
  524292: 'Third Party Administration of Insurance and Pension Funds',
  524298: 'All Other Insurance Related Activities',
  525110: 'Pension Funds',
  525120: 'Health and Welfare Funds',
  525190: 'Other Insurance Funds',
  525910: 'Open-End Investment Funds',
  525920: 'Trusts, Estates, and Agency Accounts',
  525990: 'Other Financial Vehicles',
  53: 'Real Estate and Rental and Leasing',
  531110: 'Lessors of Residential Buildings and Dwellings',
  531120: 'Lessors of Nonresidential Buildings (except Miniwarehouses)',
  531130: 'Lessors of Miniwarehouses and Self-Storage Units',
  531190: 'Lessors of Other Real Estate Property',
  531210: 'Offices of Real Estate Agents and Brokers',
  531311: 'Residential Property Managers',
  531312: 'Nonresidential Property Managers',
  531320: 'Offices of Real Estate Appraisers',
  531390: 'Other Activities Related to Real Estate',
  532111: 'Passenger Car Rental',
  532112: 'Passenger Car Leasing',
  532120: 'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
  532210: 'Consumer Electronics and Appliances Rental',
  532281: 'Formal Wear and Costume Rental',
  532282: 'Video Tape and Disc Rental',
  532283: 'Home Health Equipment Rental',
  532284: 'Recreational Goods Rental',
  532289: 'All Other Consumer Goods Rental',
  532310: 'General Rental Centers',
  532411: 'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing',
  532412: 'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing',
  532420: 'Office Machinery and Equipment Rental and Leasing',
  532490: 'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
  533110: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
  54: 'Professional, Scientific, and Technical Services',
  541110: 'Offices of Lawyers',
  541120: 'Offices of Notaries',
  541191: 'Title Abstract and Settlement Offices',
  541199: 'All Other Legal Services',
  541211: 'Offices of Certified Public Accountants',
  541213: 'Tax Preparation Services',
  541214: 'Payroll Services',
  541219: 'Other Accounting Services',
  541310: 'Architectural Services',
  541320: 'Landscape Architectural Services',
  541330: 'Engineering Services',
  541340: 'Drafting Services',
  541350: 'Building Inspection Services',
  541360: 'Geophysical Surveying and Mapping Services',
  541370: 'Surveying and Mapping (except Geophysical) Services',
  541380: 'Testing Laboratories',
  541410: 'Interior Design Services',
  541420: 'Industrial Design Services',
  541430: 'Graphic Design Services',
  541490: 'Other Specialized Design Services',
  541511: 'Custom Computer Programming Services',
  541512: 'Computer Systems Design Services',
  541513: 'Computer Facilities Management Services',
  541519: 'Other Computer Related Services',
  541611: 'Administrative Management and General Management Consulting Services',
  541612: 'Human Resources Consulting Services',
  541613: 'Marketing Consulting Services',
  541614: 'Process, Physical Distribution, and Logistics Consulting Services',
  541618: 'Other Management Consulting Services',
  541620: 'Environmental Consulting Services',
  541690: 'Other Scientific and Technical Consulting Services',
  541713: 'Research and Development in Nanotechnology',
  541714: 'Research and Development in Biotechnology (except Nanobiotechnology)',
  541715: 'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)',
  541720: 'Research and Development in the Social Sciences and Humanities',
  541810: 'Advertising Agencies',
  541820: 'Public Relations Agencies',
  541830: 'Media Buying Agencies',
  541840: 'Media Representatives',
  541850: 'Outdoor Advertising',
  541860: 'Direct Mail Advertising',
  541870: 'Advertising Material Distribution Services',
  541890: 'Other Services Related to Advertising',
  541910: 'Marketing Research and Public Opinion Polling',
  541921: 'Photography Studios, Portrait',
  541922: 'Commercial Photography',
  541930: 'Translation and Interpretation Services',
  541940: 'Veterinary Services',
  541990: 'All Other Professional, Scientific, and Technical Services',
  55: 'Management of Companies and Enterprises',
  551111: 'Offices of Bank Holding Companies',
  551112: 'Offices of Other Holding Companies',
  551114: 'Corporate, Subsidiary, and Regional Managing Offices',
  56: 'Administrative and Support and Waste Management and Remediation Services',
  561110: 'Office Administrative Services',
  561210: 'Facilities Support Services',
  561311: 'Employment Placement Agencies',
  561312: 'Executive Search Services',
  561320: 'Temporary Help Services',
  561330: 'Professional Employer Organizations',
  561410: 'Document Preparation Services',
  561421: 'Telephone Answering Services',
  561422: 'Telemarketing Bureaus and Other Contact Centers',
  561431: 'Private Mail Centers',
  561439: 'Other Business Service Centers (including Copy Shops)',
  561440: 'Collection Agencies',
  561450: 'Credit Bureaus',
  561491: 'Repossession Services',
  561492: 'Court Reporting and Stenotype Services',
  561499: 'All Other Business Support Services',
  561510: 'Travel Agencies',
  561520: 'Tour Operators',
  561591: 'Convention and Visitors Bureaus',
  561599: 'All Other Travel Arrangement and Reservation Services',
  561611: 'Investigation Services',
  561612: 'Security Guards and Patrol Services',
  561613: 'Armored Car Services',
  561621: 'Security Systems Services (except Locksmiths)',
  561622: 'Locksmiths',
  561710: 'Exterminating and Pest Control Services',
  561720: 'Janitorial Services',
  561730: 'Landscaping Services',
  561740: 'Carpet and Upholstery Cleaning Services',
  561790: 'Other Services to Buildings and Dwellings',
  561910: 'Packaging and Labeling Services',
  561920: 'Convention and Trade Show Organizers',
  561990: 'All Other Support Services',
  562111: 'Solid Waste Collection',
  562112: 'Hazardous Waste Collection',
  562119: 'Other Waste Collection',
  562211: 'Hazardous Waste Treatment and Disposal',
  562212: 'Solid Waste Landfill',
  562213: 'Solid Waste Combustors and Incinerators',
  562219: 'Other Nonhazardous Waste Treatment and Disposal',
  562910: 'Remediation Services',
  562920: 'Materials Recovery Facilities',
  562991: 'Septic Tank and Related Services',
  562998: 'All Other Miscellaneous Waste Management Services',
  61: 'Educational Services',
  611110: 'Elementary and Secondary Schools',
  611210: 'Junior Colleges',
  611310: 'Colleges, Universities, and Professional Schools',
  611410: 'Business and Secretarial Schools',
  611420: 'Computer Training',
  611430: 'Professional and Management Development Training',
  611511: 'Cosmetology and Barber Schools',
  611512: 'Flight Training',
  611513: 'Apprenticeship Training',
  611519: 'Other Technical and Trade Schools',
  611610: 'Fine Arts Schools',
  611620: 'Sports and Recreation Instruction',
  611630: 'Language Schools',
  611691: 'Exam Preparation and Tutoring',
  611692: 'Automobile Driving Schools',
  611699: 'All Other Miscellaneous Schools and Instruction',
  611710: 'Educational Support Services',
  62: 'Health Care and Social Assistance',
  621111: 'Offices of Physicians (except Mental Health Specialists)',
  621112: 'Offices of Physicians, Mental Health Specialists',
  621210: 'Offices of Dentists',
  621310: 'Offices of Chiropractors',
  621320: 'Offices of Optometrists',
  621330: 'Offices of Mental Health Practitioners (except Physicians)',
  621340: 'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
  621391: 'Offices of Podiatrists',
  621399: 'Offices of All Other Miscellaneous Health Practitioners',
  621410: 'Family Planning Centers',
  621420: 'Outpatient Mental Health and Substance Abuse Centers',
  621491: 'HMO Medical Centers',
  621492: 'Kidney Dialysis Centers',
  621493: 'Freestanding Ambulatory Surgical and Emergency Centers',
  621498: 'All Other Outpatient Care Centers',
  621511: 'Medical Laboratories',
  621512: 'Diagnostic Imaging Centers',
  621610: 'Home Health Care Services',
  621910: 'Ambulance Services',
  621991: 'Blood and Organ Banks',
  621999: 'All Other Miscellaneous Ambulatory Health Care Services',
  622110: 'General Medical and Surgical Hospitals',
  622210: 'Psychiatric and Substance Abuse Hospitals',
  622310: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
  623110: 'Nursing Care Facilities (Skilled Nursing Facilities)',
  623210: 'Residential Intellectual and Developmental Disability Facilities',
  623220: 'Residential Mental Health and Substance Abuse Facilities',
  623311: 'Continuing Care Retirement Communities',
  623312: 'Assisted Living Facilities for the Elderly',
  623990: 'Other Residential Care Facilities',
  624110: 'Child and Youth Services',
  624120: 'Services for the Elderly and Persons with Disabilities',
  624190: 'Other Individual and Family Services',
  624210: 'Community Food Services',
  624221: 'Temporary Shelters',
  624229: 'Other Community Housing Services',
  624230: 'Emergency and Other Relief Services',
  624310: 'Vocational Rehabilitation Services',
  624410: 'Child Day Care Services',
      71: 'Arts, Entertainment, and Recreation',
  711110: 'Theater Companies and Dinner Theaters',
  711120: 'Dance Companies',
  711130: 'Musical Groups and Artists',
  711190: 'Other Performing Arts Companies',
  711211: 'Sports Teams and Clubs',
  711212: 'Racetracks',
  711219: 'Other Spectator Sports',
  711310: 'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
  711320: 'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
  711410: 'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
  711510: 'Independent Artists, Writers, and Performers',
  712110: 'Museums',
  712120: 'Historical Sites',
  712130: 'Zoos and Botanical Gardens',
  712190: 'Nature Parks and Other Similar Institutions',
  713110: 'Amusement and Theme Parks',
  713120: 'Amusement Arcades',
  713210: 'Casinos (except Casino Hotels)',
  713290: 'Other Gambling Industries',
  713910: 'Golf Courses and Country Clubs',
  713920: 'Skiing Facilities',
  713930: 'Marinas',
  713940: 'Fitness and Recreational Sports Centers',
  713950: 'Bowling Centers',
  713990: 'All Other Amusement and Recreation Industries',
  72: 'Accommodation and Food Services',
  721110: 'Hotels (except Casino Hotels) and Motels',
  721120: 'Casino Hotels',
  721191: 'Bed-and-Breakfast Inns',
  721199: 'All Other Traveler Accommodation',
  721211: 'RV (Recreational Vehicle) Parks and Campgrounds',
  721214: 'Recreational and Vacation Camps (except Campgrounds)',
  721310: 'Rooming and Boarding Houses, Dormitories, and Workers’ Camps',
  722310: 'Food Service Contractors',
  722320: 'Caterers',
  722330: 'Mobile Food Services',
  722410: 'Drinking Places (Alcoholic Beverages)',
  722511: 'Full-Service Restaurants',
  722513: 'Limited-Service Restaurants',
  722514: 'Cafeterias, Grill Buffets, and Buffets',
  722515: 'Snack and Nonalcoholic Beverage Bars',
  81: 'Other Services (except Public Administration)',
  811111: 'General Automotive Repair',
  811112: 'Automotive Exhaust System Repair',
  811113: 'Automotive Transmission Repair',
  811114: 'Specialized Automotive Repair',
  811118: 'Other Automotive Mechanical and Electrical Repair and Maintenance',
  811121: 'Automotive Body, Paint, and Interior Repair and Maintenance',
  811122: 'Automotive Glass Replacement Shops',
  811191: 'Automotive Oil Change and Lubrication Shops',
  811192: 'Car Washes',
  811198: 'All Other Automotive Repair and Maintenance',
  811210: 'Electronic and Precision Equipment Repair and Maintenance',
  811211: 'Consumer Electronics Repair and Maintenance',
  811212: 'Computer and Office Machine Repair and Maintenance',
  811213: 'Communication Equipment Repair and Maintenance',
  811219: 'Other Electronic and Precision Equipment Repair and Maintenance',
  811310: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
  811411: 'Home and Garden Equipment Repair and Maintenance',
  811412: 'Appliance Repair and Maintenance',
  811420: 'Reupholstery and Furniture Repair',
  811430: 'Footwear and Leather Goods Repair',
  811490: 'Other Personal and Household Goods Repair and Maintenance',
  812111: 'Barber Shops',
  812112: 'Beauty Salons',
  812113: 'Nail Salons',
  812191: 'Diet and Weight Reducing Centers',
  812199: 'Other Personal Care Services',
  812210: 'Funeral Homes and Funeral Services',
  812220: 'Cemeteries and Crematories',
  812310: 'Coin-Operated Laundries and Drycleaners',
  812320: 'Drycleaning and Laundry Services (except Coin-Operated)',
  812331: 'Linen Supply',
  812332: 'Industrial Launderers',
  812910: 'Pet Care (except Veterinary) Services',
  812921: 'Photofinishing Laboratories (except One-Hour)',
  812922: 'One-Hour Photofinishing',
  812930: 'Parking Lots and Garages',
  812990: 'All Other Personal Services',
  813110: 'Religious Organizations',
  813211: 'Grantmaking Foundations',
  813212: 'Voluntary Health Organizations',
  813219: 'Other Grantmaking and Giving Services',
  813311: 'Human Rights Organizations',
  813312: 'Environment, Conservation and Wildlife Organizations',
  813319: 'Other Social Advocacy Organizations',
  813410: 'Civic and Social Organizations',
  813910: 'Business Associations',
  813920: 'Professional Organizations',
  813930: 'Labor Unions and Similar Labor Organizations',
  813940: 'Political Organizations',
  813990: 'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)',
  814110: 'Private Households',
  92: 'Public Administration',
  921110: 'Executive Offices',
  921120: 'Legislative Bodies',
  921130: 'Public Finance Activities',
  921140: 'Executive and Legislative Offices, Combined',
  921150: 'American Indian and Alaska Native Tribal Governments',
  921190: 'Other General Government Support',
  922110: 'Courts',
  922120: 'Police Protection',
  922130: 'Legal Counsel and Prosecution',
  922140: 'Correctional Institutions',
  922150: 'Parole Offices and Probation Offices',
  922160: 'Fire Protection',
  922190: 'Other Justice, Public Order, and Safety Activities',
  923110: 'Administration of Education Programs',
  923120: 'Administration of Public Health Programs',
  923130: 'Administration of Human Resource Programs (except Education, Public Health, and Veterans’ Affairs Programs)',
  923140: 'Administration of Veterans’ Affairs',
  924110: 'Administration of Air and Water Resource and Solid Waste Management Programs',
  924120: 'Administration of Conservation Programs',
  925110: 'Administration of Housing Programs',
  925120: 'Administration of Urban Planning and Community and Rural Development',
  926110: 'Administration of General Economic Programs',
  926120: 'Regulation and Administration of Transportation Programs',
  926130: 'Regulation and Administration of Communications, Electric, Gas, and Other Utilities',
  926140: 'Regulation of Agricultural Marketing and Commodities',
  926150: 'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors',
  927110: 'Space Research and Technology',
  928110: 'National Security',
  928120: 'International Affairs',
  99: 'Cannabis production, distribution, or sales',
  990000: 'Cannabis production, distribution, or sales',
  999999: 'Otherwise unclassified'
 };

const NAICS_2017 = {
  212111:	'Bituminous Coal and Lignite Surface Mining',
  212112: 'Bituminous Coal Underground Mining',
  212221: 'Gold Ore Mining',
  212222: 'Silver Ore Mining',
  212291: 'Uranium-Radium-Vanadium Ore Mining',
  212299: 'All Other Metal Ore Mining',
  212324: 'Kaolin and Ball Clay Mining',
  212325: 'Clay and Ceramic and Refractory Minerals Mining',
  212391: 'Potash, Soda, and Borate Mineral Mining',
  212392: 'Phosphate Rock Mining',
  212393: 'Other Chemical and Fertilizer Mineral Mining',
  212399: 'All Other Nonmetallic Mineral Mining',
  315110: 'Hosiery and Sock Mills',
  315190: 'Other Apparel Knitting Mills',
  315220: 'Men’s and Boys’ Cut and Sew Apparel Manufacturing',
  315240: 'Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing',
  315280: 'Other Cut and Sew Apparel Manufacturing',
  316992: 'Women’s Handbag and Purse Manufacturing',
  316998: 'All Other Leather Good and Allied Product Manufacturing',
  321213: 'Engineered Wood Member (except Truss) Manufacturing',
  321214: 'Truss Manufacturing',
  322121: 'Paper (except Newsprint) Mills',
  322122: 'Newsprint Mills',
  333244: 'Printing Machinery and Equipment Manufacturing',
  333249: 'Other Industrial Machinery Manufacturing',
  333314: 'Optical Instrument and Lens Manufacturing',
  333316: 'Photographic and Photocopying Equipment Manufacturing',
  333318: 'Other Commercial and Service Industry Machinery Manufacturing',
  333997: 'Scale and Balance Manufacturing',
  333999: 'All Other Miscellaneous General Purpose Machinery Manufacturing',
  334613: 'Blank Magnetic and Optical Recording Media Manufacturing',
  334614: 'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing',
  335110: 'Electric Lamp Bulb and Part Manufacturing',
  335121: 'Residential Electric Lighting Fixture Manufacturing',
  335122: 'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing',
  335129: 'Other Lighting Equipment Manufacturing',
  335911: 'Storage Battery Manufacturing',
  335912: 'Primary Battery Manufacturing',
  336111: 'Automobile Manufacturing',
  336112: 'Light Truck and Utility Vehicle Manufacturing',
  337124: 'Metal Household Furniture Manufacturing',
  337125: 'Household Furniture (except Wood and Metal) Manufacturing',
  424320: 'Men’s and Boys’ Clothing and Furnishings Merchant Wholesalers',
  424330: 'Women’s, Children’s, and Infants’ Clothing and Accessories Merchant Wholesalers',
  425110: 'Business to Business Electronic Markets',
  441228: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers',
  441310: 'Automotive Parts and Accessories Stores',
  441320: 'Tire Dealers',
  442110: 'Furniture Stores',
  442210: 'Floor Covering Stores',
  442291: 'Window Treatment Stores',
  442299: 'All Other Home Furnishings Stores',
  443141: 'Household Appliance Stores',
  443142: 'Electronics Stores',
  444130: 'Hardware Stores',
  444190: 'Other Building Material Dealers',
  444210: 'Outdoor Power Equipment Stores',
  444220: 'Nursery, Garden Center, and Farm Supply Stores',
  445120: 'Convenience Stores',
  445210: 'Meat Markets',
  445220: 'Fish and Seafood Markets',
  445299: 'All Other Specialty Food Stores',
  445310: 'Beer, Wine, and Liquor Stores',
  446110: 'Pharmacies and Drug Stores',
  446120: 'Cosmetics, Beauty Supplies, and Perfume Stores',
  446130: 'Optical Goods Stores',
  446191: 'Food (Health) Supplement Stores',
  446199: 'All Other Health and Personal Care Stores',
  447110: 'Gasoline Stations with Convenience Stores',
  447190: 'Other Gasoline Stations',
  448110: 'Men’s Clothing Stores',
  448120: 'Women’s Clothing Stores',
  448130: 'Children’s and Infants’ Clothing Stores',
  448140: 'Family Clothing Stores',
  448150: 'Clothing Accessories Stores',
  448190: 'Other Clothing Stores',
  448210: 'Shoe Stores',
  448310: 'Jewelry Stores',
  448320: 'Luggage and Leather Goods Stores',
  451110: 'Sporting Goods Stores',
  451120: 'Hobby, Toy, and Game Stores',
  451130: 'Sewing, Needlework, and Piece Goods Stores',
  451140: 'Musical Instrument and Supplies Stores',
  451211: 'Book Stores',
  451212: 'News Dealers and Newsstands',
  452210: 'Department Stores',
  452311: 'Warehouse Clubs and Supercenters',
  452319: 'All Other General Merchandise Stores',
  453110: 'Florists',
  453210: 'Office Supplies and Stationery Stores',
  453220: 'Gift, Novelty, and Souvenir Stores',
  453310: 'Used Merchandise Stores',
  453910: 'Pet and Pet Supplies Stores',
  453920: 'Art Dealers',
  453930: 'Manufactured (Mobile) Home Dealers',
  453991: 'Tobacco Stores',
  453998: 'All Other Miscellaneous Store Retailers (except Tobacco Stores)',
  454110: 'Electronic Shopping and Mail-Order Houses',
  454210: 'Vending Machine Operators',
  454310: 'Fuel Dealers',
  454390: 'Other Direct Selling Establishments',
  511110: 'Newspaper Publishers',
  511120: 'Periodical Publishers',
  511130: 'Book Publishers',
  511140: 'Directory and Mailing List Publishers',
  511191: 'Greeting Card Publishers',
  511199: 'All Other Publishers',
  511210: 'Software Publishers',
  515111: 'Radio Networks',
  515112: 'Radio Stations',
  515120: 'Television Broadcasting',
  515210: 'Cable and Other Subscription Programming',
  517311: 'Wired Telecommunications Carriers',
  517312: 'Wireless Telecommunications Carriers (except Satellite)',
  517911: 'Telecommunications Resellers',
  517919: 'All Other Telecommunications',
  519110: 'News Syndicates',
  519120: 'Libraries and Archives',
  519130: 'Internet Publishing and Broadcasting and Web Search Portals',
  519190: 'All Other Information Services',
  522120: 'Savings Institutions',
  522190: 'Other Depository Credit Intermediation',
  522293: 'International Trade Financing',
  522294: 'Secondary Market Financing',
  522298: 'All Other Nondepository Credit Intermediation',
  523110: 'Investment Banking and Securities Dealing',
  523120: 'Securities Brokerage',
  523130: 'Commodity Contracts Dealing',
  523140: 'Commodity Contracts Brokerage',
  523920: 'Portfolio Management',
  523930: 'Investment Advice',
  811112: 'Automotive Exhaust System Repair',
  811113: 'Automotive Transmission Repair',
  811118: 'Other Automotive Mechanical and Electrical Repair and Maintenance',
  811211: 'Consumer Electronics Repair and Maintenance',
  811212: 'Computer and Office Machine Repair and Maintenance',
  811213: 'Communication Equipment Repair and Maintenance',
  811219: 'Other Electronic and Precision Equipment Repair and Maintenance'
};

const
  naicsShortCodeOptions = [],
  naicsCodeOptions = {},
  allNAICSCodeOptions = [],
  searchValues = {},
  sort = (a, b) => {
    // Sort by the code instead of the name/text of the NAICS code
    if (a.value < b.value) {
      return -1;
    }
    return 1;
  },
  isValidNAICSCode = (code) => {
    // We're going to skip showing a bunch of 2017 NAICS values in the dropdown
    // See this ticket for details:
    // https://counsl.atlassian.net/browse/SONIC-2563

    // The code is valid as long as it is NOT in the `NAICS_2017` object
    return NAICS_2017[code] === undefined;
  },
  initialize = () => {
    Object.entries(NAICS).forEach(([code, label]) => {
      if (code.length === 2) {
        naicsShortCodeOptions.push({ label, value: code });
        naicsCodeOptions[code] = naicsCodeOptions[code] || [];
      } else {
        if (isValidNAICSCode(code)) {
          const shortCode = code.substring(0, 2);
          naicsCodeOptions[shortCode] = naicsCodeOptions[shortCode] || [];
          naicsCodeOptions[shortCode].push({label, value: code});
          allNAICSCodeOptions.push({label, value: code});
          searchValues[label.toLowerCase()] = code;
        }
      }
    });

    naicsShortCodeOptions.sort(sort);
    Object.entries(naicsCodeOptions).forEach(([, codeOptions]) => {
      codeOptions.sort(sort);
    });
    allNAICSCodeOptions.sort(sort);
  };

initialize();

export function getNAICSShortCodeOptions() {
  return naicsShortCodeOptions;
}

export function getNAICSCodeOptions(shortCode) {
  if (!shortCode) {
    return [];
  }
  if (shortCode.length > 2) {
    return naicsCodeOptions[shortCode.substring(0, 2)] || [];
  }

  return naicsCodeOptions[shortCode] || [];
}

export function getAllNAICSOptions() {
  return allNAICSCodeOptions;
}

export function getNAICSLabel(naics) {
  return NAICS[naics] || '';
}
