const environment = window.zgInit.DEPLOY_ENV;

export default {
  environment,
  clients: {
    auth0: {
      domain: window.zgInit.AUTH0_DOMAIN,
      audience: 'https://zen-business-core-api',
      clientId: window.zgInit.AUTH0_CLIENTID,
      redirectUri: window.zgInit.AUTH0_REDIRECT_URI,
      connection: window.zgInit.AUTH0_CONNECTION,
      host: window.zgInit.AUTH0_DASHBOARD_URL
    },
    filestack: {
      apiKey: window.zgInit.FILESTACK_API_KEY
    },
    salesforce: {
      host: window.zgInit.ZENBUSINESS_SALESFORCE_HOST
    },
    recurly: {
      host: window.zgInit.RECURLY_URL
    },
    stripe: {
      host: window.zgInit.STRIPE_URL
    },
    launchDarkly: {
      clientSideId: window.zgInit.LAUNCHDARKLY_CLIENT_SIDE_ID
    },
    rudderstack: {
      url: window.zgInit.RUDDERSTACK_URL,
      apiKey: window.zgInit.RUDDERSTACK_API_KEY
    },
  },
  features: {
    pushNotificationForDocUpload: {
      enabled: window.zgInit.PUSH_NOTIFICATION_FOR_DOC_UPLOAD === 'true'
    },
    opensrsMigration: {
      enabled: window.zgInit.OPENSRS_MIGRATION_ENABLED === 'true'
    }
  },
  urlHosts: {
    pushNotifications: window.zgInit.PUSH_NOTIFICATION_HOST
  }
};
