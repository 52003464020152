import auth0 from 'auth0-js';
import EventEmitter from 'eventemitter3';
import { jwtDecode } from 'jwt-decode';
import diff from 'date-fns/differenceInMinutes';
import manifest from '../manifest';

const loginStateKey = 'auth0StateValue';
const responseType = 'token id_token';

const OKTA_ZENGARDEN_SSO_PREFIX = 'samlp|OktaZenGarden';

// create an instance of auth0.WebAuth with your API and Client credentials
const auth0Client = new auth0.WebAuth({
  domain: manifest.clients.auth0.domain,
  clientID: manifest.clients.auth0.clientId,
  redirectUri: manifest.clients.auth0.redirectUri,
  audience: manifest.clients.auth0.audience,
  responseType,
  scope: 'openid email profile'
});

class AuthService {
  constructor() {
    this.storage = window.localStorage;
    this.login = this.login.bind(this);
    this.setSession = this.setSession.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.universalLogin = this.universalLogin.bind(this);
    this.logoutInProcess = false;
    this.authNotifier = new EventEmitter();
    this.authenticated = this.isAuthenticated();
  }

  universalLogin ({ redirectTo = null }) {
    this.logoutInProcess = false
    const stateValue = `zen-${Math.floor(Math.random() * 1000000)}`
    this.storage.setItem(loginStateKey, stateValue)

    if (redirectTo && redirectTo !== '/login' && redirectTo.startsWith('/')) {
      this.storage.setItem('redirectTo', encodeURIComponent(redirectTo))
    }

    auth0Client.authorize({
      response_type: responseType,
      client_id: manifest.clients.auth0.clientId,
      connection: manifest.clients.auth0.connection,
      redirect_uri: manifest.clients.auth0.redirectUri,
      state: stateValue,
      nonce: stateValue,
      audience: manifest.clients.auth0.audience
    });
  }

  login(username, password, redirectTo) {
    this.logoutInProcess = false;
    return new Promise((resolve, reject) => {
      const stateValue = `zen-${Math.floor(Math.random() * 1000000)}`;
      this.storage.setItem(loginStateKey, stateValue);
      if (redirectTo && redirectTo !== '/login' && redirectTo.startsWith('/')) {
        this.storage.setItem('redirectTo', encodeURIComponent(redirectTo));
      }
      auth0Client.login({
        state: stateValue,
        nonce: stateValue,
        username,
        password
      }, (err) => {
        if (err) {
          return reject(err);
        }
        return resolve();
      });
    });
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      const stateValue = this.storage.getItem(loginStateKey);

      auth0Client.parseHash({
        state: stateValue,
        nonce: stateValue
      }, (err, authResult) => {
        this.storage.removeItem(loginStateKey);
        if (authResult && authResult.accessToken && authResult.idToken) {
          return this.setSession(authResult).then(() => {
            this.authNotifier.emit('authChange', { authenticated: true, isAdmin: true });
            const redirectTo = this.storage.getItem('redirectTo');
            const target = redirectTo ? decodeURIComponent(redirectTo) : '/';
            if (redirectTo) {
              this.storage.removeItem('redirectTo');
            }
            if (window.location.pathname !== target) {
              window.location.assign(`${window.location.origin}${target}`);
            }
          }).catch((err) => (reject(err)));
        } if (err) {
          return reject(err);
        }
        return reject(new Error('Unknown handleAuthentication issue', authResult));
      });
    });
  }

  setSession(authResult) {
    return new Promise((resolve, reject) => {
      if (!authResult?.idTokenPayload?.sub?.startsWith(OKTA_ZENGARDEN_SSO_PREFIX)) {
        return reject(new Error(`Expected Okta authentication, but saw sub: ${authResult.idTokenPayload.sub}`));
      }

      // Set the time that the access token will expire at
      const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
      this.storage.setItem('access_token', authResult.accessToken);
      // read the permissions out of the JWT so we can control access to features.
      this.storage.setItem('permissions', JSON.parse(atob(authResult.accessToken.split('.')[1])).permissions);
      this.storage.setItem('id_token', authResult.idToken);
      this.storage.setItem('expires_at', expiresAt);
      auth0Client.client.userInfo(authResult.accessToken, (err, user) => {
        if (err) {
          return reject(err);
        }
        this.storage.setItem('loggin_in_email', user.email);
        this.storage.setItem('first_name', user.given_name);
        this.storage.setItem('last_name', user.family_name);
        return resolve();
      });
    });
  }

  cleanStorage() {
    this.storage.removeItem(loginStateKey);
    this.storage.removeItem('access_token');
    this.storage.removeItem('id_token');
    this.storage.removeItem('permissions');
    this.storage.removeItem('expires_at');
    this.storage.removeItem('user_name');
    this.storage.removeItem('user_full_name');
    this.storage.removeItem('user_email');
    this.storage.removeItem('redirectTo');
  }

  // remove the access and ID tokens from the local storage and emits the authChange event
  logout(opts = {}) {
    const { redirectTo } = opts;
    // Ignore number logout calls, one should be enough
    if (!this.logoutInProcess) {
      this.logoutInProcess = true
      this.cleanStorage()
      this.authNotifier.emit('authChange', false)
      let returnTo = `${window.location.origin}/login`
      if (redirectTo && redirectTo !== '/login' && redirectTo.startsWith('/')) {
        returnTo = `${returnTo}?redirectTo=${encodeURIComponent(redirectTo)}`
      }
      auth0Client.logout({
        returnTo
      });
    }
  }

  changePassword(email) {
    return new Promise((resolve, reject) => {
      auth0Client.changePassword({
        email,
        connection: 'Username-Password-Authentication'
      }, (err, resp) => {
        if (err) {
          return reject(err);
        }
        return resolve(resp);
      });
    });
  }

  /**
   * Extend user session during activity.
   * Call this function as often as wanted.
   * It will only extend once per 15 minutes, per Auth0 limitation on checkSession call.
   */
  extendSession() {
    return new Promise((resolve, reject) => {
      const idToken = this.storage.getItem('id_token');

      if (!this.isAuthenticated()) {
        return resolve();
      }

      let runCheck = true;
      if (idToken) {
        const jwt = jwtDecode(idToken);
        const issuedDate = new Date(jwt.iat * 1000);
        const minutesSince = diff(new Date(), issuedDate);
        runCheck = minutesSince >= 15;
      }

      if (runCheck) {
        const nonce = `zen-${Math.floor(Math.random() * 1000000)}`;
        auth0Client.checkSession({
          nonce,
          state: nonce
        }, (err, auth) => {
          if (err) {
            this.cleanStorage();
            if (err.code && err.code === 'login_required') {
              // Not authenticated
              return this.logout({
                redirectTo: window.location.pathname
              });
            }
            return reject(err);
          }

          if (!auth || !auth.expiresIn) {
            this.cleanStorage();
            return reject(
              new Error(`Invalid authentication during checkSession for state: ${nonce}, auth: ${JSON.stringify(auth)}`)
            );
          }
          return this.setSession(auth);
        });
      }

      return resolve();
    });
  }

  isAuthenticated() {
    const accessToken = this.getAccessToken();
    if (!accessToken) return false;
    try {
      const jwt = jwtDecode(accessToken);
      const expiresAt = new Date(jwt.exp * 1000);
      return Date.now() < expiresAt;
    } catch (e) {
      return false;
    }
  }

  getAccessToken() {
    return this.storage.getItem('access_token');
  }

  /**
   * @deprecated Use getAccessToken instead
   */
  getAuthToken() {
    return this.getAccessToken();
  }

  getIdToken() {
    return this.storage.getItem('id_token');
  }

  getLoggedInEmail() {
    return this.storage.getItem('loggin_in_email');
  }

  getFirstName() {
    return this.storage.getItem('first_name');
  }

  getFullName() {
    return `${this.storage.getItem('first_name')} ${this.storage.getItem('last_name')}`;
  }

  getNameAndInitialLastNameLetter() {
    return `${this.storage.getItem('first_name')} ${this.storage.getItem('last_name').charAt(0)}`;
  }
}

export default new AuthService();
