<template>
  <img src="@/assets/img/DogContact.svg" alt="Dog contact image">
</template>

<script>
export default {
  name: 'DogContactImg'
};
</script>

<style lang="scss" scoped>
  img {
    height: 100%;
    max-height: em(200);
    width: auto;
    max-width: em(180);
  }
</style>
