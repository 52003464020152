import { capitalizeFirstLetter } from '@/assets/utils/text-utils';
import { initialState } from './state.js'

export const mutations = {
  clearMigrationPreferenceCustomerAssets: (state) => {
    state.migrationPreferenceCustomerAssets = null;
  },
  resetZbMoneyState: (state) => {
    Object.assign(state, initialState());
  },
  setMigrationOptionsCustomerAssets: (state, migrationData) => {
    const migrations = []
    if (migrationData?.migrate_services) {
      for(const service of migrationData.migrate_services) {
        if(service.prices) {
          for (const detailPrice of service.prices) {
            migrations.push({
              service_product_uuid: service.service_product_uuid,
              service_product_name: service.service_product_name,
              display_name: service.display_name,
              display_option: `Migrate to ${service.display_name} - ${ capitalizeFirstLetter(detailPrice.term) }`,
              description: service.description,
              affiliate_plan_option_uuid: detailPrice.affiliate_plan_option_uuid,
              affiliate_pricing_uuid: detailPrice.affiliate_pricing_uuid,
              coupon: detailPrice.coupon,
              affiliate: detailPrice.affiliate,
              term: detailPrice.term,
            })
          }
        }
      }
    }
    state.migrationOptionsCustomerAssets = migrations;
  },
  setMigrationPreferenceCustomerAssets: (state, migrationPreference) => {
    state.migrationPreferenceCustomerAssets = migrationPreference;
  },
  setZbMoneyAccountData: (state, zbMoneyAccountData) => {
    state.zbMoneyAccountData = zbMoneyAccountData
  }
};

export default mutations;
