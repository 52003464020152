import httpclient from '../../httpclient';
import moment from 'moment';
import authService from '../../../auth/AuthService';

export default {
  addRequestError( { commit }, payload) {
    commit('addRequestError', {
      status: payload.status,
      url: payload.request.responseURL
    });
  },
  fetchJiraUsername({ commit }) {
    return httpclient.get(`/jira/usersearch?email=${authService.getLoggedInEmail()}`)
      .then( res => {
        return commit('setJiraUserId', res?.data?.[0]?.accountId)
      })
      .catch( err => {
        console.error(err)
      })
  },
  async postJiraTicket({ state, commit, dispatch }, jiraFormData) {
    const slackChannel = 'C7LASAKFT';
    const environment = process.env.NODE_ENV === 'production' ? 'Prod' : 'Dev';
    if (!state.jiraUserId) await dispatch('fetchJiraUsername'); // let's not make this call more than necessary
    
    const jiraPayload = {
      ...state.jiraIssuePayload
    };
    jiraPayload.fields.summary = jiraFormData.summary;
    jiraPayload.fields.reporter = { id: state.jiraUserId };

    jiraPayload.fields.customfield_10681 = {}
    jiraPayload.fields.customfield_10681.value = environment;

    const deepLinkEnv = environment.toLowerCase();
    const deepLinkProject = environment === 'Prod' ? 'zen-prod' : 'zen-dev-166315';
    const deepLinkData = /((\w{4,12}-?)){5}/.exec(jiraFormData.zgLink)?.[0];

    moment.utc()

    const hoursToLookupBack = 2;
    const endDatetime = new Date();
    const startDatetime = new Date(endDatetime.getTime());
    startDatetime.setHours(startDatetime.getHours() - hoursToLookupBack);

    const endDate = moment().utc()
    const startDate = endDate.clone().subtract(2, 'hours');    
    const endDatetimeFormatted = `${endDate.format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
    const startDatetimeFormatted = `${startDate.format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
    
    /* Whoa! What is happening? This is hideous!!! Why?!
    -- If you don't build the link ever so carefully
    -- GCP will refuse to read them
    -- AND Jira will turn the links into Emojiis
    -- ------Why? Nobody knows why
    -- ------Jira will do Jira things
    -- There is a rhyme and reason to what you see below
    -- For your own sanity...DON'T TOUCH
    */
    const deepLinkBaseUrl = 'https://console.cloud.google.com/logs/query;query='
    // eslint-disable-next-line
    const deepLinkUri = encodeURIComponent(`resource.type="k8s__container" resource.labels.cluster__name="zen-${deepLinkEnv}-gke-0"`).replaceAll('__', '\_');
    const deepLinkSearch = '%0A' + encodeURIComponent('"') + deepLinkData + encodeURIComponent('"') + ';';
    const deepLinkTimeRange = 'timeRange=' + encodeURIComponent(`${startDatetimeFormatted}`) + '%2F' + encodeURIComponent(`${endDatetimeFormatted}`);
    const deepLink = deepLinkBaseUrl + deepLinkUri + deepLinkSearch + deepLinkTimeRange + '?project=' + deepLinkProject;

    const requestErrors = state.requestErrors.map( error => {
      return `\r**Error URL:** ${error.url} **Error Status:** ${error.status}`
    });

    // Sentry Link
    const endDateSentry = moment().utc()
    const startDateSentry = endDateSentry.clone().subtract(1, 'day');
    const formatDateSentry = 'YYYY-MM-DDTHH:MM:SS';
    const sentryLinkItems = []
    sentryLinkItems.push(`https://sentry.io/organizations/zenbusiness-pbc/issues/`);
    sentryLinkItems.push(`?end=${encodeURIComponent(endDateSentry.format(formatDateSentry))}`);
    sentryLinkItems.push(`&query=user.email%3A${encodeURIComponent(jiraFormData.customerEmail)}`);
    sentryLinkItems.push(`&start=${encodeURIComponent(startDateSentry.format(formatDateSentry))}`);
    sentryLinkItems.push(`&utc=true`);
    const sentryLink = jiraFormData.customerEmail?sentryLinkItems.join(''):undefined;

    // Mandrill Link
    const mandrillLinkItems = []
    mandrillLinkItems.push(`https://mandrillapp.com/activity`);
    mandrillLinkItems.push(`&q=${encodeURIComponent(jiraFormData.customerEmail)}`);
    const mandrillLink = jiraFormData.customerEmail?mandrillLinkItems.join(''):undefined;

    const markDownText = `## Explanation of the problem
        \r${jiraFormData.description}
      \r### Website or App?
      ${jiraFormData.systemSource}
      \r### What device is the problem being experienced on?
        \r${jiraFormData.platform}
      \r### Product/Service/Process?
        \r${jiraFormData.problemSource}
      \r### What Browser are they using?
        \r${jiraFormData.browser}
      ----
      \r### Customer sentiment?
        \r${jiraFormData.customerSentimentText}
      \r### Number of customers impacted?
        \r${jiraFormData.customerNumberText}
      \r### Projected business impact?
        \r${jiraFormData.businessImpactText}
      \r### Projected customer impact?
        \r${jiraFormData.urgencyLevelText}
      ${jiraFormData.customerEmail ? `
      \r### Customer email
        \r${jiraFormData.customerEmail}` : ''}
      ----
      ${jiraFormData.sfLink || jiraFormData.zgLink || jiraFormData.sfCaseLink ? `\r### Debug Tools` : '\b'}
      ${jiraFormData.zgLink ? `\r[ZenGarden Link](${jiraFormData.zgLink})` : '\b'}
      ${jiraFormData.sfLink ? `\r[Salesforce Link](${jiraFormData.sfLink})` : '\b'}
      ${jiraFormData.sfCaseLink ? `\r[Salesforce Case Link](${jiraFormData.sfCaseLink})` : '\b'}
      ${sentryLink ? `\r[Sentry Query Link](${sentryLink})` : '\b'}
      ${mandrillLink ? `\r[Mandrill Query Link](${mandrillLink})` : '\b'}
      \b
      ${jiraFormData.zgLink && deepLinkData ? `\r### GCP Deeplinks` : '\b'}
      ${jiraFormData.zgLink.search('/business-entity/') !== -1 && deepLinkData ? `\r[Business Entity Deeplink](${deepLink})` : '\b'}
      ${jiraFormData.zgLink.search('/order/') !== -1 && deepLinkData ? `\r[Order Deeplink](${deepLink})` : '\b'}
      ${jiraFormData.zgLink.search('/account/') !== -1 && deepLinkData ? `\r[Account Deeplink](${deepLink})` : '\b'}
      \b
      ${
        requestErrors.length > 0
          ? `\r### ZenGarden Errors${requestErrors.join('\b')}`
          : '\b'
      }
    `
    
    commit('setMarkDownText', markDownText);
    const priorityLevel = await evaluatePriorityLevel(jiraFormData)
    const severityLevel = priorityLevel == 'High' ? 'Sev-1' : priorityLevel == 'Medium' ? 'Sev-2' : 'Sev-3';

    jiraPayload.md_description = markDownText; 
    jiraPayload.fields.labels = [ 'Triage' ];
    jiraPayload.fields.priority = { name: priorityLevel };
    jiraPayload.fields.customfield_10742 = { value: severityLevel };

    /* TODO: #HACKATHON
    ** this api call can be very useful for
    ** getting the data Jira expects. 
    ** Saving for now
    */
    // httpclient.get('/jira/createmeta')
    //   .then(data => {
    //     console.log(data)
    //   })
    return httpclient.post('/jira/createjira', jiraPayload)
      .then(res => {
        const msg = {
          primary: `Jira Issue #${res.data.key} was sucessfully created.`,
          secondary: `<a href="https://counsl.atlassian.net/browse/${res.data.key}" target="_blank">View issue now</a>`,
          type: 'alert alert-success'
        }
        commit('setAlertMessage', msg, { root: true });
        return res
      })
      .catch( () => {
        const msg = {
          primary: `There was a problem creating your issue.`,
          secondary: `Please post the issue in the <a href="https://zenbusinesspbc.slack.com/archives/${slackChannel}" target="_blank">app-prod Slack channel</a>`,
          type: 'alert alert-danger'
        }
        commit('setAlertMessage', msg, { root: true });
      })
  },
  async postAttachment(_, formData) {
    return httpclient.post('/jira/createjiraAttachment', formData)
      .catch( err => {
        console.error(err)
      })
}
};

function evaluatePriorityLevel(jiraFormData) {
  const total = jiraFormData.customerSentiment + jiraFormData.businessImpact +
    jiraFormData.customerNumber + jiraFormData.urgencyLevel
  let urgency = 'Low';
  if(total > 12) urgency = 'High'; else if(total > 8) urgency = 'Medium'; else urgency = 'Low' 
  return urgency;
}
