import httpclient from '../../httpclient';
import messages from '../../../assets/messages';
import {httpRequestErrorHandling} from '../utils';
import router from '../../../router'
import authService from '../../../auth/AuthService';

export default {
  fetchUserEmail({ commit }) {
    const email = authService.getLoggedInEmail();
    if (email) {
      commit('setUserEmail', email);
      return email;
    }
    return null;
  },
  searchByUUID({ commit }, { userUuid }) {
    return httpclient.get(`/api/users/${userUuid}`)
      .then((response) => {
        let accountUuid = response.data?.account_uuid;
        router.push({ name: 'Account', params: { accountUuid } })
      })
      .catch((e) => { 
        if (e?.response?.status === 404) {
          httpRequestErrorHandling(e, commit, messages.searchUserUuidNotFound);
        }
        else {
          httpRequestErrorHandling(e, commit, messages.searchUserUuid);
        }
      });
  },
}
