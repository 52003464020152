export default {
  automationConfig: [],
  order: {},
  sosStatus: [],
  orders: [],
  orderTasks: [],
  isLoadingOrderTasks: true,
  orderTask: {},
  businessLicenseReport: null,
  transactionalBillingItems: [],
  subscriptionBillingItems: [],
  billingInvoices: [],
  refunds: [],
  canGenerateFormation: false,
  newOrderNote: {
    order_note_content: null,
    order_note_created_by: null
  },
  businessEntity: {},
  raAddresses: {},
  refundReasons: [],
  cancelReasons: [],
  cancelAndRefundReasons: [],
  plans: {},
  automatedFilingTracking: [],
  eligibleDowngradePlans: {},
  entityStatusAtSOS: [],
  orderTracking: {},
  retryOrderCreation: {
    reprocessOrderWithoutTasks: null,
    reprocessOrderTasksCreated: null,
    reprocessCompletedOrder: null,
    missedChargeEvents: null,
    bulkSendOrdersToSalesforce: null,
  },
};
