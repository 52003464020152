import Vue from 'vue';
import Router from 'vue-router';
import Home from '../components/home/home';
import Login from '../components/auth/login';
import Callback from '../components/auth/callback';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: Home
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/callback',
      name: 'Callback',
      component: Callback
    },
    {
      path: '/account',
      name: 'AccountList',
      component: () => import(/* webpackChunkName: "account" */ '@/components/accounts/account-list')
    },
    {
      path: '/account/create',
      name: 'AccountCreate',
      component: () => import(/* webpackChunkName: "account" */'@/components/accounts/account-create')
    },
    {
      path: '/general/document-descriptions',
      name: 'DocumentDescriptions',
      component: () => import(/* webpackChunkName: "document" */'@/components/general/document-descriptions')
    },
    {
      path: '/account/:accountUuid',
      component: () => import(/* webpackChunkName: "account" */'@/components/accounts/account'),
      props: true,
      children: [
        {
          path: '',
          name: 'Account',
          component: () => import(/* webpackChunkName: "account" */'../components/accounts/account-orders-view'),
          props: true
        },
        {
          path: 'orders',
          name: 'AccountOrdersView',
          component: () => import(/* webpackChunkName: "account" */'../components/accounts/account-orders-view'),
          props: true
        },
        {
          path: 'charges',
          name: 'ChargesView',
          component: () => import(/* webpackChunkName: "account" */'../components/accounts/ChargesView'),
          props: true
        },
        {
          path: 'business-entities',
          name: 'AccountBusinessEntitiesView',
          component: () => import(/* webpackChunkName: "account" */'../components/accounts/account-business-entities-view'),
          props: true
        },
        {
          path: 'customer-assets',
          name: 'CustomerAssetsView',
          component: () => import(/* webpackChunkName: "account" */'@/v2/zb-money/components/CustomerAssetsView'),
          props: true
        },
        {
          path: 'users',
          name: 'UsersInfoView',
          component: () => import(/* webpackChunkName: "account" */'../components/accounts/users-view'),
          props: true
        },
        {
          path: 'info',
          name: 'AccountInfoView',
          component: () => import(/* webpackChunkName: "account" */'../components/accounts/account-view'),
          props: true
        },
        {
          path: 'device-info',
          name: 'AccountDeviceInfoView',
          component: () => import(/* webpackChunkName: "account" */'@/v2/account-info/components/AccountDeviceInfoView'),
          props: true
        }
      ]
    },
    {
      path: '/account/:accountUuid/edit',
      name: 'AccountEdit',
      component: () => import(/* webpackChunkName: "account" */'@/components/accounts/account-edit'),
      props: true
    },
    {
      path: '/order',
      name: 'OrderList',
      component: () => import(/* webpackChunkName: "order" */'@/components/orders/order-list')
    },
    {
      path: '/order/account/:accountUuid/create',
      component: () => import(/* webpackChunkName: "order" */'@/v2/order-creation/components/order-create'),
      props: true,
      children: [
        {
          path: '',
          name: 'OrderCreate',
          component: () => import(/* webpackChunkName: "order" */'@/v2/order-creation/components/order-create-select-items'),
          props: true
        }
      ]
    },
    {
      path: '/order/:uuid',
      component: () => import(/* webpackChunkName: "order" */'@/components/orders/order'),
      props: true,
      children: [
        {
          path: '',
          name: 'Order',
          component: () => import(/* webpackChunkName: "order" */'../components/orders/order-tasks-view'),
          props: true
        },
        {
          path: 'tasks',
          name: 'OrderTasksView',
          component: () => import(/* webpackChunkName: "order" */'../components/orders/order-tasks-view'),
          props: true
        },
        {
          path: 'billing',
          name: 'OrderBillingView',
          component: () => import(/* webpackChunkName: "order" */'../components/orders/billing/order-billing-view'),
          props: true
        },
        {
          path: 'notes',
          name: 'OrderNotesView',
          component: () => import(/* webpackChunkName: "order" */'../components/orders/order-notes-view'),
          props: true
        },
        {
          path: 'notes/create',
          name: 'OrderNoteCreate',
          component: () => import(/* webpackChunkName: "order" */'@/components/orders/order-note-create'),
          props: true
        },
        {
          path: 'raw',
          name: 'OrderRawView',
          component: () => import(/* webpackChunkName: "order" */'../components/orders/order-raw-view'),
          props: true
        },
        {
          path: 'service/:taskUuid',
          name: 'OrderServiceDataView',
          component: () => import(/* webpackChunkName: "order" */'../components/orders/order-service-data-view'),
          props: true
        },
        {
          path: 'repair',
          name: 'RepairView',
          component: () => import(/* webpackChunkName: "order" */'../components/orders/services/repair-view'),
          props: true
        }
      ]
    },
    {
      path: '/business-entity',
      name: 'BusinessEntityList',
      component: () => import(/* webpackChunkName: "businessEntity" */'@/components/business-entities/business-entity-list')
    },
    {
      path: '/business-entity/:businessEntityUuid',
      component: () => import(/* webpackChunkName: "businessEntity" */'@/components/business-entities/business-entity'),
      props: true,
      children: [
        {
          path: '',
          name: 'BusinessEntity',
          component: () => import(/* webpackChunkName: "businessEntity" */'../components/business-entities/business-entity-view'),
          props: true
        },
        {
          path: 'info',
          name: 'BusinessEntityInfo',
          component: () => import(/* webpackChunkName: "businessEntity" */'../components/business-entities/business-entity-view'),
          props: true
        },
        {
          path: 'documents',
          name: 'BusinessEntityDocuments',
          component: () => import(/* webpackChunkName: "businessEntity" */'../components/business-entities/business-entity-documents'),
          props: true
        },
        {
          path: 'webservices',
          name: 'BusinessEntityWebServices',
          component: () => import(/* webpackChunkName: "businessEntity" */'@/v2/web-services/components/business-entity-web-services'),
          props: true
        },
        {
          path: 'dbas',
          name: 'BusinessEntityDbas',
          component: () => import(/* webpackChunkName: "businessEntity" */'@/v2/dba/components/business-entity-dbas'),
          props: true
        },
        {
          path: 'changelog',
          name: 'BusinessEntityChangelog',
          component: () => import(/* webpackChunkName: "businessEntity" */'../components/business-entities/business-entity-changelog'),
          props: true
        }
      ]
    },
    {
      path: '/business-entity/:businessEntityUuid/edit',
      name: 'BusinessEntityEdit',
      component: () => import(/* webpackChunkName: "businessEntity" */'@/components/business-entities/business-entity-edit'),
      props: true
    },
    {
      path: '/business-entity/:businessEntityUuid/webservices-edit',
      name: 'BusinessEntityWebServicesEdit',
      component: () => import(/* webpackChunkName: "businessEntity" */'@/v2/web-services/components/business-entity-web-services-edit'),
      props: true
    },
    {
      path: '/business-entity/:businessEntityUuid/add-external-domain',
      name: 'BusinessEntityExternalDomain',
      component: () => import(/* webpackChunkName: "businessEntity" */'@/v2/web-services/components/business-entity-external-domain-create'),
      props: true
    },
    {
      path: '/business-entity/:businessEntityUuid/documents/:documentUuid/view',
      name: 'BusinessEntityDocumentView',
      component: () => import(/* webpackChunkName: "businessEntity" */'@/components/business-entities/business-entity-document-view'),
      props: true
    },
    {
      path: '/business-entity/account/:accountUuid/create',
      name: 'BusinessEntityCreate',
      component: () => import(/* webpackChunkName: "businessEntity" */'@/components/business-entities/business-entity-create'),
      props: true
    },
    {
      path: '/products/create-new-affiliate',
      name: 'CreateNewAffiliate',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/create-new-affiliate'),
    },
    {
      path: '/products/affiliate-pricing',
      name: 'AffiliatePricingList',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/affiliate-pricing-list'),
    },
    {
      path: '/products/coupons',
      name: 'CouponList',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/coupon-list'),
      props: true
    },
    {
      path: '/products/affiliate-plan-options',
      name: 'AffiliatePlanOptionList',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/affiliate-plan-option-list'),
      props: true
    },
    {
      path: '/products/plan-options',
      name: 'PlanOptionList',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/plan-option-list'),
      props: true
    },
    {
      path: '/products/service-fees',
      name: 'ServiceFeeList',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/service-fee-list'),
      props: true
    },
    {
      path: '/products/services-c-corp',
      name: 'ServicesCCorpList',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/services-c-corp-list'),
      props: false
    },
    {
      path: '/products/services-fulfillment',
      name: 'ServicesFulfillment',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/services-fulfillment'),
      props: false
    },
    {
      path: '/products/service-pricing',
      name: 'ServicePricingList',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/service-pricing-list'),
    },
    {
      path: '/products/service-products',
      name: 'ServiceProductsList',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/service-products-list'),
    },
    {
      path: '/products/service-product-plans',
      name: 'ServiceProductPlansList',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/service-product-plans-list'),
    },
    {
      path: '/products/amendment-report-field-config',
      name: 'AmendmentReportFieldConfig',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/amendment-report-field-config'),
      props: true
    },
    {
      path: '/products/amendment-report-field-config/edit',
      name: 'EditAmendmentReportFieldConfig',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/edit-amendment-report-field-config'),
      props: true
    },
    {
      path: '/products/amendment-report-field-config/add',
      name: 'AddAmendmentReportField',
      component: () => import(/* webpackChunkName: "products" */'@/components/products/add-amendment-report-field'),
      props: true
    },
    {
      path: '/automation/automation-status',
      name: 'AutomationStatus',
      component: () => import(/* webpackChunkName: "automation" */'@/v2/automation/components/automation-status'),
      props: true
    },
    {
      path: '/automation/automated-filings',
      name: 'AutomatedFilings',
      component: () => import(/* webpackChunkName: "automation" */'@/v2/automation/components/automated-filings'),
      props: true
    },
    {
      path: '/automation/generate-payload',
      name: 'GeneratePayload',
      component: () => import(/* webpackChunkName: "automation" */'@/v2/automation/components/generate-payload'),
      props: true
    },
    {
      path: '/automation/retry-callback',
      name: 'RetryCallback',
      component: () => import(/* webpackChunkName: "automation" */'@/v2/automation/components/retry-callback'),
      props: true
    },
    {
      path: '/automation/automated-filing-uuid-search',
      name: 'AutomatedFilingUUIDSearch',
      component: () => import(/* webpackChunkName: "automation" */'@/v2/automation/components/automated-filing-uuid-search'),
      props: true
    },
    {
      path: '/automation/ca-login-access-queue',
      name: 'LoginAccessQueue',
      component: () => import(/* webpackChunkName: "automation" */'@/v2/automation/components/LoginAccessQueue'),
      props: true
    },
    {
      path: '/developers/data-alerts',
      name: 'DataAlertsList',
      component: () => import(/* webpackChunkName: "developers" */'@/components/developers/data-alerts/data-alerts-list'),
      props: true
    },
    {
      path: '/developers/data-alerts/:id',
      name: 'DataAlert',
      component: () => import(/* webpackChunkName: "developers" */'@/components/developers/data-alerts/data-alert'),
      props: true
    },
    {
      path: '/developers/components',
      name: 'Components',
      component: () => import(/* webpackChunkName: "developers" */'@/components/developers/components'),
      props: true
    },
    {
      path: '/developers/retry-order-creation',
      name: 'RetryOrderCreation',
      component: () => import(/* webpackChunkName: "developers" */'@/components/developers/retry-order-creation'),
      props: true
    },
    {
      path: '/zebra',
      name: 'ZeBRA',
      component: () => import(/* webpackChunkName: "zebra" */'@/v2/zebra/components/ZeBRA'),
      props: true,
    }
  ]
});
