import { generateSortFunction } from '../utils';
// import Vue from 'vue';

export default {
  setProducts: (state, products) => {
    state.products = products;
  },
  setAffiliates: (state, affiliates) => {
    // Sort by name
    const sortByName = generateSortFunction([
      { accessor: (aff) => aff.name, ascending: true }
    ]);
    state.affiliates = affiliates.sort(sortByName);
  },
  setDeactivatedAffiliates: (state, deactivated_affiliates) => {
    // Sort by name
    const sortByName = generateSortFunction([
      { accessor: (aff) => aff.name, ascending: true }
    ]);
    state.deactivated_affiliates = deactivated_affiliates.sort(sortByName);
  },
  setPlanProductsV2: (state, planProductsV2) => {
    state.planProductsV2 = planProductsV2;
  },
  setPlanOptionsV2: (state, planOptionsV2) => {
    state.planOptionsV2 = planOptionsV2;
  },
  setPlanOptionsV3: (state, planOptionsV3) => {
    state.planOptionsV3 = planOptionsV3;
  },
  setServiceFees: (state, serviceFees) => {
    serviceFees.forEach((fee) => {
      const product = state.products.find((p) => p.service === fee.service);
      fee.display_name = product ? product.display_name : fee.service;
    });
    state.serviceFees = serviceFees;
  },
  setServicePricing: (state, servicePricing) => {
    servicePricing.forEach((pricing) => {
      const product = state.products.find((p) => p.service === pricing.service);
      pricing.display_name = product ? product.display_name : pricing.service;
    });
    const sortByName = generateSortFunction([
      { accessor: (pricing) => pricing.display_name.toUpperCase(), ascending: true }
    ]);
    state.servicePricing = servicePricing.sort(sortByName);
  },
  setServicePricingV2: (state, servicePricingV2) => {
    let byGroupAndService = {}
    servicePricingV2.forEach((pricing) => {
      const priceGroup = pricing.price_group
      const service = pricing.service
      const serviceInterval = pricing.interval
      if (!(priceGroup in byGroupAndService)) {
        byGroupAndService[priceGroup] = {}
      }
      if (!byGroupAndService[priceGroup][service])
      {
        byGroupAndService[priceGroup][service] = {}
      }

      byGroupAndService[priceGroup][service][serviceInterval] = pricing


      const product = state.products.find((p) => p.service === pricing.service);
      pricing.display_name = product ? product.display_name : pricing.service;
    });
    state.servicePricingByGroupAndService = byGroupAndService
    const sortByName = generateSortFunction([
      { accessor: (pricing) => pricing.display_name.toUpperCase(), ascending: true }
    ]);
    state.servicePricing = servicePricingV2.sort(sortByName);

  },
  setReportFieldConfig: (state, reportFieldConfig) => {
    state.reportFieldConfig = reportFieldConfig;
  },
  setCoupons: (state, coupons) => {
    state.coupons = coupons;
  },
  setCoupon: (state, coupon) => {
    state.coupon = coupon;
  },
  setAvailablePlans: (state, plans) => {
    state.availablePlans = plans
  },
  setAffiliatePlanOptions: (state, plans) => {
    state.affiliatePlanOptions = plans
  },
  setPlanOptions: (state, planOptions) => {
    // Sort by plan name, plan price
    const sortPlanOptions = generateSortFunction([
      { accessor: (po) => po.related_plan_pricing.service, ascending: true },
      { accessor: (po) => po.related_plan_pricing.amount, ascending: true }
    ]);
    state.planOptions = planOptions.sort(sortPlanOptions);
  },
  setJurisdictionStates: (state, jurisdiction_states) => {
    state.jurisdictionStates = jurisdiction_states
  },
  setCCorpTerms: (state, terms) => {
    state.ccorpTerms = terms
  },
  setCCorpJurisdictionFields: (state, fields) => {
    state.ccorpFields = fields.data
  },
  setCCorpJurisdictionFees: (state, fees) => {
    state.ccorpFees = fees.data
  }
};
