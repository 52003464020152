<template>
  <div class="field-copy" @mouseover="onMouseover" @mouseleave="onMouseleave" @click="copy">
    <slot></slot>
    <ClipboardCopy
      ref="clipboard"
      :showCopied="showCopied"
      :showCopy="showCopy"
      :value="valueToCopy"
      @changeShowCopied="changeShowCopied">
    </ClipboardCopy>
  </div>
</template>

<script>
import { clipboardCopyMixin } from '@/components/mixins';
import ClipboardCopy from '@/components/v2/common/Generics/ClipboardCopy';

export default {
    name: "SlotCopy",
    props: ["valueToCopy"],
    mixins: [clipboardCopyMixin],
    components: {
      ClipboardCopy
    },
    methods: {
      copy() {
        if (this.$refs.cliboard) {
          this.$refs.cliboard.doCopy();
        }
      }
    },
};
</script>
<style>
  .field-copy {
    cursor: pointer;
  }
</style>
