import httpclient from '@/store/httpclient';
import messages from '@/assets/messages';
import { commitResponseToStore, httpRequestErrorHandling, validateParams } from '@/store/modules/utils';
import { state } from '@/store/modules/business-entities/state';

export const actions = {
    async setBusinessEntityDomains({ commit }, { uuid }) {
      return httpclient.get(`/api/v2/domains?business_entity_uuid=${uuid}&include_deleted=true&page_size=50`)
      .then(commitResponseToStore(commit, 'setBusinessEntityDomains'))
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    },
    async setBusinessEntityWebsites({ commit }, {  businessEntityUuid }) {
      return httpclient.get(`/api/v2/websites?business_entity_uuid=${encodeURIComponent(businessEntityUuid)}&expand=true`)
      .then(commitResponseToStore(commit, 'setBusinessEntityWebsites'))
      .catch((e) => { httpRequestErrorHandling(e, commit) });
    },
    generateAuthCode({ commit }, { domainUuid }) {
      return httpclient.post(`/api/v2/domains/${domainUuid}/auth-code`)
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    },
    getAuthCode({ commit }, { domainUuid }) {
      return httpclient.get(`/api/v2/domains/${domainUuid}/auth-code`)
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    },
    fetchBusinessEntityWebSites({ commit }, { uuid, website_url, account_uuid }) {
        if (!validateParams.UUID(uuid)) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
        } else {
        // This was call was not returning valid data because business_entity_website_url is consistently returning NULL
        if (!website_url !== null) {
            httpclient.get(`/api/v2/accounts/${account_uuid}/auth0-user-info`)
            .then((response) => {
              return httpclient.get(`/webservices/v1/websites?businessEntityUuid=${uuid}`, { accountUserName: response.data[0]?.email })
                .then(commitResponseToStore(commit, 'setWebsite', (response) => {
                  const websites = response.data.results;
                  if (websites.length > 1) {
                    const publishedSite = websites.find(site => site.publish_status === 'PUBLISHED');
                    if (publishedSite === undefined) {
                      return {...websites.find(site => site.website_type === 'dudaEcommDemo')}
                    }
                    return {...publishedSite}
                  }
                return {...websites[0]}
                }))
              })
        }
        }
      },
      fetchDomains({ commit }, { uuid }) {
        if(!(validateParams.UUID(uuid))) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID);
        } else {
          return httpclient.get(`/api/v2/domains?business_entity_uuid=${uuid}&page_size=50`)
            .then((response) => {
              commit('setBusinessEntityDomains', response.data);
            })
            .catch((e) => { httpRequestErrorHandling(e, commit) });
        }
      },
      fetchEmails({ commit }, { uuid }) {
        if(!(validateParams.UUID(uuid))) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID);
        } else {
          return httpclient.get(`/api/domains/emails/business-entity/${uuid}`)
          .then((response) => {
            commit('setBusinessEntityEmails', response.data);
          }).catch((e) => { httpRequestErrorHandling(e, commit) });
        }
      },
      fetchDomainDns({ commit }, { dnsRecordUuid }) {
        if(!(validateParams.UUID(dnsRecordUuid))) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID);
        } else {
          return httpclient.get(`/api/v2/domains/dns/${dnsRecordUuid}`)
          .then((response) => {
            return response.data;
          }).catch((e) => { httpRequestErrorHandling(e, commit) });
        }
      },
      createDomainDns({ commit }, { domainUuid, body }) {
        if(!(validateParams.UUID(domainUuid))) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID);
        } else {
          return httpclient.post(`/api/v2/domains/${domainUuid}/dns`, body)
          .then((response) => {
            return response.data;
          }).catch((e) => { httpRequestErrorHandling(e, commit) });
        }
      },
      updateDomainDns({ commit }, { dnsRecordUuid, body }) {
        if(!(validateParams.UUID(dnsRecordUuid))) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID);
        } else {
          return httpclient.patch(`/api/v2/domains/dns/${dnsRecordUuid}`, body)
          .then((response) => {
            return response.data;
          }).catch((e) => { httpRequestErrorHandling(e, commit) });
        }
      },
      deleteDomainDns({ commit }, { dnsRecordUuid }) {
        if(!(validateParams.UUID(dnsRecordUuid))) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID);
        } else {
          return httpclient.delete(`/api/v2/domains/dns/${dnsRecordUuid}`)
          .then((response) => {
            return response.data;
          }).catch((e) => { httpRequestErrorHandling(e, commit) });
        }
      },
      // zenapi needs to support updating website_url before updating this to v2
      updateWebsite({ commit }, {
        websiteUuid, newWebsiteUrl, newWebsiteDomain, newZenbusinessDNS, newActive, newDeleted
      }) {
        return httpclient.patch(`/api/v2/websites/${websiteUuid}`, {
          website_url: newWebsiteUrl, website_domain: newWebsiteDomain, zenbusiness_dns: newZenbusinessDNS, active: newActive,
          deleted: newDeleted,
        })
          .then((response) => {
            commit('setWebsite', response.data);
          })
          .catch((e) => { httpRequestErrorHandling(e, commit) });
      },
      updateDomain({ commit }, {
        domain_uuid, domainUpdateBody
      }) {
        if (!validateParams.UUID(domain_uuid)) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
        } else {
          return httpclient.patch(`/api/v2/domains/${domain_uuid}?include_deleted=true`, domainUpdateBody)
            .then((response) => {
              return response;
            })
            .catch((e) => { httpRequestErrorHandling(e, commit) });
        }
      },
      createDomain({ commit }, data) {
        const { businessEntityUUID, isExternal, domainName } = data;
        if (!(validateParams.UUID(businessEntityUUID) && domainName)) {
          return httpRequestErrorHandling(new Error(messages.validationParameters), commit, messages.validationParameters)
        } else {
          return httpclient.post('/api/v2/domains', { business_entity_uuid: businessEntityUUID, is_external: isExternal, domain_name: domainName })
            .then(() => {
              console.log('created domain');
            })
            .catch((e) => { httpRequestErrorHandling(e, commit) });
        }
      },
      linkWebsiteToDomain({ commit }, { domainUUID, websiteUUID, accountUuid }) {
        if(!(validateParams.UUID(domainUUID) && validateParams.UUID(websiteUUID))) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
        } else {
          return httpclient.get(`/api/v2/accounts/${accountUuid}/auth0-user-info`)
            .then((response) => {
              return httpclient.put(`/webservices/v1/domains/${domainUUID}`, { websiteUuid: websiteUUID }, { accountUserName: response.data[0]?.email })
                .then(() => {
                  commit('setAlertMessage', messages.linkDomain.success, { root: true });
                })
                .catch((e) => { httpRequestErrorHandling(e, commit, messages.linkDomain) });
              })
            .catch((e)=>{ httpRequestErrorHandling(e, commit, messages.linkDomain) });
        }
      },
      resendVerificationEmail({ commit }, { domainUuid, data }) {
        return httpclient.post(`/api/v2/domains/${domainUuid}/resend-verification-email`, data)
          .catch((e) => { httpRequestErrorHandling(e, commit) });
      },
      syncDNS({ commit }, { uuid }) {
        if (!validateParams.UUID(uuid)) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
        } else {
          return httpclient.post(`/api/v2/domains/${uuid}/dns/sync`)
            .then(() => {
              commit('setAlertMessage', messages.syncDNS.success, { root: true });
            })
            .catch((e) => { httpRequestErrorHandling(e, commit, messages.syncDNS) });
        }
      },
      syncNameservers({ commit }, { uuid }) {
        if (!validateParams.UUID(uuid)) {
          return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
        } else {
          return httpclient.post(`/api/v2/domains/${uuid}/nameservers/sync`)
            .then(() => {
              commit('setAlertMessage', messages.syncNameservers.success, { root: true });
            })
            .catch((e) => { httpRequestErrorHandling(e, commit, messages.syncNameservers) });
        }
      },
      updateEmail({ commit }, {
        uuid, active, emailAddress, domainUUID
      }) {
        if (!(validateParams.UUID(uuid) && validateParams.UUID(domainUUID))) {
          return httpRequestErrorHandling(new Error(messages.validationParameters), commit, messages.validationParameters)
        } else {
          return httpclient.put(`/api/domains/emails/${uuid}`, { active, email_address: emailAddress, domain_uuid: domainUUID })
            .then(() => {
              console.log('updated emails');
            })
            .catch((e) => { httpRequestErrorHandling(e, commit) });
        }
      },
      fetchNameservers({ commit }, domainsUuids) {
        for (const uuid of domainsUuids) {
          if (!validateParams.UUID(uuid)) {
            return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
          }
        }
        return Promise.all(
          domainsUuids.map(
            domainUuid => httpclient.get(`/api/v2/domains/${encodeURIComponent(domainUuid)}`)
              .then(({ data: { domain_uuid, domain_nameservers } = {} }) => ({ [domain_uuid]: domain_nameservers }))
          ))
        .then(data => data.reduce((acc, item) => ({...acc, ...item}), []))
        .catch((e) => { httpRequestErrorHandling(e, commit) });
      },
      updateNameservers({ commit }, nameserversChanges) {
        const incomingCreates = [];
        const incomingUpdates = [];
        const incomingDeletes = [];
        for (const key in nameserversChanges) {
          for (const uuid in nameserversChanges[key]) {
            if (!validateParams.UUID(uuid)) {
              return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
            }
          }
          const changes = Object.entries(nameserversChanges[key])
            .map(([nameserverUuid, item]) => ({ ...item, nameserverUuid, domainUuid: key }))
            .filter(({ name }) => name !== '');
          incomingCreates.push(...changes.filter(
            ({ create }) => (create === true)
          ));
          incomingUpdates.push(...changes.filter(
            ({ name, create }) => (name !== null && create === false)
          ));
          incomingDeletes.push(...changes.filter(({ name }) => (name === null)));
        }
        const nameserverPromises = []
        nameserverPromises.push(...incomingCreates.map(
          ({ domainUuid, name }) => {
            return httpclient.post(`/api/v2/domains/${encodeURIComponent(domainUuid)}/nameservers`, { name })
              .catch((e) => { httpRequestErrorHandling(e, commit) });
          }
        ));
        nameserverPromises.push(...incomingUpdates.map(
          ({ nameserverUuid, name }) => {
            return httpclient.patch(`/api/v2/domains/nameservers/${encodeURIComponent(nameserverUuid)}`, { name })
              .catch((e) => { httpRequestErrorHandling(e, commit) });
          }
        ));
        nameserverPromises.push(...incomingDeletes.map(
          ({ nameserverUuid }) => (
            httpclient.delete(`/api/v2/domains/nameservers/${encodeURIComponent(nameserverUuid)}`)
              .catch((e) => { httpRequestErrorHandling(e, commit) })
          )
        ));

        if (nameserverPromises.length > 0) {
          return Promise.all(nameserverPromises);
        }
        return null;
      },
      modifyAutoRenew({ commit }, { domainUuid, autoRenewChanges }) {
        const businessEntityUuid = state.businessEntity.business_entity_uuid;
        return httpclient.put(`/api/v2/domains/${domainUuid}/auto-renew`, autoRenewChanges)
          .then(() => httpclient.get(`/api/v2/domains?business_entity_uuid=${businessEntityUuid}&include_deleted=true`))
          .then(commitResponseToStore(commit, 'setBusinessEntityDomains'))
          .catch((e) => { httpRequestErrorHandling(e, commit) });
      },
      resetWebServicesState({ commit }) {
        commit('resetWebServicesState');
      },
};

export default {};
