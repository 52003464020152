export default {
  accountUuid: {
    error: {
      primary: 'Account UUID unknown.',
      type: 'alert alert-danger'
    }
  },
  updateAmmendmentReportFieldConfig: {
    success: {
      primary: 'Report Field Config updated',
      secondary: 'The Report Field Config was updated sucessfully',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to update Report Field Config',
      secondary: 'Check data and try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  addAmmendmentReportField: {
    success: {
      primary: 'Report Field Added',
      secondary: 'The Report Field was added sucessfully',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to add Report Field',
      secondary: 'Check data and try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  updateAmountServicePricing: {
    success: {
      primary: 'Service Pricing Updated',
      secondary: 'The Pricing was updated sucessfully',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to update Service Pricing',
      secondary: 'Check data and try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  createPlanOption: {
    success: {
      primary: 'Plan Option Created',
      secondary: 'The Plan Option was created sucessfully',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to create Plan Option',
      secondary: 'Check data and try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  patchPlanOption: {
    success: {
      primary: "Plan Option Updated",
      secondary: "The Plan Option was updated sucessfully",
      type: "alert alert-success",
    },
    error: {
      primary: "Unable to update Plan Option",
      secondary: "Check data and try again or contact support.",
      type: "alert alert-danger",
    },
  },
  createAccount: {
    success: {
      primary: 'Account Created!',
      secondary: 'Account created and synced to Salesforce.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to create Account!',
      secondary: 'Check data and try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  updateAccount: {
    success: {
      primary: 'Account Updated!',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to update Account!',
      secondary: 'Check data and try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  accountObfuscated: {
    error: {
      primary: 'This account has been anonymized by request of the user!',
      secondary: 'Contact dev support for account recovery.',
      type: 'alert alert-danger'
    }
  },
  accountObfuscateFailure: {
    error: {
      primary: 'Unable to obfuscate Account',
      secondary: 'Verify that the account does not have the registered agent service, and contact dev support for further help',
      type: 'alert alert-danger'
    }
  },
  searchUserUuid: {
    error: {
      primary: 'Error searching for user UUID',
      secondary: 'Please try again or contact support',
      type: 'alert alert-danger'
    }
  },
  searchUserUuidNotFound: {
    error: {
      primary: 'No user with the given UUID',
      secondary: 'Verify the UUID for the user to search is correct and try again',
      type: 'alert alert-danger'
    }
  },
  updateUser: {
    success: {
      primary: 'User Updated! You may need to refresh the page to see user details tab updates.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to update User!',
      secondary: 'Check data and try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  userMfaReset: {
    success: {
      primary: 'User MFA successfully reset',
      type: 'alert alert-success'
    }
  },
  userMfaDisabled: {
    success: {
      primary: 'User MFA successfully disabled',
      type: 'alert alert-success'
    }
  },
  userMfaEnabled: {
    success: {
      primary: 'User MFA successfully enabled',
      type: 'alert alert-success'
    }
  },
  sendPasswordResetEmail: {
    success: {
      primary: 'Password reset email sent to ',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Sending password email failed for user ',
      secondary: 'Contact support about this issue.',
      type: 'alert alert-danger'
    }
  },
  findByEmail: {
    error: {
      primary: 'No user with the given email exists.',
      secondary: 'Check data and try again or contact support.',
      type: 'alert alert-danger'
    }
  },

  createOrder: {
    success: {
      primary: 'Order Created!',
      secondary: 'Charged to Stripe and synced to Salesforce',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to create Order!',
      secondary: 'Check data and try again or contact support.',
      type: 'alert alert-danger'
    },
    cardError: {
      primary: 'Order Failed, Card Not Accepted.',
      secondary: 'Card declined or out of funds, please advise customer to contact their bank.',
      type: 'alert alert-danger'
    },
    dataError: {
      primary: 'Bad Order Data!',
      secondary: 'A price, fee or detail has not been set, make sure all relevant fields are selected or filled out',
      type: 'alert alert-danger'
    },
    domainError: {
      primary: 'Bad Order Data!',
      secondary: 'Please, check domain availability',
      type: 'alert alert-danger'
    },
    annualReportEligibility: {
      primary: 'Entity is not eligible for Annual Report',
      type: 'alert alert-danger'
    }
  },
  updateEntity: {
    success: {
      primary: 'Entity Updated!',
      secondary: 'Changes synced to Salesforce.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to update Entity!',
      secondary: 'Try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  updateResponsibleParty: {
    success: {
      primary: 'Responsible Party Updated!',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to update Responsible Party!',
      secondary: 'Try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  searchEntitiesByName: {
    error: {
      primary: 'Searching for entities by name failed!',
      secondary: 'Contact support about this issue.',
      type: 'alert alert-danger'
    }
  },

  cancelOrder: {
    success: {
      primary: 'Order Canceled!',
      secondary: 'This EXCLUDES applicable refunds and will be visible on Salesforce.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to cancel Order!',
      secondary: 'Try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  downgradeOrder: {
    success: {
      primary: 'Plan in order downgraded.',
      secondary: 'Order tasks for the old plan have been cancelled, and partner requests were transferred to the new order',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to downgrade plan in order.',
      secondary: 'Try again or contact support.',
      type: 'alert alert-danger'
    },
    refundNotApplied: {
      primary: 'Plan in order downgraded, but refund could not be applied.',
      secondary: 'Most recent invoice for original plan subscription could not be refunded; apply refund manually',
      type: 'alert alert-warning'
    }
  },
  cancelOrderTask: {
    success: {
      primary: 'Order Task Canceled!',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to cancel Order Task!',
      secondary: 'Try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  updateOrderTask: {
    success: {
      primary: 'Order Task Updated!',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to update Order Task!',
      secondary: 'Try again or contact support.',
      type: 'alert alert-danger'
    }
  },
  issueRefund: (message = null) => {
    return {
      success: {
        primary: 'Refund Request Success! You may need to refresh the page to see the results.',
        secondary: '',
        type: 'alert alert-success'
      },
      error: {
        primary: 'We were unable to process the refund.',
        secondary: message,
        type: 'alert alert-danger'
      }
    }
  },
  resendEmail: {
    success: {
      primary: 'Receipt Email Sent!',
      secondary: 'Check Mandrill logs for more details.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Receipt Email Failed.',
      type: 'alert alert-danger'
    }
  },
  resetPartnerRequest: {
    success: {
      primary: 'Partner Request reset!',
      secondary: 'If this was in error please contact support.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Partner Request Failed.',
      secondary: 'Please contact support.',
      type: 'alert alert-danger'
    }
  },
  resendWelcome: {
    success: {
      primary: 'Welcome Email Sent!',
      secondary: 'Check Mandrill logs for more details.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Welcome Email Failed.',
      secondary: 'Please contact support.',
      type: 'alert alert-danger'
    }
  },
  incompleteOrderReminder: {
    success: {
      primary: 'Order Reminder Email Sent!',
      secondary: 'Check Mandrill logs for more details.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Order Reminder Email failed',
      type: 'alert alert-danger'
    }
  },
  loginStatus: {
    success: {
      primary: 'Login Success!',
      secondary: 'Welcome to ZenGarden',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Login Failed.',
      secondary: 'Invalid username or password',
      type: 'alert alert-danger'
    }
  },
  fileUploadStatus: {
    error: {
      primary: 'File Upload failed. Contact support or try again.',
      type: 'alert alert-danger'
    }
  },
  fileDelete: {
    error: {
      primary: 'File delete failed. Contact support or try again.',
      type: 'alert alert-danger'
    }
  },
  fileUpdate: {
    error: {
      primary: 'File update failed. Contact support or try again.',
      type: 'alert alert-danger'
    }
  },
  SOOStatus: {
    success: {
      primary: 'Successfully requested SOO document',
      type: 'alert alert-success'
    },
    responseError: {
      primary: 'Error: ',
      type: 'alert alert-danger'
    },
    error: {
      primary: 'SOO generator failed. Contact Support.',
      type: 'alert alert-danger'
    }
  },
  SS4Status: {
    success: {
      primary: 'Successfully requested SS4document',
      type: 'alert alert-success'
    },
    dataError: {
      primary: 'Form SS4 generator failed from missing or invalid data. Please update the entity and try again.',
      type: 'alert alert-warning'
    },
    error: {
      primary: 'SS4 generator failed.',
      type: 'alert alert-danger'
    }
  },
  DocumentStatus: {
    success: {
      primary: 'Successfully requested <> document',
      type: 'alert alert-success'
    },
    responseError: {
      primary: '',
      type: 'alert alert-danger'
    },
    error: {
      primary: '<> generator failed. Contact Support.',
      type: 'alert alert-danger'
    }
  },
  F2553Status: {
    success: {
      primary: 'Successfully requested Form 2553 document',
      type: 'alert alert-success'
    },
    dataError: {
      primary: 'Form 2553 generator failed from missing or invalid data. Please update the entity and try again.',
      type: 'alert alert-warning'
    },
    error: {
      primary: 'Form 2553 generator failed.',
      type: 'alert alert-danger'
    }
  },
  automatedSosStatus: {
    success: {
      primary: 'Successfully started automated filing with the state',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Failed to start automated filing with the state',
      type: 'alert alert-danger'
    }
  },
  syncAutomatedFilingStatus: {
    success: {
      primary: 'Successfully synchronized automated filing',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Failed to sync automated filing',
      type: 'alert alert-danger'
    }
  },
  sendPartnerRequest: {
    success: {
      primary: 'Partner request sent',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Partner request sent but failed. Check customer data.',
      type: 'alert alert-danger'
    },
    responseError: {
      primary: '',
      type: 'alert alert-danger'
    }
  },
  linkDomain: {
    success: {
      primary: 'Primary domain linked to static website, IT MAY TAKE HOURS FOR DNS CHANGES TO TAKE EFFECT. Please check Web Services tab for details. ',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to link primary domain to static website. Ensure that the entity has at least one domain and has purchased a static website',
      type: 'alert alert-danger'
    }
  },
  syncDNS: {
    success: {
      primary: 'Synced DNS records successfully',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to sync DNS records.',
      type: 'alert alert-danger'
    }
  },
  syncNameservers: {
    success: {
      primary: 'Synced nameservers successfully ',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to sync nameservers.',
      type: 'alert alert-danger'
    }
  },
  cpaAppointment: {
    success: {
      primary: 'Appointment Scheduled! It may take a few minutes for the event to appear on the calendar.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Unable to schedule CPA appointment. Check customer credits, other conflicting appointments, or contact support.',
      type: 'alert alert-danger'
    }
  },
  processCompletedOrder: {
    success: {
      primary: 'Order completed!',
      type: 'alert alert-success'
    },
    error: {
      primary: 'An error occured processing the completed order. Check API logs for more detail.',
      type: 'alert alert-danger'
    }
  },
  sendOrderToSalesforce: {
    success: {
      primary: 'Order sent to salesforce successfully!',
      type: 'alert alert-success'
    },
    error: {
      primary: 'An error occured sending the order to salesforce. Check API logs for more detail.',
      type: 'alert alert-danger'
    }
  },
  generatePartnerRequests: {
    success: {
      primary: 'Partner requests generated successfully!',
      type: 'alert alert-success'
    },
    error: {
      primary: 'An error occurred generating partner requests. Check API logs for more detail.',
      type: 'alert alert-danger'
    }
  },
  exchangePartnerRequestDomain: {
    success: {
      primary: 'Exchanged partners request domain',
      type: 'alert alert-success'
    },
    error: {
      primary: 'An error occured processing exchange. Check API logs for more detail.',
      type: 'alert alert-danger'
    }
  },
  editRefundInvoiceReason: {
    success: {
      primary: 'Refund invoice reason completed!',
      type: 'alert alert-success'
    },
    error: {
      primary: 'An error occured processing the completed order. Check API logs for more detail.',
      type: 'alert alert-danger'
    }
  },
  submitOrderBillingItemChange: {
    success: {
      primary: 'Order Billing Item status updated!',
      type: 'alert alert-success'
    },
    error: {
      primary: 'An error occured processing the Order Billing Item status change. Check API logs for more detail.',
      type: 'alert alert-danger'
    }
  },
  fetchBusinessEntity: {
    success: {
      primary: 'Business Entity found',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Business Entity does not exist',
      type: 'alert alert-danger'
    }
  },
  updateDomain: {
    success: {
      primary: 'Domain data was updated successfully.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Domain data could not be updated.',
      type: 'alert alert-danger'
    }
  },
  repairDomain: {
    success: {
      primary: 'Domain was repaired successfully.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The domain could not be repaired.',
      type: 'alert alert-danger'
    }
  },
  createProduct: {
    success: {
      primary: 'Service Product created successfully',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Service Product creation failed.',
      secondary: 'An unexpected error ocurred',
      type: 'alert alert-danger'
    }
  },
  activateAffiliate: {
    success: {
      primary: 'Affiliate activated successfully',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Affiliate activation failed.',
      secondary: 'An unexpected error ocurred',
      type: 'alert alert-danger'
    }
  },
  deactivateAffiliate: {
    success: {
      primary: 'Affiliate deactivated successfully',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Affiliate activation failed.',
      secondary: 'An unexpected error ocurred',
      type: 'alert alert-danger'
    }
  },
  createCoupon: {
    success: {
      primary: 'Coupon created',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Coupon creation failed.',
      secondary: 'Coupon Id must be a single word, with no spaces, and cannot be same as existing. Amount must be greater than zero',
      type: 'alert alert-danger'
    }
  },
  createAffiliate: {
    success: {
      primary: 'Affiliate created',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Failed to create  affiliate ',
      type: 'alert alert-danger'
    }
  },
  createAffiliatePlanOption: {
    success: {
      primary: 'Affiliate-plan option link created',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Failed to create link between affiliate and plan option',
      type: 'alert alert-danger'
    }
  },
  deactivateAffiliatePlanOption: {
    success: {
      primary: 'Affiliate Plan Option and Affiliate Pricing deactivated',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Failed to deactivate Affiliate Plan Option and Affiliate Pricing',
      type: 'alert alert-danger'
    }
  },
  createServicePricing: {
    success: {
      primary: 'Service pricing created',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Service Pricing creation failed.',
      type: 'alert alert-danger'
    }
  },
  createAffiliatePricing: {
    success: {
      primary: 'Pricing added to affiliate',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Affiliate price add failed.',
      secondary: '',
      type: 'alert alert-danger'
    }
  },
  createServiceProductPlans: {
    success: {
      primary: 'Created service product plan links',
      type: 'alert alert-success'
    },
    error: {
      primary: 'One or more service product plan links failed to create',
      type: 'alert alert-danger'
    },
    cleanupError: {
      primary: 'One or more service product plan links failed to create, and created ones could not be deleted!',
      secondary: 'Check ZenAPI to see why deleting SPP objects failed and manually resolve the issue.',
      type: 'alert alert-danger'
    }
  },
  automatedFilingStatus: {
    error: {
      primary: 'Could not retrieve automated filing status data',
      type: 'alert alert-danger'
    }
  },
  bofFilingSubmission: {
    success: {
      primary: 'Filing successfully submitted.',
      type: 'alert alert-success'
    },
    invalidRequestError: {
      primary: 'invalidRequest',
      type: 'alert alert-danger'
    },
    validationRequestError: {
      primary: 'validationError',
      type: 'alert alert-danger'
    },
    unexpectedExceptionError: {
      primary: 'unexpectedExceptionError',
      secondary: 'A technical error has occurred.',
      type: 'alert alert-danger'
    }
  },
  validationUUID: {
    error: {
      primary: 'The UUID parameter is invalid, please refresh the page to try again.',
      type: 'alert alert-danger'
    }
  },
  validationID: {
    error: {
      primary: 'The ID parameter is invalid',
      type: 'alert alert-danger'
    }
  },
  validationParameters: {
    error: {
      primary: 'The parameters dont work with the conditions.',
      type: 'alert alert-danger'
    }
  },
  fetchMessage: {
    error: {
      primary: 'The request can not be processed.',
      type: 'alert alert-danger'
    },
    errorResponded: {
      primary: 'The request can not be processed.',
      type: 'alert alert-danger'
    },
    errorNoResponse: {
      primary: 'The request can not be processed.',
      type: 'alert alert-danger'
    }
  },
  automatedFilingList: {
    error: {
      primary: 'Could not retrieve automated filing list',
    }
  },
  automatedStartAutomationTypeProcess: {
    success: {
      primary: 'Successfully started robotic process.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Failed to start robotic process',
      type: 'alert alert-danger'
    }
  },
  itemsQueue: {
    error: {
      primary: 'Could not retrieve items queue',
    }
  },
  ccorpAddFieldMessage: {
    success: {
      primary: 'The field was added.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The field can not be added.',
      type: 'alert alert-danger'
    }
  },
  ccorpUpdateFieldMessage: {
    success: {
      primary: 'The field was updated.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The field can not be updated.',
      type: 'alert alert-danger'
    }
  },
  ccorpDeleteFieldMessage: {
    success: {
      primary: 'The field was deleted.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The field can not be deleted.',
      type: 'alert alert-danger'
    }
  },
  ccorpAddFeeMessage: {
    success: {
      primary: 'The fee was added.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The fee can not be added.',
      type: 'alert alert-danger'
    }
  },
  ccorpUpdateFeeMessage: {
    success: {
      primary: 'The fee was updated.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The fee can not be updated.',
      type: 'alert alert-danger'
    }
  },
  ccorpDeleteFeeMessage: {
    success: {
      primary: 'The fee was deleted.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The fee can not be deleted.',
      type: 'alert alert-danger'
    }
  },
  ccorpAddTermMessage: {
    success: {
      primary: 'The term was added.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The term can not be added.',
      type: 'alert alert-danger'
    }
  },
  ccorpUpdateTermMessage: {
    success: {
      primary: 'The term was updated.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The term can not be updated.',
      type: 'alert alert-danger'
    }
  },
  ccorpDeleteTermMessage: {
    success: {
      primary: 'The term was deleted.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The term can not be deleted.',
      type: 'alert alert-danger'
    }
  },
  ccorpCreateCalculatorMessage: {
    success: {
      primary: 'Congratulations! The CCorp Stock Calculator has been created or updated.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'The CCorp Stock Calculator couldnt be created or updated.',
      type: 'alert alert-danger'
    }
  },
  ccorpComputeCalculatorMessage: {
    error: {
      primary: 'The computation failed.',
      type: 'alert alert-danger'
    }
  },
  generateAutomationPayloadMessage: {
    success: {
      primary: 'Payload generated.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Error generating payload.',
      type: 'alert alert-danger'
    },
  },
  downloadPayloadInteractionMessage: {
    success: {
      primary: 'Payload file generated.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Error downloading Payload file.',
      type: 'alert alert-danger'
    },
  },
  copyPayloadInteractionMessage: {
    success: {
      primary: 'Text copied to clipboard.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Error copying to clipboard.',
      type: 'alert alert-danger'
    },
  },
  sendCallbackMessage: {
    success: {
      primary: 'Callback updated automated filing',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Error updating automated filing',
      type: 'alert alert-danger'
    },
  },
  orderTaskMessage: {
    success: {
      primary: 'Order Task found',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Order Task does not exist',
      type: 'alert alert-danger'
    }
  },
  registeredAgentUpdateSuccessByJurisdiction: (jurisdiction) => {
    return {
      primary: 'Entity Updated!',
      secondary: jurisdiction + '\'s Registered Agent Updated!',
      type: 'alert alert-success'
    }
  },
  entityStatusAtSOS: {
    success: {
      primary: 'Successfully started checking entity status at Secretary of State',
      type: 'alert alert-success'
    }
  },
  fetchBusinessEntityMsg: {
    error: {
      primary: 'There was an issue fetching the business entity data.',
      secondary: 'You will be redirected to the previous page.',
      type: 'alert alert-danger'
    }
  },
  fetchOrderTracking: {
    success: {
      primary: 'Order tracking retrieved',
      type: 'alert alert-success'
    },
    error: {
      primary: 'An error occurred getting order tracking. Check API logs for more detail.',
      type: 'alert alert-danger'
    }
  },
  createAddressContactJourney: {
    success: {
      primary: `Request Sent`,
      secondary: `Customer has been notified`,
      type: 'alert alert-success'
    },
    error: {
      primary: 'Request Failed',
      secondary: 'Please try again later',
      type: 'alert alert-danger'
    }
  },
  createCouponJourney: {
    success: {
      primary: `Request Sent`,
      secondary: `Customer has been notified`,
      type: 'alert alert-success'
    },
    error: {
      primary: 'Request Failed',
      secondary: 'Please try again later',
      type: 'alert alert-danger'
    }
  },
};
