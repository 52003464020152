import { initialState } from './state.js'

export const mutations = {
  resetDeviceInfoState: (state) => {
    Object.assign(state, initialState());
  },
  setDeviceInfo: (state, deviceInfo) => {
    state.deviceInfo = deviceInfo;
  }
};

export default {};