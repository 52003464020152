<template>
  <svg class="spinner" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" x="0" y="0">
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5" stroke="currentColor"></circle>
  </svg>
</template>

<script>
export default {
  name: 'IconSpinner'
};
</script>

<style lang="scss" scoped>
/* - The spinner is dynamic:
  -- to adjust the size or color of the spinner,
  -- set the font-size or color of the parent element. 
*/
.spinner {
  width: 1em;
  height: 1em;
  animation: rotate 2s linear infinite;
  circle {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  } 
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>