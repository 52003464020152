import httpclient from '@/store/httpclient';
import { httpRequestErrorHandling } from '@/store/modules/utils';

export const actions = {
  resetDeviceInfoState({ commit }) {
    commit('resetDeviceInfoState');
  },
  async fetchDeviceInfo({ commit }, { accountUuid }) {
    const userUuidInfo = await httpclient.get(`/api/v2/accounts/${accountUuid}/users`)
      .catch((e) => { httpRequestErrorHandling(e, commit) });
    const userUuid = (userUuidInfo.data[0].user_uuid);
    return httpclient.get(`customers/v1/devices/${userUuid}`).then((response) => {
      const { results } = response.data;
      commit('setDeviceInfo', results);
    }).catch((e) => { httpRequestErrorHandling(e, commit) });
  }
};

export default {};