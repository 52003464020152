import httpclient from '../../httpclient';
import messages from '../../../assets/messages';
import { httpRequestErrorHandling, validateParams } from '../utils';
import { FILING_SUBMISSION_STATUSES } from '../../../assets/constants/bofFilings';

export default {
    submitBofData({ commit }, { orderTaskUuid, businessEntityUuid, businessEntityJurisdictionUuid, formationDate, stateOfFormation }) {
        if(!validateParams.UUID(orderTaskUuid) || !validateParams.UUID(businessEntityUuid) || !validateParams.UUID(businessEntityJurisdictionUuid)) {
          httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
        } else {
          const body = {
            orderTaskUuid,
            businessEntityUuid,
            businessEntityJurisdictionUuid,
            formationDate,
            stateOfFormation
          }
          return httpclient.post(`/compliance/api/v1/filing/bof/submitFiling`, body)
            .then(() => {
              commit('setAlertMessage', messages.bofFilingSubmission.success, { root: true });
              commit('setBofLatestFilingStatus', 'pending');
              commit('setBeginButtonStatus', 'processing');
            })
            .catch((e) => {
              const errorMessages = e.response?.data?.errorMessages || [];
              let alertMessage;

              if (e.response.status === 400) {
                  alertMessage = {
                      ...messages.bofFilingSubmission.invalidRequestError,
                      secondary: errorMessages
                  };
              } else if (e.response.status === 422) {
                  alertMessage = {
                      ...messages.bofFilingSubmission.validationRequestError,
                      secondary: errorMessages
                  };
              } else {
                  alertMessage = {
                      ...messages.bofFilingSubmission.unexpectedExceptionError
                  };
              }

              commit('setAlertMessage', alertMessage, { root: true });
              commit('setBofLatestFilingStatus', 'failed');
            });
        }
    },
    fetchFilingStatus({ commit }, { orderTaskUuid }) {
      if(!validateParams.UUID(orderTaskUuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
      } else {
        return httpclient.get(`/compliance/api/v1/filing-update/filing-status/${orderTaskUuid}`)
          .then((response) => {
            commit('setBofLatestFilingStatus', response.data.newStatus);
            if(response.data.newStatus === FILING_SUBMISSION_STATUSES.COMPLETED) {
              commit('setFilingNotes', '');
            } else {
              commit('setFilingNotes', response.data.data.errors);
            }
            return response?.data;
          })
          .catch((e) => {
            if (e?.response?.status === 404) {
              commit('setBofLatestFilingStatus', 'not_found');
            } else {
              httpRequestErrorHandling(e, commit)
            }
          });
      }
    },
}