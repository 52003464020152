import Vue from 'vue';
import automation from './validations/automationValidation';

export default {
  getPayloadByOrderTaskType: (state) => (orderTaskType) => {
    return state.payload[orderTaskType];
  },
  getAutomationMessagesByOrderTaskType: (state) => (orderTaskType) => {
    return state.automationMessages[orderTaskType];
  },
  getAutomationMessages: (state) => (orderTask, contacts) => {
    const orderTaskType = orderTask?.order_task_type;
    const payload = state.payload[orderTaskType]
    let messages = [];
    switch(orderTaskType) {
      case 'llc_annual_report':
        messages = automation.validationLLCAnnualReport(orderTask, payload, contacts);
        break;
      case 'corp_annual_report':
        messages = automation.validationCorpAnnualReport(orderTask, payload, contacts);
        break;
      case 'llc_formation':
        messages = automation.validationFormation(orderTask, payload, contacts);
        break;
      case 'standard_good_standing':
          messages = automation.validationGoodStanding(orderTask);
          break;
      default:
        console.warn(`Order Task ${orderTaskType} not supported to validate automation.`);
    }
    Vue.set(state.automationMessages, orderTaskType, messages);
    return messages;
  },
};
