import httpclient from '../../httpclient';
import messages from '../../../assets/messages';
import { httpRequestErrorHandling, validateParams } from '../utils';
// import { state } from './state';

export const actions = {
  fetchBusinessEntityGeneratedDocuments({ commit }, { uuid }) {
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/documents/api/v2/generated-documents?business_entity_uuid=${uuid}`)
        .then((response) => {
          commit('setGeneratedDocuments', response.data);
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
};

export default {};
