<template>
  <img id="circle-logo-teal" :style="{ 'font-size': logoSize + 'px' }" :alt="alt" src="@/assets/img/ZenCircleLogoTeal.svg">
</template>

<script>
export default {
  name: 'CircleLogoTeal',

  props: {
    alt: {
      type: String,
      default: ''
    },
    logoSize: {
      default: 30
    }
  },
};
</script>

<style lang="scss" scoped>
img {
  font-size: 1em;
  height: 1em;
  width: 1em;
}
</style>
