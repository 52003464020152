import router from "@/router";
import {
  validationLLCFormationIL,
  validationLLCFormationVA,
} from "../../../components/utils/automation-validation";

export const getContacts = (contacts, contactType) => {
  // Return a list of contacts by type (for managers, members).
  return contacts.reduce((filtered, elem) => {
    if (elem.contact_type === contactType) {
      filtered.push(elem.contact);
    }
    return filtered;
  }, []);
};
export const getContact = (contacts, contactType) => {
  // Return a contact object by type (for authorized signatory, registered agent).
  const contact = contacts.find((elem) => elem.contact_type === contactType);
  return contact ? contact.contact : {};
};
const getAddress = (addresses, addressType) => {
  // Return an address object by type.
  const address = addresses.find((elem) => elem.address_type === addressType);
  return address ? address.address : {};
};

export const getters = {
  getBusinessEntityUuid: (state) => {
    if (state?.businessEntity?.business_entity_uuid) {
      return state.businessEntity.business_entity_uuid;
    }
    const urlParam = router.currentRoute.params.businessEntityUuid;
    if (urlParam) {
      return urlParam;
    }
    return;
  },
  getBusinessEntityWebsiteUrl: (state) => {
      return state.businessEntity.business_entity_website_url;
  },
  getBusinessEntityAccountUuid: (state) => {
      return state.businessEntity.account_uuid;
  },
  businessEntityAuthorizedSignatory(state) {
    const { contacts } = state;
    const authorizedSignatory = contacts.find((contact) => contact.contact_type === 'authorized_signatory');
    return authorizedSignatory ? authorizedSignatory.contact : null;
  },
  businessEntityManagers(state) {
    return getContacts(state.contacts, 'manager');
  },
  errorMessagesAutomatedFormation(state) {
    const { addresses, businessEntity, businessEntityTasks, contacts } = state;
    let errorMessages = [];
    const llcFormationTask = businessEntityTasks.find((task) => task.order_task_type === 'llc_formation');
    if (llcFormationTask?.order_task_jurisdiction === "IL"){
      errorMessages = validationLLCFormationIL(
        getContact(contacts, 'authorized_signatory'),
        getContact(contacts, 'registered_agent'),
        getContacts(contacts, 'manager'),
        getContacts(contacts, 'member'),
        getAddress(addresses, 'physical'),
        businessEntity,
      );
    }
    else if (llcFormationTask?.order_task_jurisdiction === "VA"){
      errorMessages = validationLLCFormationVA(
        getContact(contacts, 'registered_agent'),
        getContacts(contacts, 'manager'),
        getContacts(contacts, 'member'),
      );
    }
    return errorMessages;
  },
  getContactsByType: (state) => (contactType) => {
    const { contacts } = state;
    return getContacts(contacts, contactType);
  },
  getContacts: (state) => {
    const { contacts } = state;
    return contacts;
  },
  getAddresses: (state) => {
    const { addresses } = state;
    return addresses;
  },
  getContactTypes: (state) => {
    const { contactTypes } = state;
    return contactTypes;
  },
  getAddressOfType: (state) => (addressType) => {
    const { addresses } = state;
    const address = addresses.find((a) => a.address_type === addressType);
    return address ? address.address : {};
  },
  getBusinessEntityType(state) {
    const { businessEntity } = state;
    return businessEntity.business_entity_type;
  },
  getJurisdictions(state) {
    return state.jurisdictions;
  },
  getHasVBA({ businessEntity }) {
    return businessEntity.has_vba;
  },
  getManagerType({ businessEntity }) {
    return businessEntity.management_structure_in_english;
  },
  getBusinessIndustry({ businessEntity }) {
    return businessEntity.business_industry;
  },
  compareContactData: (state) => (contactData, compareObjProp, rehydrate=false) => {
    const { businessEntity } = state;
    const beContact = businessEntity.business_entity_data[compareObjProp];
    const editedContactData = {
      isEdited: {}
    };

    for (const property in beContact) {
      editedContactData[property] = beContact[property];
      if (contactData && beContact && beContact[property] !== contactData[property]) {
        editedContactData.isEdited[property] = true;
      }
    }
    return rehydrate ? { ...contactData, ...editedContactData} : editedContactData
  },
  compareListsOfContactData: (state) => (contactData, compareObjProp) => {
    const { businessEntity } = state;
    const beContactList = businessEntity.business_entity_data[compareObjProp];
    // TODO / NOTES
    // There's a lot happening here and none of it is great
    // *ideally* this would be as simple as "find entities that should match and do compareContactData-equivalent"
    // but it turns out "find entities that match" is... prohibitive
    // There might be arbitrary changes made to the business entity (removal, addition, changes on contact itself)
    // so we can make no assumptions based on size of lists beyond the obvious (if sizes don't match, something change)
    //  but the obvious doesn't gain us anything because we care about *what* changed, not just that *changes happened*
    // But we can't make any assumption if the sizes are the same
    //  a contact could have been removed and a new one added (same size) or a single contact might have been changed
    //  itself (same size)
    // Ok, so we just iterate over them and pull out matching contacts based on something immutable
    //  turns out there's nothing immutable
    // While the contacts on the business entity (beContactList) have contact_uuids
    //  contacts in the order_task_data do *not* have contact_uuids. I suspect fixing this will be prohibitive
    //    if I remember correctly, order_task_data is populated in the database *prior to* creation of contacts
    //    in the database, which is *prior to* them getting assigned a uuid.
    //  I am unsure how difficult refactoring zapi to assign uuids before populating order_task_data would be
    //    but even if it were easy, we *still* wouldn't be able to make assumptions or match on anything prior to
    //    the introduction of the fix
    // Ok, can we match on something that isn't the contact_uuid?
    //  Not comfortably. Every attribute of the contact can change, including names, so there's nothing that can
    //  be matched on with any degree of certainty that it won't be changed
    // ...Can we just assume order remains the same?
    //  I haven't checked, but given all the possible ways things can change and the fact that nothing is ordered
    //    anywhere in the system, I didn't even try. I suspect if assuming order between the two lists is preserved
    //    works, it's only going to be by sheer blind luck
    // So we just return the business entity list and leave this function here in case things change
    return beContactList;
  },
  compareEntityData: (state) => (entityData, compareObjProp) => {
    const { businessEntity } = state;
    const editedBeData = {};
    editedBeData.originalValue = entityData;
    editedBeData.value = businessEntity[compareObjProp];
    editedBeData.isEdited = businessEntity[compareObjProp] !== entityData ? true : false;
    editedBeData.getCanonicalValue = () => editedBeData.isEdited ? editedBeData.value : editedBeData.originalValue;
    return editedBeData;
  },
};

export default {};
