export default {
  setAccounts: (state, accounts) => {
    state.accounts = accounts;
  },
  setAccount: (state, account) => {
    state.account = account;
  },
  setDeviceInfo: (state, deviceInfo) => {
    state.account.deviceInfo = deviceInfo;
  },
  setFirstName: (state, firstName) => {
    state.account.first_name = firstName;
  },
  setMiddleName: (state, middleName) => {
    state.account.middle_name = middleName;
  },
  setLastName: (state, lastName) => {
    state.account.last_name = lastName;
  },
  setSuffix: (state, suffix) => {
    state.account.suffix = suffix;
  },
  setAccountEmail: (state, email) => {
    state.account.account_email = email;
  },
  setEmail: (state, email) => {
    state.account.email = email;
  },
  setPassword: (state, password) => {
    state.account.password = password;
  },
  setAccountPhone: (state, phone) => {
    state.account.account_phone = phone;
  },
  setAccountBusinessName: (state, name) => {
    state.account.self_declared_business_name = name;
  },
  setAccountBusinessType: (state, type) => {
    state.account.self_declared_business_type = type;
  },
  setAccountBusinessTypeText: (state, text) => {
    state.account.self_declared_business_type_other_text = text;
  },
  setAccountType: (state, type) => {
    state.account.account_type = type;
  },
  setPhone: (state, phone) => {
    state.account.phone = phone;
  },
  setSignupClient: (state, client) => {
    state.account.signup_client = client;
  },
  setProfile: (state, profile) => {
    state.profile = profile;
  },
  setUsers: (state, users) => {
    state.users = users;
  },
  setCharges: (state, charges) => {
    state.charges = charges;
  },
  setAuth0User: (state, auth0User) => {
    state.auth0User = auth0User;
  },
  setAuth0UserLogs: (state, auth0UserLogs) => {
    state.auth0UserLogs = auth0UserLogs;
  }
};
