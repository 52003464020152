export default {
  setJiraUserId: (state, jiraUserId) => {
    state.jiraUserId = jiraUserId;
  },
  setMarkDownText: (state, markDownText) => {
    state.markDownText = markDownText;
  },
  addRequestError: (state, requestError) => {
    state.requestErrors.push(requestError);
  }
};
