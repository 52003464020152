import httpclient from "../../httpclient";

const actions = {
  async fetchPersonalCreditMonitoring({ commit }, { uuid }) {
    return httpclient
      .get(
        `/financial-accounts/v1/credit-monitoring/subscribers?account_uuid=${uuid}`
      )
      .then((response) => {
        commit("setPersonalCreditMonitoringData", response.data);
      });
  },
};

export default actions;
