export default {
  planProductsV2: (state) => state.planProductsV2,
  planOptionsV2ByPlan: (state) => {
    const planOptionsByPlan = {};
    let services = state.planOptionsV2.map((planOption) => planOption.related_plan_pricing.service);
    services = [...new Set(services)];
    for (const service of services) {
      planOptionsByPlan[service] = state.planOptionsV2.filter((planOption) => planOption.related_plan_pricing.service === service);
    }
    return planOptionsByPlan;
  },
  pricingV2ByProduct: (state) => {
    const pricingByProduct = {};
    let services = state.servicePricingV2.map((pricing) => pricing.service);
    services = [...new Set(services)];
    for (const service of services) {
      const pricing = state.servicePricingV2.filter((pricing) => pricing.service === service);
      pricingByProduct[service] = {
        pricing,
        isBundle: pricing[0].service_category === 'plan' && service.indexOf('plan') === -1,
        isPlan: pricing[0].service_category === 'plan' && service.indexOf('plan') !== -1
      };
    }
    return pricingByProduct;
  },
  serviceNameIDs: (state) => state.products.map((product) => product.service),
  pricingByGroupAndService: (state) => (priceGroup, service) => {
    if (priceGroup in state.servicePricingByGroupAndService && service in state.servicePricingByGroupAndService[priceGroup]){
      // The servicePricingByGroupAndService consolidates multiple interval records for the same service
      // into an object {INTERVAL: PRICING_FOR_INTERVAL}
      // If an object only has one interval, pull out the specific pricing and return it
      // else return all the intervals
      let cached = state.servicePricingByGroupAndService[priceGroup][service]
      if (Object.keys(cached).length === 1) {
        return cached[Object.keys(cached)[0]]
      }
      return cached
    }
    return null
  },
  getPlanAffiliates: (state) => (planName) => {
    const { affiliatePlanOptions } = state;
    var planAffiliates=[]
    affiliatePlanOptions.filter(
          function (plan_item){
           if (plan_item.related_plan_option.related_plan_pricing.service == planName) {
                 planAffiliates.push(plan_item)
           }
          }
        )
    planAffiliates.sort((a,b) => (a.related_affiliate.name > b.related_affiliate.name) ? 1 : ((b.related_affiliate.name> a.related_affiliate.name) ? -1 : 0))
    return planAffiliates;
  },
  getProducts: (state) => {
    return state.products;
  },
  getCoupons: (state) => {
    return state.coupons;
  },
  getReportFieldConfig: (state) => {
    return state.reportFieldConfig;
  }
};
