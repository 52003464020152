export default {
  setAgencyLoginAccounts: (state, agencyLoginAccounts) => {
    state.agencyLoginAccounts = agencyLoginAccounts.results;
  },
  setSharedAgencyLoginAccounts: (state, sharedAgencyLoginAccounts) => {
    state.sharedAgencyLoginAccounts = sharedAgencyLoginAccounts;
  },
  setOrderTaskFulfillment: (state, orderTaskFulfillment) => {
    state.orderTaskFulfillment = orderTaskFulfillment;
  },
  setBusinessEntityJurisdictionFulfillment: (state, businessEntityJurisdictionFulfillment) => {
    // Make sure that we're not accidentally sending through a "we successfully found nothing" message as an actual record
    if (typeof(businessEntityJurisdictionFulfillment) !== 'string') {
      state.businessEntityJurisdictionFulfillment[businessEntityJurisdictionFulfillment.business_entity_jurisdiction_uuid] = businessEntityJurisdictionFulfillment;
    }
  },
  setCorpStockInformation: (state, cCorpInformationStock) => {
    state.ccorpStockInformationFulfillment = cCorpInformationStock;
  },
  setFulfillmentData: (state, fulfillmentData) => {
    state.fulfillmentData = fulfillmentData;
  }
}
