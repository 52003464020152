
import httpclient from '@/store/httpclient';
import messages from '@/assets/messages';
import { httpRequestErrorHandling, validateParams } from '@/store/modules/utils';

export const actions = {
  async fetchDbas({ commit }, { uuid }) {
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/dba-entities/business-entity/${uuid}`)
        .then((response) => {
          commit('setDbas', response.data);
            return response;
        })
        .catch((e) => { 
          httpRequestErrorHandling(e, commit);
          return e.response;
        });
    }
  },
  resetDbaState({ commit }) {
    commit('resetDbaState');
  },
  updateDbas({ commit }, { uuid, name }) {
    if (!(validateParams.UUID(uuid) && name)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.put(`/api/dba-entities/${uuid}`, { name })
        .then((response) => {
          commit('setDbas', response.data);
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
};

export default {};