const titleCaseMinorWords = [ // from the ama style guide
  // articles
  'a',
  'an',
  'the',
  // coordinating conjunctions
  'and',
  'but',
  'for',
  'nor',
  'or',
  'so',
  'yet'
]

export const addSuggestedBreakToEmail = (email) => {
  if (typeof email !== 'string') return email
  return email ? email.split('@').join(`@<wbr>`) : ''
}

export const capitalizeFirstLetter = (str) => {
  return str[0].toUpperCase() + str.slice(1);
}

export const makeSentenceCase = (str) => {
  if (!str) return str
  if (typeof str !== 'string') return str
  const uppercaseRegex = /(llc|sos)/gi
  const toSentence = str.split('_').join(' ')
  const updatedSentence = toSentence.replace(uppercaseRegex, (x) => x.toUpperCase())

  return updatedSentence.charAt(0).toUpperCase() + updatedSentence.slice(1)
}

export const makeTitleCase = (str, delimiter = ' ') => {
  const strArr = str.split(delimiter)
  return strArr.reduce( (acc, curr, index) => {
    let val = curr
    if (typeof curr === 'string') {
      if (index === 0 || index === strArr.length - 1) {
        val = capitalizeFirstLetter(curr)
      } else if (!titleCaseMinorWords.includes(curr)) {
        val = capitalizeFirstLetter(curr)
      }
    }
    acc.push(val)
    return acc
  }, []).join(' ')
}