import actions from "./actions";
import mutations from './mutations';
import state from './state';

const bofAutomatedFilings = {
  namespaced: true,
  actions,
  mutations,
  state
};

export default bofAutomatedFilings;
