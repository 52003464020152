import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import state from "./state";
import accountDeviceInfo from "@/v2/account-info/store";
import accounts from "./modules/accounts";
import automation from "@/v2/automation/store";
import dba from "@/v2/dba/store";
import domains from "./modules/domains";
import orders from "./modules/orders";
import bofAutomatedFilings from "./modules/bof-automated-filings";
import businessEntities from "./modules/business-entities";
import orderCreate from "@/v2/order-creation/store";
import products from "./modules/products";
import versions from "./modules/versions";
import dataAlerts from "./modules/data-alerts";
import partnerRequest from "./modules/partner-request";
import integrations from "./modules/integrations";
import fulfillment from "./modules/fulfillment";
import users from "./modules/users";
import documents from "./modules/documents";
import system from "./modules/system";
import creditMonitoring from "./modules/credit-monitoring";
import webServices from "@/v2/web-services/store";
import zbMoney from "@/v2/zb-money/store";
import zebra from "@/v2/zebra/store";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {
    accountDeviceInfo,
    accounts,
    automation,
    bofAutomatedFilings,
    businessEntities,
    dataAlerts,
    dba,
    domains,
    documents,
    fulfillment,
    integrations,
    orders,
    orderCreate,
    products,
    partnerRequest,
    users,
    versions,
    system,
    creditMonitoring,
    webServices,
    zbMoney,
    zebra,
  },
});
