export default {
  affiliates: [],
  deactivated_affiliates: [],
  serviceFees: [],
  servicePricing: [],
  servicePricingV2: [],
  servicePricingByGroupAndService: {},
  products: [],
  planProductsV2: [],
  planOptionsV2: [],
  reportFieldConfig: {},
  coupons: [],
  coupon: {},
  planOptions: [],
  availablePlans: [],
  jurisdictionStates: [],
  ccorpTerms: [],
  ccorpFields: [],
};
