import httpclient from '@/store/httpclient';
import messages from '@/assets/messages';
import { capitalizeFirstLetter } from '@/assets/utils/text-utils';
import { httpRequestErrorHandling, validateParams } from '@/store/modules/utils';
import { messages as zbMoneyMessages } from '@/v2/zb-money/constants/messages';

export const actions = {
  fetchZbMoneyStatus({ commit }, uuid) {
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/hurdlr/${uuid}/subscription-tier`)
        .then((response) => {
          commit('setZbMoneyAccountData', response.data.hurdlr_account)
        })
        .catch((e) => {
          if (e.response.status !== 404) {
            httpRequestErrorHandling(e, commit);
          }
        });
    }
  },
  sendMigrationInformationOrderTask({ commit }, { orderTaskUuid, payload, }) {
    if(!validateParams.UUID(orderTaskUuid) || !validateParams.UUID(orderTaskUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/v2/order-tasks/${orderTaskUuid}/migrate`, payload)
        .then((response) => {
          const migrationPreference = response.data;
          if(response.status === 201) {
            commit('setMigrationPreferenceCustomerAssets', migrationPreference);
            const customMessage = { ...zbMoneyMessages.migrationCustomerAsset.success }
            customMessage.secondary = `Created.`
            commit('setAlertMessage', customMessage, { root: true });
            return {successful: true, data: migrationPreference};  
          } else {
            console.warn('RESPONSE NOT 201');
          }
          return {successful: false, data: migrationPreference};  
        })
        .catch((error)=>{
          if (error.response?.status === 400) {
            const customMessage = { ...zbMoneyMessages.migrationCustomerAsset }
            customMessage.error.secondary = error.response.data?capitalizeFirstLetter(error.response.data.error):'Contact support.';
            httpRequestErrorHandling(error, commit, customMessage);
          } else {
            httpRequestErrorHandling(error, commit, zbMoneyMessages.migrationCustomerAsset);
          }
          return {successful: false, data: null};
        })
    }
  },
  createMigrationPreferenceCustomerAssets({ commit }, { orderTaskUuid, payload, }) {
    if(!validateParams.UUID(orderTaskUuid) || !validateParams.UUID(orderTaskUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/v2/order-tasks/${orderTaskUuid}/set-migration-preference`, payload)
        .then((response) => {
          const migrationPreference = response.data;
          commit('setMigrationPreferenceCustomerAssets', migrationPreference);
          const customMessage = { ...zbMoneyMessages.migrationCustomerAsset.success }
          customMessage.secondary = `Created.`
          commit('setAlertMessage', customMessage, { root: true });
          return {successful: true, data: migrationPreference};
        })
        .catch((error)=>{
          httpRequestErrorHandling(error, commit, zbMoneyMessages.migrationCustomerAsset)
          return {successful: false, data: null};
        })
    }
  },
  updateMigrationPreferenceCustomerAssets({ commit }, { orderTaskUuid, migrationUUID, payload, }) {
    if(!validateParams.UUID(orderTaskUuid) || !validateParams.UUID(migrationUUID)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.put(`/api/v2/order-tasks/${orderTaskUuid}/set-migration-preference/${migrationUUID}`, payload)
        .then((response) => {
          const migrationPreference = response.data;
          commit('setMigrationPreferenceCustomerAssets', migrationPreference);
          const customMessage = {...zbMoneyMessages.migrationCustomerAsset.success}
          customMessage.secondary = `Updated.`
          commit('setAlertMessage', customMessage, { root: true });
          return {successful: true, data: migrationPreference};
        })
        .catch((error)=>{
          httpRequestErrorHandling(error, commit, zbMoneyMessages.migrationCustomerAsset)
          return {successful: false, data: null};
        })
    }
  },
  cancelMigrationPreferenceCustomerAssets({ commit }, { orderTaskUuid }) {
    if(!validateParams.UUID(orderTaskUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      const payload = {
        "action_at_term_end": null
      }
      return httpclient.patch(`/api/v2/order-tasks/${orderTaskUuid}`, payload)
        .then((response) => {
          if (response.status !== 200) {
            commit('setAlertMessage', zbMoneyMessages.cancelMigrationCustomerAsset.error, { root: true });
          } else {
            commit('setAlertMessage', zbMoneyMessages.cancelMigrationCustomerAsset.success, { root: true });
          }
          return response.data;
        })
        .catch((error) => {
          httpRequestErrorHandling(error, commit, zbMoneyMessages.cancelMigrationCustomerAsset)
        })
    }
  },
  fetchMigrationServicesOptions({ commit }, { orderTaskUuid }) {
    if(!validateParams.UUID(orderTaskUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/order-tasks/${orderTaskUuid}/service-for-migrate`)
        .then((response) => {
          commit('setMigrationOptionsCustomerAssets', response.data);
          return { success: true, data: response }
        })
        .catch((error)=>{
          commit('setMigrationOptionsCustomerAssets', []);
          httpRequestErrorHandling(error, commit, zbMoneyMessages.migrationOptions)
          return { success: false, data: error }
        })
    }
  },
  clearMigrationPreferenceCustomerAssets({ commit }) {
    commit('clearMigrationPreferenceCustomerAssets');
  },
  resetZbMoneyState({ commit }) {
    commit('resetZbMoneyState');
  }
}
