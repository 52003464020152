export default {
  account: {},
  accounts: [],
  accountUuid: undefined,
  deviceInfo: [],
  users: [],
  profile: null,
  auth0User: {},
  auth0UserLogs: {},
  charges: []
};
