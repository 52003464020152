import httpclient from '@/store/httpclient';
import { validateParams, httpRequestErrorHandling } from '@/store/modules/utils';
import messages from '@/assets/messages';
import { AUTOMATED_PROCESS_TYPE } from '@/assets/constants/automations';
 

const automationPrefix = '/api';

export default {
  fetchAutomatedFilingStatus({ commit }, queryParams) {
    return httpclient.get(`${automationPrefix}/v2/automated-filing/status`, { params: queryParams })
      .then((response) => response.data)
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.automatedFilingStatus)});
  },
  fetchAutomatedFilingList({ commit }, queryParams) {
    return httpclient.get(`${automationPrefix}/v3/automated-filing/list`, { params: queryParams })
      .then((response) => response.data)
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.automatedFilingList)});
  },
  fetchAutomatedFiling({ commit }, AutomatedFilingUuid) {
    if (!validateParams.UUID(AutomatedFilingUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      
      return httpclient.get(`${automationPrefix}/v3/automated-filing/${AutomatedFilingUuid}`)
      .then((response) => response.data)
      .catch((e) => {
        if(e?.response?.status !== 404) {
          return httpRequestErrorHandling(e, commit, messages.orderUuid);
        }
      });
    }
  },
  fetchItemsQueue({ commit }, queryParams) {
    return httpclient.get(`${automationPrefix}/v2/ca-login-access-request-queue`, { params: queryParams })
      .then((response) => response.data)
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.itemsQueue)});
  },
  generateAutomationPayload({ commit }, orderTaskUuid) {
    if (!validateParams.UUID(orderTaskUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      
      return httpclient.get(`${automationPrefix}/v3/automated-filing/generate_payload/${orderTaskUuid}`)
      .then((response) => response.data)
      .catch((e) => {
        if(e?.response?.status !== 404) {
          return httpRequestErrorHandling(e, commit, messages.orderUuid);
        }
      });
    }
  },
  sendAutomatedFilingCallback({ commit },  { automatedFilingUuid, callbackPayload }) {
    if (!validateParams.UUID(automatedFilingUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.patch(`${automationPrefix}/v3/automated-filing/${automatedFilingUuid}`, callbackPayload)
      .then((response) => response.data)
      .catch((e) => {
        if (e?.response?.status !== 404) {
          return httpRequestErrorHandling(e, commit, messages.orderUuid);
        }
      });
    }
  },  
  fetchAutomationPayloadToValidate({ commit }, orderTask) {
    const uuid = orderTask.order_task_uuid;
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      
      return httpclient.get(`${automationPrefix}/v3/automated-filing/generate_payload/${uuid}`)
        .then((response) => {
          commit('setAutomationPayload', { payload: response.data, orderTask});
          return response.data;
        })
        .catch((e) => {
          if(e?.response?.status !== 404) {
            httpRequestErrorHandling(e, commit, messages.orderUuid);
          }
        });
    }
  },
  startRobotCAAgencyLoginAccountSharing({ commit }) {
    return httpclient.post(`${automationPrefix}/v2/robotic-process`, { name: AUTOMATED_PROCESS_TYPE.REQUEST_AGENCY_LOGIN_ACCOUNT })
      .then((response) => {
        const data = response.data;
        const customMessage = {
          ...messages.automatedStartAutomationTypeProcess.success
        };
        if(data?.uuid) {
          customMessage["secondary"] = `Process UUID ${data?.uuid}`;
        }
        commit('setAlertMessage', customMessage, { root: true });
        return data;
      })
      .catch((e) => {
        httpRequestErrorHandling(e, commit, messages.automatedStartAutomationTypeProcess);
      });
  },
  fetchQueueNumber({ commit }, { jurisdiction_code, formation_type, automated_filing_uuid = null }) {
    const queryString = automated_filing_uuid ? `?${automated_filing_uuid}` : '';
    return httpclient.get(`${automationPrefix}/v3/automated-filing/queue_number/${formation_type}/${jurisdiction_code}${queryString}`)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return { error: "Queue data not available", commit };
        });
  },
}
