export const mutations = {
  setDomains: (state, domains) => {
      state.domains = domains;
  },
  setEmails: (state, emails) => {
    state.emails = emails;
},
};

export default {};
