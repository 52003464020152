import Vue from 'vue';
import Vuex from 'vuex';
import { createResource, PaginationPlugin } from 'vuex-pagination';
import httpclient from '../../httpclient';
import { validateParams, httpRequestErrorHandling } from '../utils';
import messages from '../../../assets/messages';

Vue.use(Vuex);
Vue.use(PaginationPlugin);

const state = {
  dataAlert: {},
  dataAlerts: {},
  dataAlertRecords: {},
  startUpdateResponse: null,
  updateErrors: false
};
const dateOptions = {year: "numeric", month: "short", day: "numeric"};

const inferState = (dataAlert) => {
  dataAlert.status = dataAlert?.status || 'not-started';
  if (dataAlert.status == 'not-started') {
    dataAlert.humanStatus = "Report has not been started";
    dataAlert.humanLastReport = "None";
  }
  else if (dataAlert.status == 'started') {
    let humanReadableUpdateStart = new Date(dataAlert.update_started).toLocaleTimeString(undefined, dateOptions);
    dataAlert.humanStatus = `Report started at ${humanReadableUpdateStart}`;
    dataAlert.humanLastReport = "None";
  } else if (dataAlert.status == 'complete') {
    dataAlert.humanLastReport = new Date(dataAlert.last_updated).toLocaleTimeString(undefined, dateOptions);
    dataAlert.humanStatus = `Report complete at ${dataAlert.humanLastReport}`;
  } else if (dataAlert.status == 'errored') {
    dataAlert.humanLastReport = new Date(dataAlert.last_updated).toLocaleTimeString(undefined, dateOptions);
    dataAlert.humanStatus = `Report errored at ${dataAlert.humanLastReport}`;
  }
  return dataAlert;
}

const dataAlertViewMap = {
  "order-tasks-sf-sync": "OrderServiceDataView",
  "order-tasks-without-related-jurisdiction":  "OrderServiceDataView",
  "mismatched-affiliate-pricing-for-plan": null,
  "repeated-singular-contacts": "BusinessEntity",
  "completed-formation-orders-deferred-items": "Order"
}

const dataAlertParamsMap = {
  "order-tasks-sf-sync": [
        {"prop": "taskUuid", "value": "order_task_uuid"},
        {"prop": "uuid", "value": "order_uuid"},
    ],
  "order-tasks-without-related-jurisdiction":  [
        {"prop": "taskUuid", "value": "order_task_uuid"},
        {"prop": "uuid", "value": "order_uuid"},
    ],
  "mismatched-affiliate-pricing-for-plan": [{"prop": "none", "value": "none"}],
  "repeated-singular-contacts": [
        {
            "prop": "businessEntityUuid",
            "value": "business_entity_uuid",
        }
    ],
  "completed-formation-orders-deferred-items": [{"prop": "uuid", "value": "order_uuid"}]
}

const addViewParams = (dataAlert) => {
  dataAlert.viewName = dataAlertViewMap[dataAlert.id];
  dataAlert.params = dataAlertParamsMap[dataAlert.id];
  return dataAlert;
}


const mutations = {
  setDataAlert: (state, dataAlert) => {
    dataAlert = addViewParams(inferState(dataAlert));
    state.dataAlert = dataAlert;
  },
  setDataAlerts: (state, dataAlerts) => {
    dataAlerts = dataAlerts.map(addViewParams).map(inferState);
    state.dataAlerts = dataAlerts;
  },
  setReportRunStatus: (state, response) => {
    if (response?.errors) {
      state.startUpdateResponse = response.errors.join(', ');
      state.updateErrors = true;
    } else {
      state.startUpdateResponse = response;
      state.updateErrors = false;
    }
  },
  resetReportRunStatus: (state) => {
    state.startUpdateResponse = null;
    state.updateErrors = false;
  }
};

const actions = {
  fetchDataAlert({ commit }, { id }) {
    if(!validateParams.ID(id)) {
      httpRequestErrorHandling(new Error(messages.validationID), commit, messages.validationID)
    } else {
      return httpclient.get(`/api/v2/data-alerts/${id}/`)
        .then((response) => {
          commit('setDataAlert', response.data);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchDataAlerts({ commit }) {
    return httpclient.get('/api/v2/data-alerts/')
      .then((response) => {
        commit('setDataAlerts', response.data);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  startReportRun({ commit }, { id }) {
    if(!validateParams.ID(id)) {
      httpRequestErrorHandling(new Error(messages.validationID), commit, messages.validationID)
    } else {
      return httpclient.post(`/api/v2/data-alerts/${id}/`)
        .then((response) => {
          commit('setReportRunStatus', response.data);
        })
        .catch((e) => {
          if (e?.response?.data?.errors) {
            commit('setReportRunStatus', e.response.data);
          }
          else {
            httpRequestErrorHandling(e, commit);
          }
        }
        );
    }
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

function fetchDataAlertPage(opts) {
  return httpclient.get(`/api/v2/data-alerts/${opts.args.dataAlertId}/?page=${opts.page}&page_size=${opts.pageSize}`)
    .then((response) => {
      return {
        total: response.data.count,
        data: response.data.records
      };
    })
    .catch(() => {
      // eslint-disable-next-line no-empty
      return {
        total: 0,
        data: []
      }
    });
}

createResource('dataAlertRecords', fetchDataAlertPage);
