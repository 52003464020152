
export const getters = {
    getWebsite(state) {
        return state.website;
    },
    getWebsites(state) {
      return state.websites;
    },
    getDomains(state) {
        return state.domains;
    },
    getEmails(state) {
        return state.emails;
    },
};

export default {};
