<template>
    <div class="zg-goodJobBanner" :style="{backgroundColor: randomizedBG, color: textColor }">
        <p class="big-text">{{ mainMessage }}</p>
        <p class="small-text">{{ secondaryMessage }}</p>
    </div>
</template>
<script>
export default {
    name: 'ZgGoodJobBanner',
    data() {
        return {
            randomizedBG: null,
            backGroundColor: 'white',
            textColor: 'black',
            mainMessage: '',
            secondaryMessage: '',
            backgroundColors: [
                '#CFF9FA',
                '#D9FEC5',
                '#111112',
                '#CDDEFD',
                '#FFC877',
                '#33F28B'
            ],
            mainMessages: [
                'The work you do is invaluable!',
                'You are amazing!',
                'Keep it up!',
                'We appreciate you!',
                'You are essential!',
                'You\'re making an impact. For real!'
            ],
            secondaryMessages: [
                'Thank you for all the support you provide our customers.',
                'Way to identify and report issues for our customers.',
                'Our customers and our teammates rely on your efforts.',
                'You are the backbone of our team. Thank you for all you do.',
                'What you do truly makes a difference. Thank you.',
                'Thanks for making ZenBusiness a better company.'
            ]
        }
    },
    methods: {
        setInitialValues(){
            this.randomizedBG = this.backgroundColors[Math.floor(Math.random()*this.backgroundColors.length)] 
            if (this.randomizedBG == '#111112') this.textColor = 'white'
            this.mainMessage = this.mainMessages[Math.floor(Math.random() * this.mainMessages.length)]
            this.secondaryMessage = this.secondaryMessages[Math.floor(Math.random() * this.secondaryMessages.length)]
        }
    },
    created() {
        this.setInitialValues()
    }
}
</script>
<style lang="scss" scoped>
.zg-goodJobBanner {
    display: inline-flex;
    flex-direction: column;
    padding: em(15);
    border-radius: em(10);
    box-shadow: 0 em(1) em(2) em(1) rgba($clr-black, 0.1);
    transition: 0.3s;
    margin-bottom: em(10);
    width: 100%;
}
.big-text {
    //styleName: lg/title/large-alt;
font-family: Montserrat;
font-size: 38px;
font-weight: 800;
line-height: 46px;
letter-spacing: -1px;
text-align: center;
}
.small-text {
    //styleName: lg/body/extra-large-regular;
font-family: Inter;
font-size: 20px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0px;
text-align: center;
}
</style>