export const messages = {
  migrationCustomerAsset: {
    success: {
      primary: 'The migration preference have been saved.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Something happened. The migration was not saved',
      type: 'alert alert-danger'
    }
  },
  cancelMigrationCustomerAsset: {
    success: {
      primary: 'The migration have been canceled.',
      secondary: 'The order taks can be canceled in Salesforce.',
      type: 'alert alert-success'
    },
    error: {
      primary: 'Something happened. The migration was not canceled.',
      secondary: 'Please report this error in the App-Prod channel for Engineering to follow up.',
      type: 'alert alert-danger'
    }
  },
  migrationOptions: {
    error: {
      primary: 'The migration options couldnt be retrieved.',
      type: 'alert alert-danger'
    }
  }
};

export default messages;
