import { computeFullNameOfPersonMixin } from "../mixins";

export const validationLLCFormationIL = (
  businessEntityAuthorizedSignatory,
  businessEntityRegisteredAgent,
  managers,
  members,
  physicalAddress,
  businessEntity
) => {
  const messages = [];
  validateAddressLength(messages, [
    businessEntityAuthorizedSignatory,
    businessEntityRegisteredAgent,
    physicalAddress,
  ]);
  validateEntityNameLength(messages, businessEntity.business_entity_name);
  validateFullnameLengthVariant1(messages, [...managers, ...members]);
  validateFullnameLengthVariant2(messages, [
    businessEntityAuthorizedSignatory,
    businessEntityRegisteredAgent,
  ]);
  validateCompanyNameLength(messages, [
    businessEntityRegisteredAgent,
    ...members,
    ...managers,
  ]);
  validateNumberManagers(messages, managers);
  return messages;
};

const validateAddressLength = (messages, entities) => {
  const MAX_LENGTH = 45;
  for (const elem of entities) {
    const address_1 = elem?.address_1;
    const address_2 = elem?.address_2;
    if (!address_1 || !address_2) {
      return;
    }
    let address = address_1;
    if (address_2 && address_2.length > 0) {
      address = `${address}, ${address_2}`;
    }
    if (address.length > MAX_LENGTH) {
      messages.push({ message: formatMessage(address, MAX_LENGTH) });
    }
  }
};

const validateEntityNameLength = (messages, entityName) => {
  const MAX_LENGTH = 120;
  if (entityName?.length > MAX_LENGTH) {
    messages.push({ message: formatMessage(entityName, MAX_LENGTH) });
  }
};

const validateFullnameLengthVariant1 = (messages, entities) => {
  // Validation for managers and members.
  const MAX_LENGTH = 60;
  for (const elem of entities) {
    const firstName = elem?.first_name;
    const lastName = elem?.last_name;
    const middleName = elem?.middle_name;
    const suffix = elem?.suffix;
    if (!firstName || !lastName) {
      return;
    }
    let fullName = `${lastName}, ${firstName}`;
    if (middleName && middleName.length > 0) {
      fullName = `${fullName} ${middleName[0]}`;
    }
    if (suffix && suffix.length > 0) {
      fullName = `${fullName} ${suffix}`;
    }
    if (fullName.length > MAX_LENGTH) {
      messages.push({ message: formatMessage(fullName, MAX_LENGTH) });
    }
  }
};

const validateFullnameLengthVariant2 = (messages, entities) => {
  // Validation for registered agent and authorized signatory.
  const MAX_LENGTH = 60;
  for (const elem of entities) {
    const firstName = elem?.first_name;
    const lastName = elem?.last_name;
    const middleName = elem?.middle_name;
    const suffix = elem?.suffix;
    if (!firstName || !lastName) {
      return;
    }
    let fullName = firstName;
    if (middleName && middleName.length > 0) {
      fullName = `${fullName} ${middleName}`;
    }
    fullName = `${fullName} ${lastName}`;
    if (suffix && suffix.length > 0) {
      fullName = `${fullName}, ${suffix}`;
    }
    if (fullName.length > MAX_LENGTH) {
      messages.push({ message: formatMessage(fullName, MAX_LENGTH) });
    }
  }
};

const validateCompanyNameLength = (messages, entities) => {
  const MAX_LENGTH = 60;
  for (const elem of entities) {
    if (elem?.company_name?.length > MAX_LENGTH) {
      messages.push({ message: formatMessage(elem.company_name, MAX_LENGTH) });
    }
  }
};

const validateNumberManagers = (messages, managers) => {
  const MAX_LENGTH = 8;
  if (managers?.length > MAX_LENGTH) {
    messages.push({ message: "Too many Managers. Paper filing required." });
  }
};

const formatMessage = (name, expectedLength) => {
  return `${name} is currently ${name.length} characters long. It cannot exceed ${expectedLength} characters.`;
};

// TODO: to be removed
export const validationLLCFormationVA = (
  registeredAgent,
  managers,
  members,
) => {
  const messages = [];
  if (registeredAgent["company_name"] === "") {
    const getFullName = computeFullNameOfPersonMixin.methods.computeFullNameOfPerson;
    const raFullName = getFullName(registeredAgent);
    const result = [...managers, ...members].find(
      (person) => raFullName === getFullName(person)
    );
    if (!result) {
      messages.push({
        message:
          `This order cannot be processed until the RA Capacity of the individual listed as Registered Agent (${raFullName}) ` +
          "is determined. Once determined, this order cannot be automated unless the individual listed as Registered Agent " +
          `(${raFullName}] is also listed as a Member or Manager of the entity. Available Capacity selections are:`,
        details: [
          "General Partner of a General or Limited Partnership that is a Member or Manager of the Limited Liability Company",
          "Member of the Virginia State Bar",
          "Member or Manager of a Limited Liability Company that is a Member or Manager of the Limited Liability Company",
          "Member or Manager of the Limited Liability Company",
          "Officer or Director of a Corporation that is a Member or Manager of the Limited Liability Company",
          "Trustee of a Trust that is a Member or Manager of the Limited Liability Company",
        ],
      });
    }
  }
  return messages;
};
