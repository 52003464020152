export default {
  agencyLoginAccounts: [],
  sharedAgencyLoginAccounts: [],
  orderTaskFulfillment: undefined,
  agencyAccountsByJurisdiction: [],
  businessEntityJurisdictionFulfillment: {},
  ccorpStockInformationFulfillment: {},
  fulfillmentData: {},
  corpStockFeatureFlag: true
}
