import moment from 'moment';
import {
  validateAddressLength,
  validateCompanyNameLength,
  validateEntityNameLength,
  validateFullnameLengthVariant1,
  validateFullnameLengthVariant2,
  validateNumberManagers,
} from "@/v2/automation/store/validations/methodsValidation";
import {
  getContact,
  getContacts,
} from "@/store/modules/business-entities/getters";
import { computeFullNameOfPersonMixin } from "@/components/mixins";
import {
  returnMissingFields,
  genericMissingFieldsMessage,
} from "./methodsValidation";

export const validationLLCFormationIL = (payload) => {
  if (!payload) {
    return [];
  }
  const messages = [];
  validateAddressLength(messages, [
    payload.authorized_signatory,
    payload.registered_agent,
    payload.address,
  ]);
  validateEntityNameLength(messages, payload.entity_name);
  validateFullnameLengthVariant1(messages, [
    ...payload.managers,
    ...payload.members,
  ]);
  validateFullnameLengthVariant2(messages, [
    payload.authorized_signatory,
    payload.registered_agent,
  ]);
  validateCompanyNameLength(messages, [
    payload.registered_agent,
    ...payload.managers,
    ...payload.members,
  ]);
  validateNumberManagers(messages, payload.managers);
  return messages;
};

export const validationLLCFormationMI = (orderTask) => {
  const messages = [];
  if (
    orderTask.order_task_data?.manager_type !==
    "All the owners (Member managed)"
  ) {
    messages.push(
      "Because this entity’s Management Structure is manager managed, it must be manually filed."
    );
  }
  return messages;
};

export const validationLLCFormationNY = (payload) => {
  if (!payload) {
    return [];
  }
  const messages = [];
  if (payload?.county === "") {
    messages.push({
      main:
        "This order cannot be processed because the following information is missing:",
      items: [
        "LLC Office County",
      ],
    });
  }
  return messages;
};

export const validationLLCFormationVA = (payload, contacts) => {
  if (!payload) {
    return [];
  }
  const { manager_type_in_english: managerTypeStr } = payload
  const messages = [];
  const getFullName = computeFullNameOfPersonMixin.methods.computeFullNameOfPerson;
  const managers = getContacts(contacts, "manager");
  const members = getContacts(contacts, "member");
  const managerMembers = members.filter((member) =>
    !!managers.find((manager) => getFullName(member) === getFullName(manager)));
  const isManagerTypeSomeOwners = managerTypeStr.startsWith('Only');

  // If Management Structure is “Only some of the owners (Manager managed)”
  // and none of the owners listed has Manager
  if (isManagerTypeSomeOwners && !managers.length) {
    messages.push('This order cannot be processed because the Management Structure indicates that Members will be managing the entity, but none of the Members are listed as Managers.');
  }

  // If Management Structure is “Only some of the owners (Manager managed)”
  // and all of the owners listed has Manager
  if (isManagerTypeSomeOwners && members.length === managerMembers.length) {
    messages.push({
      main: 'This order cannot be processed because the Management Structure indicates that only some Members will be managing the entity, but all of the Members are listed as Managers. Please either',
      items: [
        'confirm Management Structure is “All the owners (Member managed)”',
        'or add/adjust Members to include one or more Manager = “No”.'
      ],
    });
  }

  // If Management Structure is “Some or all of the owners and other managers (Manager managed)”
  // or “None of the owners, only other managers (Manager managed)“
  // and there are no Managers listed
  if ((managerTypeStr.startsWith('Some') || managerTypeStr.startsWith('None')) && !managers.length) {
    messages.push({
      main: 'This order cannot be processed because the Management Structure indicates that Managers will be managing the entity, but there are no Managers listed. Please either',
      items: [
        'confirm either Management Structure isn\'t “All the owners (Member managed)” or “Only some of the owners (Manager managed)“',
        'or add Managers.'
      ],
    });
  }

  if (payload.registered_agent?.company_name !== "") {
    const registeredAgent = getContact(contacts, "registered_agent");
    const raFullName = getFullName(registeredAgent);
    const result = [...managers, ...members].find(
      (person) => raFullName === getFullName(person)
    );
    if (!result) {
      messages.push({
        main: `This order cannot be processed until the RA Capacity of the individual listed as Registered Agent (${raFullName}) is determined. Available Capacity selections are:`,
        items: [
          "General Partner of a General or Limited Partnership that is a Member or Manager of the Limited Liability Company",
          "Member of the Virginia State Bar",
          "Member or Manager of a Limited Liability Company that is a Member or Manager of the Limited Liability Company",
          "Member or Manager of the Limited Liability Company",
          "Officer or Director of a Corporation that is a Member or Manager of the Limited Liability Company",
          "Trustee of a Trust that is a Member or Manager of the Limited Liability Company",
        ],
        secondary: `Once determined, this order must be Manually processed UNLESS the individual listed as Registered Agent (${raFullName}) is also listed as a Member or Manager of the entity.`
      });
    }
  }
  return messages;
};

export const validationLLCFormationCO = (payload) => {
  if (!payload) {
    return [];
  }
  const messages = [];
  validateMissingDataCO(messages, payload);
  validateFutureFileDateService(messages, payload['future_file_date_service']);
  return messages;
};

const validateMissingDataCO = (messages, payload) => {
  const fields = [
    "entity_name",
    "manager_type",
    "principal_address.address_1",
    "principal_address.city",
    "principal_address.state",
    "principal_address.zip",
    "principal_address.country",
    "mailing_address.address_1",
    "mailing_address.city",
    "mailing_address.state",
    "mailing_address.zip",
    "mailing_address.country",
    "registered_agent.address_1",
    "registered_agent.city",
    "registered_agent.zip",
    "authorized_signatory.address_1",
    "authorized_signatory.city",
    "authorized_signatory.state",
    "authorized_signatory.zip",
    "authorized_signatory.country",
  ];
  const missingFields = returnMissingFields(fields, payload);
  if (missingFields.length > 0) {
    const completeMessage = genericMissingFieldsMessage(missingFields);
    messages.push(completeMessage);
  }
}

const validateFutureFileDateService = (messages, service) => {
  const filingDate = service['future_filing_date'];
  const status = service['status'];
  if (filingDate === '' && status === 'pending') {
    const completeMessage = genericMissingFieldsMessage(['future_filing_date']);
    messages.push(completeMessage);
  }
  else if (filingDate !== '') {
    const today = moment().utc();
    const in90Days = today.add(90, 'days');
    const futureFilingDate = moment(filingDate);
    const acceptedFilingDate = futureFilingDate.subtract(90, 'days');
    if (futureFilingDate > in90Days && status === 'pending') {
      messages.push('This order cannot be processed because the Future File Date is more than 90 days in the future. ' +
                    `This order can be filed on ${acceptedFilingDate.format('MM/DD/YYYY')}.`)
    }
    if (today > futureFilingDate && !(status == 'canceled' || status === 'completed')) {
      messages.push('This order has a Future File Date that has passed. Please proceed accordingly.')
    }
  }
}
