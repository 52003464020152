import {
  validationCorpAnnualReportNJ,
  validationLLCAnnualReportCA,
  validationLLCAnnualReportNJ,
} from "./orderTaskAnnualReportValidation";
import {
  validationLLCFormationCO,
  validationLLCFormationIL,
  validationLLCFormationMI,
  validationLLCFormationNY,
  validationLLCFormationVA,
} from "./orderTaskFormationValidation";

const validationLLCAnnualReport = (orderTask, payload, contacts) => {
  const jurisdiction = orderTask.order_task_jurisdiction;
  let messages = [];
  switch (jurisdiction){
    case 'NJ':
      messages = validationLLCAnnualReportNJ(orderTask, payload, contacts);
      break;
    case 'TX':
      break;
    case 'CA':
      messages = validationLLCAnnualReportCA(payload);
      break;
    default:
      console.warn(`Jurisdiction ${jurisdiction} not supported`);
  }
  return messages;
}

const validationCorpAnnualReport = (orderTask, payload, contacts) => {
  const jurisdiction = orderTask.order_task_jurisdiction;
  let messages = [];
  switch (jurisdiction){
    case 'NJ':
      messages = validationCorpAnnualReportNJ(orderTask, payload, contacts);
      break;
    case 'TX':
      break;
    default:
      console.warn(`Jurisdiction ${jurisdiction} not supported`);
  }
  return messages;
}

const validationFormation = (orderTask, payload, contacts) => {
  const jurisdiction = orderTask.order_task_jurisdiction;
  let messages = [];
  switch (jurisdiction){
    case 'CO':
      messages = validationLLCFormationCO(payload);
      break;
    case 'IL':
      messages = validationLLCFormationIL(payload);
      break;
    case 'MI':
      messages = validationLLCFormationMI(orderTask);
      break;
    case 'NY':
      messages = validationLLCFormationNY(payload);
      break;
    case 'VA':
      messages = validationLLCFormationVA(payload, contacts);
      break;
    default:
      console.warn(`Jurisdiction ${jurisdiction} not supported`);
  }
  return messages;
}

const validationGoodStanding = (orderTask) => {
  const jurisdiction = orderTask.order_task_jurisdiction;
  let messages = [];
  switch (jurisdiction){
    case 'CA':
      // TODO: Only business_id is required to be validated in the payload but it's not currently provided
      return messages;
    default:
      console.warn(`Jurisdiction ${jurisdiction} not supported`);
  }
  return messages;
}

export default {
  validationLLCAnnualReport,
  validationCorpAnnualReport,
  validationFormation,
  validationGoodStanding,
};


