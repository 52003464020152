import httpclient from '@/store/httpclient';
import messages from '@/assets/messages';
import { httpRequestErrorHandling, validateParams } from '@/store/modules/utils';

export default {
  fetchResultsSearch({ commit }, { word, page=null }) {
    if(word && word.length>3) {
      let clean_word = encodeURIComponent(word)
      let url = `/api/v3/jurisdictions/search/deep?search=${clean_word}`;
      if(page) {
        url = `${url}&page=${page}`
      }
      return httpclient.get(url)
        .then((response) => {
          const results = response.data.results;
          if(page) {
            commit('appendResults', results);
          } else {
            commit('setResults', results);
          }
          if(response.data.next) {
            commit('setNext', page?page+1:2);
          } else {
            commit('clearNext');
          }
        });
    }
  },
  clearResults({commit}) {
    commit('clearResults');
    commit('clearNext');
  },
  checkActiveOrderTaskPerJurisdiction({ commit }, { jurisdictionUuid, serviceProducts }) {
    if(!validateParams.UUID(jurisdictionUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v3/jurisdictions/${jurisdictionUuid}/order-tasks-check?services=${serviceProducts.join(',')}`)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit);
          return null;
        });
    }
  }
};
