import httpclient from '../httpclient';
import messages from '../../assets/messages';
import {validate as uuidValidate} from 'uuid';
import store from '@/store';

/**
 * Helper function for committing changes to the Vuex store in promises
 * @param {Object} commit
 * @param {String} label
 * @param {Function} mutator - Optionally use if you want to mutate the response.data
 * @returns {Object} response
 */
const camelToUnderscore = (key) => {
  return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}

const convertToSnakecase = (obj) => {
  let retData = {}
  Object.keys(obj).forEach(key => {
    retData[camelToUnderscore(key)] = obj[key]
  });
  return retData;

}
export const commitResponseToStore = (commit, label, mutator = (response) => (response.data)) => (response) => {
  if (label === 'setWebsite' && response.data?.results?.[0]) {
    response.data.results[0] = convertToSnakecase(response.data.results[0])
  }
  commit(label, mutator(response));
  return response;
};

export const generateDocumentV2 = (commit, docType, params, deleteFile = true) => {
  /** Go to document-api microservice, generate the requested doc and open it in a new tab
   *
   * @param {Object} commit
   * @param {String} docType
   * @param {Object} params
   */
  let baseUrl = '/documents/api/v1/';
  let deleteRootUrl = `${baseUrl}document-generated`;
  let messageDocType = '';

  if (docType === 'soo') {
    baseUrl += `soo/${params.business_entity_uuid}?create_document_obj=${params.create_document_obj}`;
    params = null;
    messageDocType = 'SOO';
  } else if (docType === 'ss4') {
    baseUrl += `ss4/${params.business_entity_uuid}`;
    delete params.business_entity_uuid;
    messageDocType = 'SS4';
  } else if (docType === 'f2553') {
    baseUrl += `f2553/${params.business_entity_uuid}`;
    params = null;
    messageDocType = 'F2553';
  }

  // if (docType === 'cora') {
  //   baseUrl += 'compliance/cora';
  //   messageDocType = 'CoRA';
  // } else if (docType === 'ss4') {
  //   baseUrl += 'compliance/ss4';
  //   messageDocType = 'SS4';

  httpclient.post(baseUrl, params)
    .then((response) => {
      if (response.data.file_url) {
        messages.DocumentStatus.success.primary = messages.DocumentStatus.success.primary.replace('<>', messageDocType);
        commit('setAlertMessage', messages.DocumentStatus.success, {root: true});

        // here we only need the file handle, not the full link
        let fileHandle = response.data.file_url.split('/')
        fileHandle = fileHandle[fileHandle.length - 1];
        const deleteUrl = `${deleteRootUrl}/${fileHandle}`;

        openDocument(response, fileHandle, deleteUrl, deleteFile);
      } else {
        commit('setAlertMessage', response.message, {root: true});
      }
    }).catch((error) => {
    if (error.response.data.message) {
      messages.DocumentStatus.responseError.primary = `Error: ${error.response.data.message}`;
      commit('setAlertMessage', messages.DocumentStatus.responseError, {root: true});
    } else {
      messages.DocumentStatus.error.primary = messages.DocumentStatus.error.primary.replace('<>', messageDocType);
      commit('setAlertMessage', messages.DocumentStatus.error, {root: true});
    }
  });
};

export const openDocument = (response, fileHandle, url, deleteFile = true) => {
  // open document in a new tab
  const link = document.createElement('a');

  // for compatibility purposes while all docs are connected to docs-api
  link.href = response.data.data ? response.data.data.file_url : response.data.file_url;

  link.setAttribute('download', fileHandle);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();

  // We not always need to delete the file after generating it.
  // for example when creating a SOO doc, we don't have to delete it.
  if (deleteFile) {
    // delete file in Filestack
    httpclient.delete(url)
      .then(() => {
        console.log('removed');
      })
      .catch((err) => {
        console.log('err', err);
      });
  }
};

export const generateTemporalDocument = (content, format, filename) => {
  /** Function that generates a document in memory to download it
   * @param string content - Content that will fill the temporal document
   * @param string format - Format that will determine the extension and file type
   * @param string filename - Exporting file name
   */
  let fileType = '';
  let element = document.createElement('a');
  if (format === 'json') {
    fileType = 'data:application/json;charset=utf-8,';
    filename += '.json';
  } else {
    fileType = 'data:text/plain;charset=utf-8,';
    filename += '.txt';
  }
  element.setAttribute('href', fileType + encodeURIComponent(content));
  // Populate the file
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  // Download the file
  element.click();
  document.body.removeChild(element);
}

export const generateSortFunction = (sortFieldList) =>
  /** Generate a function that can be passed to Array.sort() and will perform a SQL-like sort operation.
   * The input is a list of objects that contain the following properties:
   * --accessor: a function that maps an object being sorted to one of its fields
   * --ascending: a boolean indicating whether this field should be sorted in ascending or descending order
   * For example, the following input:
   * [ { accessor: (o => o.service_name), ascending: true }, { accessor: (o => o.price), ascending: false } ]
   * Is equivalent to the SQL expression "SORT BY service_name ASC, price DESC".
   *
   * @param {Array} sortFieldList - a list of objects representing the fields to sort by, and in what order
   */
    (obj1, obj2) => {
    for (let i = 0; i < sortFieldList.length; i++) {
      const {accessor, ascending} = sortFieldList[i];
      const val1 = accessor(obj1);
      const val2 = accessor(obj2);
      if (val1 < val2) {
        return ascending ? -1 : 1;
      }
      if (val1 > val2) {
        return ascending ? 1 : -1;
      }
    }
    // No more fields to compare; the two objects are identical according to the comparison criteria
    return 0;
  };

export const allSettled = (promises) => {
  /*
  A stand-in for Promise.allSettled(), which is not supported by the version of babel being used.
  Source: https://stackoverflow.com/a/39031032
   */
  const wrappedPromises = promises.map((p) => Promise.resolve(p)
    .then(
      (val) => ({status: 'fulfilled', value: val}),
      (err) => ({status: 'rejected', reason: err})
    ));
  return Promise.all(wrappedPromises);
};

export const httpRequestErrorHandling = (error, commit, typeError = messages.fetchMessage) => {
  if (!error) {
    console.error("The error wasn't received")
  } else {
    if (error.response) {
      store.dispatch('integrations/addRequestError', error.response)
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      commit('setAlertMessage', (typeError != messages.fetchMessage) ? typeError.error : messages.fetchMessage.errorResponded, {root: true})
    } else if (error.request) {
      // The request was made but no response was received
      commit('setAlertMessage', (typeError != messages.fetchMessage) ? typeError.error : messages.fetchMessage.errorNoResponse, {root: true})
    } else {
      commit('setAlertMessage', typeError.error, {root: true})
    }
    console.error(error)
  }
};

export const validateParams = {
  UUID: (param) => {
    return uuidValidate(param)
  },
  ID: (param) => {
    return param ? true : false
  }
};

export default commitResponseToStore;
