<template>
  <div class="col-sm-6 offset-sm-3 mt-sm-4">
    <h1 class="text-center">Login processing...</h1>
    <p class="text-center text-success" v-if="success">{{success}}</p>
    <p class="text-center text-danger" v-if="failure">{{failure}}</p>
  </div>
</template>

<script>
export default {
  name: 'callback',
  props: ['auth'],
  data() {
    return {
      success: null,
      failure: null,
      error: null
    };
  },
  mounted() {
    this.auth.handleAuthentication().then(() => {
      this.success = 'Processing your login. Please wait.';
    }).catch((err) => {
      if (err.error === 'server_error') {
        this.failure = 'Confirm you are not blocking third-party cookies.';
      } else {
        this.failure = `An unexpected issue has occurred: ${err.message}`;
      }
    });
  }
};
</script>

<style>
</style>
