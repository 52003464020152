export default {
  automatedFilingTracking(state) {
    const { automatedFilingTracking } = state;
    return automatedFilingTracking;
  },
  getAutomatedFilingStatus: (state) => (isTest = false) => {
    const { sosStatus } = state
    const sosStatusFiltered = sosStatus.find(automatedFiling => automatedFiling.is_test === isTest)
    return {
      completedDatetime: sosStatusFiltered?.completed_datetime,
      numTries: sosStatusFiltered?.num_tries,
      started: sosStatusFiltered?.started_datetime,
      status: sosStatusFiltered?.status,
      subStatus: sosStatusFiltered?.sub_status,
      test: sosStatusFiltered?.is_test,
      trackingUuid: sosStatusFiltered?.automated_filing_uuid,
      type: sosStatusFiltered?.type
    }
  },
  getOrderTask: ({ orderTask }) => {
    return orderTask;
  },
  getOrderTasks: ({ orderTasks }) => {
    return orderTasks;
  },
  getOrderTasksByType: (state) => (orderTaskType) => {
    const { orderTasks } = state
    return orderTasks.filter((orderTask) => orderTask.order_task_type === orderTaskType);
  },
  getOrderTaskActiveByType: (state) => (orderTaskType) => {
    const { orderTasks } = state
    return orderTasks.find((orderTask) => orderTask.order_task_type === orderTaskType && orderTask.order_task_status === 'active')
  },
  getOrderTaskStatusArrayByType: (state) => (orderTaskType, statusToCheck = []) => {
    const { orderTasks } = state
    return orderTasks.find(
      (orderTask) =>
        orderTask.order_task_type === orderTaskType &&
        statusToCheck?.indexOf(orderTask.order_task_status) > -1
      )
  },
  getOrderTasksByTypeArr: (state) => (orderTaskTypesArr) => {
    const { orderTasks } = state
    return orderTasks.reduce( (acc, curr) => {
      if (orderTaskTypesArr.includes(curr.order_task_type)) acc.push(curr)
      return acc
    }, [])
  },
  getOrderTasksByOrder: (state) => (orderUuid) => {
    const { orderTasks } = state
    return orderTasks.reduce( (acc, curr) => {
      if (curr.order_uuid === orderUuid) acc.push(curr)
      return acc
    }, [])
  },
  getRegisteredAgentData: (state) => (formationType) => {
    const { order } = state
    const raServiceIndex = order.order_data.findIndex(od => od.service === formationType)
    return raServiceIndex !== -1 ? order.order_data[raServiceIndex].registered_agent : null
  },
  getAutomatedFiling: (state) => (isTest = false) => {
    const { sosStatus } = state;
    return sosStatus.find(automatedFiling => automatedFiling.is_test === isTest)
  },
  getUuidOrder: ({order}) => {
    return order.order_uuid;
  },
  getUuidBusinessEntity: ({order}) => {
    return order.business_entity_uuid
  },
  getJurisdiction: ({order}) => {
    return order.jurisdiction;
  },
  getDaoDesignation: (state) => {
    const daoDesignationIndex = state.orderTasks.findIndex(ot => ot.order_task_type === 'dao_designation')
    return daoDesignationIndex !== -1 ? state.orderTasks[daoDesignationIndex] : null
  },
  entityStatusAtSOS(state) {
    const { entityStatusAtSOS } = state;
    return entityStatusAtSOS;
  },
  getSplitBillingItems: (state) => {
    const splitBillingItems = []
    const billingItems = state.transactionalBillingItems.concat(state.subscriptionBillingItems).filter((item) => item.price > 0 || item.fees > 0)
    billingItems.forEach((item) => {
      if (!isNaN(item.fees) || !isNaN(item.price)) {
        if (item.fees !== 0 && item.price !== 0) {
          let feeItem = {...item}
          let priceItem = {...item}
          feeItem.price = 0
          priceItem.fees = 0
          splitBillingItems.push(priceItem, feeItem)
        } else {
          splitBillingItems.push(item)
        }
      }
    });
    return splitBillingItems
  },
  getCancelAndRefundReasons: (state) => {
    return state.cancelAndRefundReasons;
  },
  getRefundReasons: (state) => {
    return state.refundReasons;
  },
  getCancelReasons: (state) => {
    return state.refundReasons;
  },
  getOrderTracking: ( state ) => {
    return state.orderTracking;
  }
};
