<template>
  <div
    v-if="alert"
    class="alert d-flex flex-row"
    :class="alert.type"
    @blur="resetAlert"
    id="alert-div"
    tabindex="0"
    role="alert"
  >
    <div class="alert__content">
      <h2 class="alert__title">{{ alert.primary }}</h2>
      <p class="alert__subtitle" v-html="formattedSecondary"></p>
      <p v-if="showCopyButton" class="alert__copy">
        <SlotCopy :valueToCopy="markDownText">
          Copy data
        </SlotCopy>
      </p>
    </div>
      <button v-on:click="resetAlert" type="button" class="alert__close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SlotCopy from "@/components/v2/common/Generics/SlotCopy.vue";

export default {
  name: 'ZgAlert',
  components: {SlotCopy },
  props: ['alert'],
  computed: {
    ...mapState({
      markDownText: (state) => state.integrations.markDownText
    }),
    showCopyButton () {
      return this.alert.primary === 'There was a problem creating your issue.'
    },
    formattedSecondary() {
    if (!this.alert.secondary) return '';

    const messages = Array.isArray(this.alert.secondary) ? this.alert.secondary : [this.alert.secondary];

    return messages
      .map(msg => this.breakLongLines(msg))
      .join('<br>');
  }
  },
  methods: {
    resetAlert() {
      this.$store.dispatch('resetAlert');
    },
    breakLongLines(text) {
    const maxLength = 70;
    const regex = new RegExp(`(.{1,${maxLength}})(\\s|$)|(.{1,${maxLength}})`, 'g');
    return text.match(regex)?.join('<br>') || text;
  }
  },
  mounted() {
    document.getElementById("alert-div")?.focus();
  },
};
</script>

<style lang="scss" scoped>
  .alert {
    position: fixed;
    left: 0;
    right: 0;
    top: em(49);
    z-index: 1021;
    max-width: em(633);
    margin: auto;
    padding: em(8) em(20);
    border: none;
    max-height: 80vh;
    overflow-y: auto; 
    &__content {
      flex: 1;
      padding: 10px;
    }
    &__title {
      font-size: rem(16, 15);
      line-height: em(24, 16);
      font-weight: 700;
      color: $clr-white;
    }
    &__subtitle {
      font-size: rem(14);
      line-height: em(24, 14);
      font-weight: 400;
      padding: 0;
      color: $clr-white;
    }
    &__close {
      float: right;
      font-size: rem(21);
      font-weight: 700;
      line-height: 1;
      color: $clr-black;
      opacity: 0.5;
      padding: 0;
      background-color: transparent;
      border: 0;
    }
    &-success {
      background-color: $clr-primary-dk;
    }
    &-danger {
      background-color: $clr-danger;
    }
  }
</style>

<style lang="scss">
  .alert {
    a {
      color: $clr-white;
      font-weight: 800;
      text-decoration: underline;
      transition: 400ms $easeInOutBack;

      &:hover {
        color: $clr-white;
        text-decoration: none;
      }
    }
    .alert__copy {
      color: white;
      text-decoration: underline;
    }
  }
</style>
