import * as rudderanalytics from 'rudder-sdk-js';
import avo from './avo';

import manifest from '../manifest';


const rudderStackDestination = {
  make() {
    rudderanalytics.load(
      manifest.clients.rudderstack.apiKey,
      manifest.clients.rudderstack.url);
    rudderanalytics.ready(() => {});
  },

  logEvent(eventName, eventProperties) {
    // For any event transformation or PII filtering for different destination please refer to
    // https://counsl.atlassian.net/wiki/spaces/DATA/pages/3478356324/Rudderstack+GA4+Event+Transformation+and+Filtering
    rudderanalytics.track(eventName, eventProperties);
  },

  setUserProperties(userId, userProperties) {
    rudderanalytics.identify(userId, userProperties);
  },

  identify(userId) {
    rudderanalytics.identify(userId, {});
  },

  unidentify() {
    rudderanalytics.reset(false);
  },

  page(pageName, eventProperties) {
    rudderanalytics.page(pageName, eventProperties);
  }

};

const { environment } = manifest;

export const initAvo = () => {
  const ZEN_ENV_MAPPING = {
    local: 'dev',
    development: 'dev',
    test: 'dev',
    webpack: 'dev',
    production: 'prod'
  };

  avo.initAvo(
    { env: ZEN_ENV_MAPPING[environment], webDebugger: false },
    {},
    {},
    rudderStackDestination
  );
};

// track device type
const zenIsMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const mobileDeviceType = (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase())) ? 'tablet' : 'mobile';

/**
 * Track button pressed event
 *
 * @param {Object} props
 * @param {string} props.screenName The name of the screen that triggers the event.
 *    values restricted to:
 *    "sign_in", "forgot_password", "password_reset", "upgrade", "entity_unsupported",
 *    "entity_not_found", "chat", "formation_tracker", "action_menu", "settings", "onboarding",
 *    "onboarding_transition", "user_agreement", "document_file_sharing", "document_viewer",
 *    "template_viewer", "document_library", "template_library", "template_file_sharing",
 *    "product_detail", "product_suite", "no_documents", "templates_locked",
 *    "product_checkout_faq", "product_checkout", "expense_detail_edit",
 *    "no_expense_categorization", "expense_categorization", "expense_edit_categorization",
 *    "expenses_list", "journey_lesson", "journey_intro_hub", "journey_lesson_hub",
 *    "journey_lesson_review", "edu_story", "dashboard_homepage"
 * @param {string} props.component The high-level component that the current event is happening in.
 *    values restricted to:
 *    "login", "home", "onboarding", "main_chat", "user_agreements", "settings", "web", "root",
 *    "document_center", "product_suite", "checkout", "expenses", "j2s", "edu"
 * @param {string} props.buttonText The text that is displayed on a button. [Optional]
 * @param {string} props.buttonType The type of button that is tapped in an app_button_tapped alert.
 *    values restricted to:
 *    "action", "icon", "primary", "secondary", "tertiary", "link_message", "dropdown_item",
 *    "toggle", "medium", "small", "slider", "card", "list_item", "poll_option"
 * @param {string} props.buttonName The name of a button that is tapped.
 *    values restricted to:
 *    "action_menu", "action_menu_item", "settings", "sign_in", "forgot_password",
 *    "force_upgrade_update", "reset_password", "logout", "continue", "back_to_login",
 *    "formation_card_action", "business_entity_selector_item", "sort_item", "filter_item",
 *    "view_type_toggle", "buy", "faq", "view_expand_collapse", "cancel", "open_dashboard_webview",
 *    "chat_link_message", "product_offer_card", "place_order", "expense_item", "archive",
 *    "done", "discard_changes", "keep_editing", "undo_approve", "approve_expenses",
 *    "approve_all_expenses", "back", "undo_approve_all", "undo_archive", "finish_later",
 *    "navigation_back", "leave", "navigation_next", "poll", "call_to_action", "next_up_card"
 * @param {string} props.buttonValue Underlying value sent with non-textual buttons. [Optional]
 * For example, list vs. grid toggle in Document Center would send button_value = "list"
 * or button_value = "grid".
 * @param {string} props.pageType Type of page the click is coming from
 */
export const trackButtonPressed = ({
                                     buttonText,
                                     buttonType,
                                     buttonName,
                                     buttonValue,
                                     screenName,
                                     component,
                                     pageType
                                   }) => {
  avo.buttonPressed({
    buttonText,
    buttonType,
    buttonName,
    buttonValue,
    screenName,
    component,
    deviceType: zenIsMobile ? mobileDeviceType : 'desktop',
    pageType
  });
};
