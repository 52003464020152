<template>
  <md-icon v-if="showCopy && showCopied">file_copy</md-icon>
  <md-icon v-else-if="showCopy && !showCopied" @click.native="doCopy">content_copy</md-icon>
</template>

<script>
export default {
  name: 'ClipboardCopy',

  props: ['showCopied', 'showCopy', 'value'],

  methods: {
    doCopy() {
      const that = this;
      this.$copyText(that.value).then(() => {
        that.$emit('changeShowCopied', true);
      }, () => {
        alert('Can not copy');
      });
    }
  }

};
</script>
<style lang="scss" scoped>
.md-icon {
  font-size: 1.1rem !important;
}
</style>
