import manifest from '../../../manifest'

export default {
  getEnvironment: () => {
    return manifest.environment ?? '';
  },
  isDevelopment: () => {
    return manifest.environment?.toLowerCase() !== 'production'
  },
  isProduction: () => {
    return manifest.environment?.toLowerCase() === 'production'
  }
};
