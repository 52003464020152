import router from '@/router';
import { DEFAULT_AFFILIATE } from '@/assets/constants/products';

export default {
  getAccountUuid: (state) => {
    if (state?.account?.account_uuid) {
      return state.account.account_uuid;
    }
    const urlParam = router.currentRoute.params.accountUuid;
    if (urlParam) {
      return urlParam;
    }
    return undefined
  },
  getZbMoneyAccountData: (state) => {
    return state.zbMoneyAccountData
  },
  getAccountEmail: (state) => {
    const { account } = state;
    return account?.account_email;
  },
  getAffiliate: ({ account }) => {
    return account?.entry_point_group;
  },
  getAffiliateOrDefault: ({ account }) => {
    if (account) {
      return account.entry_point_group?account.entry_point_group:DEFAULT_AFFILIATE;
    }
    return null;
  }
};
