export function initialState() {
	return {
		emails: [],
		domains: [],
		website: {},
		websites: [],
	}
}

export const state = {
	...initialState()
};

export default {};
