import { generateSortFunction } from '../utils';

export default {
  setAutomationConfig: (state, config) => {
    state.automationConfig = config;
  },
  setOrders: (state, orders) => {
    // Sort jurisdictions in order
    for (const order of orders) {
      order.jurisdictions.sort();
    }
    state.orders = orders;
  },
  setOrder: (state, order) => {
    state.order = order;
  },
  setOrderTasks: (state, orderTasks) => {
    // Sort order tasks by jurisdiction ASC, service ASC
    const orderTaskSort = generateSortFunction([
      { accessor: (ot) => ot.order_task_jurisdiction, ascending: true },
      { accessor: (ot) => ot.order_task_type, ascending: true }
    ]);
    orderTasks.sort(orderTaskSort);
    state.orderTasks = orderTasks;
    state.isLoadingOrderTasks = false;
  },
  setLoadingOrderTasks: (state, loadingState) => {
    state.isLoadingOrderTasks = loadingState;
  },
  setOrderTask: (state, orderTask) => {
    state.orderTask = orderTask;
  },
  setOrderTracking: (state, orderTracking) => {
    state.orderTracking = orderTracking;
  },
  setBusinessLicenseReport: (state, businessLicenseReport) => {
    state.businessLicenseReport = businessLicenseReport.results[0] || null;
  },
  setTransactionalBillingItems: (state, transactionalBillingItems) => {
    // Sort items by jurisdiction ASC, service ASC
    const billingItemSort = generateSortFunction([
      { accessor: (bi) => bi.jurisdiction, ascending: true },
      { accessor: (bi) => bi.service, ascending: true }
    ]);
    transactionalBillingItems.sort(billingItemSort);
    state.transactionalBillingItems = transactionalBillingItems;
  },
  clearCancelAndRefundReasons: (state) => {
    state.cancelAndRefundReasons = [];
  },
  clearCancelReasons: (state) => {
    state.cancelReasons = [];
  },
  clearRefundReasons: (state) => {
    state.refundReasons = [];
  },
  setSubscriptionBillingItems: (state, subscriptionBillingItems) => {
    // Sort items by jurisdiction ASC, service ASC
    const billingItemSort = generateSortFunction([
      { accessor: (bi) => bi.jurisdiction, ascending: true },
      { accessor: (bi) => bi.service, ascending: true }
    ]);
    subscriptionBillingItems.sort(billingItemSort);
    state.subscriptionBillingItems = subscriptionBillingItems;
  },
  setOrderBillingItemPromotions: (state, orderBillingItems) => {
    state.orderBillingItemPromotions = orderBillingItems;
  },
  setBillingInvoices: (state, billingInvoices) => {
    state.billingInvoices = billingInvoices;
  },
  setCanGenerateFormation: (state, canGenerateFormation) => {
    state.canGenerateFormation = canGenerateFormation;
  },
  setOrderNoteCreatedBy: (state, value) => {
    state.newOrderNote.order_note_created_by = value;
  },
  setOrderNoteContent: (state, value) => {
    state.newOrderNote.order_note_content = value;
  },
  setSOSStatus: (state, status) => {
    state.sosStatus = status;
  },
  setBusinessEntity: (state, businessEntity) => {
    state.businessEntity = businessEntity;
  },
  setRegisteredAgentAddresses: (state, raAddresses) => {
    state.raAddresses = raAddresses;
  },
  setRefunds: (state, refunds) => {
    state.refunds = refunds
  },
  setRefundReasons: (state, refundReasons) => {
    state.refundReasons = refundReasons.sort((a,b) => {
      if (a.primary_reason == b.primary_reason) {
        if (a.secondary_reason == b.secondary_reason) {
          return 0;
        } else {
          return a.secondary_reason < b.secondary_reason ? -1 : 1;
        }
      } else {
        return a.primary_reason < b.primary_reason ? -1 : 1;
      }
    })
  },
  setCancelReasons: (state, cancelReasons) => {
    state.cancelReasons = cancelReasons.sort((a,b) => {
      if (a.primary_reason == b.primary_reason) {
        if (a.secondary_reason == b.secondary_reason) {
          return 0;
        } else {
          return a.secondary_reason < b.secondary_reason ? -1 : 1;
        }
      } else {
        return a.primary_reason < b.primary_reason ? -1 : 1;
      }
    })
  },
  setCancelAndRefundReasons: (state, reasons) => {
    state.cancelAndRefundReasons = reasons.sort((a,b) => {
      if (a.primary_reason == b.primary_reason) {
        if (a.secondary_reason == b.secondary_reason) {
          return 0;
        } else {
          return a.secondary_reason < b.secondary_reason ? -1 : 1;
        }
      } else {
        return a.primary_reason < b.primary_reason ? -1 : 1;
      }
    });
    state.cancelReasons = reasons.filter((reason) => { return reason.is_cancel_reason });
    state.refundReasons = reasons.filter((reason) => { return reason.is_refund_reason });
  },
  setPlans: (state, plans) => {
    state.plans = plans;
  },
  setAutomatedFilingTracking: (state, automatedFilingTracking) => {
    state.automatedFilingTracking = automatedFilingTracking;
  },
  setEligibleDowngradePlans: (state, eligibleDowngradePlans) => {
    state.eligibleDowngradePlans = eligibleDowngradePlans
  },
  setEntityStatusAtSOS: (state, entityStatusAtSOS) => {
    state.entityStatusAtSOS = entityStatusAtSOS;
  },
  clearOrderTask: (state) => {
    state.orderTask = null;
  },
  setReprocessOrderWithoutTasks: (state, value) => {
    state.retryOrderCreation.reprocessOrderWithoutTasks = value;
  },
  setReprocessOrderTasksCreated: (state, value) => {
    state.retryOrderCreation.reprocessOrderTasksCreated = value;
  },
  setReprocessCompletedOrder: (state, value) => {
    state.retryOrderCreation.reprocessCompletedOrder = value;
  },
  setMissedChargeEvents: (state, value) => {
    state.retryOrderCreation.missedChargeEvents = value;
  },
  setBulkSendOrdersToSalesforce: (state, value) => {
    state.retryOrderCreation.bulkSendOrdersToSalesforce = value;
  }
};
