import { initialState } from './state.js'

export const mutations = {
  resetDbaState: (state) => {
    Object.assign(state, initialState());
  },
  setDbas: (state, dbas) => {
    state.dbas = dbas;
  }
};

export default {};
