<template>
  <button type="button" class="zg-btn" @click="$emit('click')" :disabled="!isEnable">
    <slot name="title">
    </slot>
    <transition name="zg-btn-thinking" v-if="isSubmitting" appear>
      <span class="zg-btn__icon">
        <icon-spinner />
      </span>
    </transition>
  </button>
</template>
<script>
import IconSpinner from '@/components/common/images/IconSpinner'
export default {
  name: 'ZgButton',
  props: {
    isEnable: {
      type: Boolean,
      default: true
    },
    isSubmitting: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IconSpinner
  }
}
</script>

<style lang="scss" scoped>
  .zg-btn {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: em(15);
    font-size: 1em;
    border: none;
    border-radius: em(4);
    text-decoration: none;
    font-weight: 700;
    transition: background-color 300ms $easeInOutQuad, opacity 800ms $easeInOutQuad;
    &__icon {
      padding-left: em(5);
      display: inline-block;
      width: 1.5em;
    }
    &-thinking {
      &-enter {
        width: 0em;
        &-active {
          transition: width 400ms $easeOutExpo;
        }
        &-to {
          width: 1.5em;
        }

      }
      &-leave {
        width: 0em;
        &-active {
          transition: width 300ms $easeInExpo;
        }
        &-to {
          width: 0;
        }
      }
    }
    &-default {
      background-color: $clr-primary;
      color: $clr-white;
    }
    &-default:hover {
      background-color: $clr-primary-lt;
    }
    &-default:disabled {
      background-color: $clr-primary-ltr;
    }
    &-light {
      background-color: $clr-white;
      color: $clr-primary;
    }
    &-light:hover {
      color: $clr-primary-lt;
    }
    &-no-bg {
      background: none;
      color: $clr-primary;
    }
    &-no-bg:hover {
      color: $clr-primary-lt;
    }
    &-outline {
      border-style: solid;
      color: $clr-primary;
    }
    &-outline:hover {
      color: $clr-primary-lt;
    }
    &-danger {
      background-color: $clr-danger;
      color: $clr-white;
    }
    &-danger:hover {
      color: $colal-50;
    }
    &-dark {
      background-color: $clr-btn-dark;
      color: $clr-white;
    }
    &-dark:hover {
      background-color: $clr-btn-dark-hover;
    }
    &-info {
      background-color: $clr-primary;
      color: $clr-white;
    }
    &-info:hover {
      background-color: $clr-btn-primary-hover;
    }    
    &-info:disabled {
      color: $clr-font-tertiary;
      background-color: $clr-btn-primary-inactive;
      &:hover {
        background-color: $clr-btn-primary-inactive;
      }
    }
    &-sm {
      margin-bottom: em(5);
      margin-top: em(5);
      padding: em(6);
    }
  }

  .fade {
    &-enter-active, &-leave-active {
      transition: opacity 450ms $easeInOutQuad;
    }
    &-enter, &-leave-to {
      opacity: 0;
    }
  }

  .zg-btn:hover {
    cursor: pointer;
  }
  .zg-btn:disabled,.zg-btn[disabled] {
    cursor: not-allowed;
    color: $clr-font-tertiary;
    background: $clr-btn-ghost;
  }

  .zg-text-sm {
    font-size: 0.75em;
  }

  .zg-text-md {
    font-size: 1em;
  }

  .zg-text-lg {
    font-size: 1.25em;
  }
</style>
