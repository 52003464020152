import httpclient from '../../httpclient';
import {commitResponseToStore, httpRequestErrorHandling, validateParams} from '../utils'
import messages from '../../../assets/messages'
import corpStockFeatureFlag from './state'

export default {
  fetchAgencyLoginAccounts({ commit }) {
    return httpclient.get(`api/v2/fulfillment/agency-login-accounts?page_size=50`)
      .then(commitResponseToStore(commit, 'setAgencyLoginAccounts'))
      .catch((e) => { httpRequestErrorHandling(e, commit) })
  },

  fetchSharedAgencyLoginAccounts({ commit }, { jurisdictionUuid }) {
    return httpclient.get(`api/v3/fulfillment/${jurisdictionUuid}/agency-login-account-sharing`)
      .then(response => {
        commit('fulfillment/setSharedAgencyLoginAccounts', response.data.results, { root: true });
        return response.data.results
      })
      .catch((e) => { httpRequestErrorHandling(e, commit) })
  },
  addOrRemoveSharedAgencyLoginAccounts({ commit }, { jurisdictionUuid, addUuids, removeUuids }) {
    function addSharedAgencyLoginAccounts({ jurisdictionUuid, addUuids }) {
      const listOfPromises = []
      for (let i = 0; i < addUuids.length; i++) {
        const body = {
          agency_login_account_uuid: addUuids[i],
        }
        listOfPromises.push(
          httpclient.post(`api/v3/fulfillment/${jurisdictionUuid}/agency-login-account-sharing`, body)
        );
      }
      return Promise.all(listOfPromises)
    }
    function removeSharedAgencyLoginAccounts({ jurisdictionUuid, removeUuids }) {
      const listOfPromises = []
      for (let i = 0; i < removeUuids.length; i++) {
        const payload = { data: {
          "agency_login_account_uuid": removeUuids[i],
        }}
        listOfPromises.push(
          httpclient.delete(`api/v3/fulfillment/${jurisdictionUuid}/agency-login-account-sharing`, payload)
        );
      }
      return Promise.all(listOfPromises)
    }
    const addPromises = addSharedAgencyLoginAccounts({ jurisdictionUuid, addUuids });
    const removePromises = removeSharedAgencyLoginAccounts({ jurisdictionUuid, removeUuids });
    return Promise.all([addPromises, removePromises])
      .then((values) => {console.log(values);})
      .catch((error) => {
        httpRequestErrorHandling(error, commit);
        return Promise.reject('The update was incomplete');
      })
  },
  fetchOrderTaskFulfillment({ commit }, { order_task_uuid }) {
    return httpclient.get(`/api/v3/fulfillment/${order_task_uuid}/order-task-fulfillment`)
      .then(commitResponseToStore(commit, 'setOrderTaskFulfillment'))
      .catch((e) => {
        if(e.response.status != 404) {
          httpRequestErrorHandling(e, commit)
        }
      })
  },
  fetchCorpStockInformation({ commit }, { order_task_uuid }) {
    if (!corpStockFeatureFlag) {
      return httpclient.get(`/api/v2/fulfillment/${order_task_uuid}/corp-stock-information`)
      .then(commitResponseToStore(commit, 'setCorpStockInformation'))
      .catch((e) => {
        if(e.response.status != 404) {
          httpRequestErrorHandling(e, commit)
        }
      })  
    }
    return httpclient.get(`/api/v3/fulfillment/${order_task_uuid}/corp-stock-information`)
    .then(commitResponseToStore(commit, 'setCorpStockInformation'))
    .catch((e) => {
      if(e.response.status != 404) {
        httpRequestErrorHandling(e, commit)
      }
    })  
  },
  createOrderTaskFulfillment({ commit }, { order_task_uuid, agency_login_account_uuid, user_email }) {
    let payload = {
      agency_login_account_uuid: agency_login_account_uuid,
      is_automation: false,
      updated_by: user_email
    }
    return httpclient.post(`/api/v3/fulfillment/${order_task_uuid}/order-task-fulfillment`, payload)
      .then(commitResponseToStore(commit, 'setOrderTaskFulfillment'))
      .catch((e) => { console.log(e); httpRequestErrorHandling(e, commit) })
  },
  updateOrderTaskFulfillment({ commit }, { order_task_fulfillment_uuid, order_task_uuid, agency_login_account_uuid, user_email }) {
    let payload = {
      order_task_fulfillment_uuid: order_task_fulfillment_uuid,
      agency_login_account_uuid: agency_login_account_uuid,
      is_automation: false,
      updated_by: user_email
    }
    return httpclient.put(`/api/v3/fulfillment/${order_task_uuid}/order-task-fulfillment`, payload)
      .then(commitResponseToStore(commit, 'setOrderTaskFulfillment'))
      .catch((e) => { console.log(e); httpRequestErrorHandling(e, commit) })
  },
  fetchBusinessEntityJurisdictionFulfillment({commit}, {jurisdiction}) {
    if (!validateParams.UUID(jurisdiction['jurisdiction_uuid'])) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    }
    const url = `api/v3/fulfillment/${jurisdiction['jurisdiction_uuid']}/jurisdiction-fulfillment`
    return httpclient.get(url)
      .then(commitResponseToStore(commit, 'setBusinessEntityJurisdictionFulfillment'))
      .catch(() => { })
  },
  updateBusinessEntityJurisdictionFulfillment({commit}, {jurisdiction, jurisdictionFulfillmentUuid, newPin}) {
    if (!validateParams.UUID(jurisdiction['jurisdiction_uuid'])) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    }
    const url = `api/v3/fulfillment/${jurisdiction['jurisdiction_uuid']}/jurisdiction-fulfillment`
    const body = {
      'jurisdiction_fulfillment_uuid': jurisdictionFulfillmentUuid,
      'agency_account_entity_access_pin': newPin
    }
    return httpclient.put(url, body)
      .then(commitResponseToStore(commit, 'setBusinessEntityJurisdictionFulfillment'))
      .catch((e) => { httpRequestErrorHandling(e, commit) })
  },
  createBusinessEntityJurisdictionFulfillment({commit}, {jurisdiction, newPin}) {
    if (!validateParams.UUID(jurisdiction['jurisdiction_uuid'])) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    }
    const url = `api/v3/fulfillment/${jurisdiction['jurisdiction_uuid']}/jurisdiction-fulfillment`
    const body = {
      'agency_account_entity_access_pin': newPin
    }
    return httpclient.post(url, body)
      .then(commitResponseToStore(commit, 'setBusinessEntityJurisdictionFulfillment'))
      .catch((e) => { httpRequestErrorHandling(e, commit) })
  },
  createAgencyLoginAccountList({ commit }, newAgencyLoginAccount) {
    const body = {
      "username": newAgencyLoginAccount.username,
      "password": "password",
      "jurisdiction_definition": "US_CA",
      "agency_type": 'sos',
      "url": "sos.com"
    }
    return httpclient.post(`/api/v2/fulfillment/agency-login-accounts`, body)
      .then((response) => { console.log(response) })
      .catch((e) => { httpRequestErrorHandling(e, commit) })
  },
  fetchFulfillmentDataValidation({commit}, order_task_uuid) {
    if (!validateParams.UUID(order_task_uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    }
    const url = `api/v3/fulfillment/${order_task_uuid}/fulfillment-data-validation`
    return httpclient.get(url)
      .then(commitResponseToStore(commit, 'setFulfillmentData'))
      .catch(() => { })
  }
}

