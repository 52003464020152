export default {
  expireToken: (state) => {
    state.tokenExpired = true;
  },
  resetToken: (state) => {
    state.tokenExpired = false;
  },
  setAlert: (state, bool) => {
    state.successAlert.status = bool;
  },
  setAlertMessage: (state, messages) => {
    state.successAlert.status = true;
    state.successAlert.primary = messages.primary;
    state.successAlert.secondary = messages.secondary;
    state.successAlert.type = messages.type;
  }
};
