import moment from "moment";

const getters = {
  experianSubscriber(state) {
    return state.personalCreditMonitoringData?.experian_subscriber_id
      ? state.personalCreditMonitoringData?.experian_subscriber_id
      : "N/A";
  },
  answeredQuestions(state) {
    return state.personalCreditMonitoringData?.experian_subscriber_id
      ? state.personalCreditMonitoringData?.answeredQuestions
        ? "Complete"
        : "Incomplete"
      : "N/A";
  },
  lockedOutAt(state) {
    return state.personalCreditMonitoringData?.locked_out_at
      ? moment(state.personalCreditMonitoringData?.locked_out_at).format(
          "MMMM DD, YYYY - h:mm:ss A"
        )
      : "N/A";
  },
};

export default getters;
