export default {
  getBusinessEntityJurisdictionFulfillment: (state) => (jurisdictionUuid) => {
    return state.businessEntityJurisdictionFulfillment[jurisdictionUuid] || null
  },
  getAllAgencyLoginAccounts: (state) => (agencyType) => {
    return state.agencyLoginAccounts?.reduce( (acc, curr) => {
      if (curr?.agency_type === agencyType) {
        const obj = {
          username: curr.username,
          uuid: curr.uuid
        }
        acc.push(obj)
      }
      return acc
    }, []) ?? null
  },
  getSharedAgencyLoginAccounts: (state) => {
    return state.sharedAgencyLoginAccounts
  },
  getCCorpStockInformation: (state) => {
    return state.ccorpStockInformationFulfillment;
  },
  getOrderTaskFulfillment: (state) => {
    return state.orderTaskFulfillment;
  },
}
