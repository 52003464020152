export function initialState() {
  return {
    customerAssets: [],
    migrationOptionsCustomerAssets: [],
    migrationPreferenceCustomerAssets: undefined,
    zbMoneyAccountData: {}
  }
}

export const state = {
	...initialState()
};

export default {};
