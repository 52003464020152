export default {
  setResults: (state, results) => {
    state.results = results;
  },
  appendResults: (state, results) => {
    state.results = state.results.concat(results);
  },
  setNext: (state, page) => {
    state.next = page;
  },
  clearResults: (state) => {
    state.results = undefined;
  },
  clearNext: (state) => {
    state.next = undefined;
  },
};
