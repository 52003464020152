// Generated by Avo VERSION 114.95.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* eslint-disable */

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function() {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function(obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift();
        return {done: value === undefined, value: value}
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function(name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function() {
    var items = [];
    this.forEach(function(value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function() {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function(body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function() {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      };
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);
  }

  Request.prototype.clone = function() {
    return new Request(this, {body: this._bodyInit})
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function(bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''});
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  };

  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function(message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, options));
      };

      xhr.onerror = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function() {
        reject(new self.DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function(value, name) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function() {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})();

(function(exports) {
  var __AVO_DEV__ = false;
  var __AVO_NOOP__ = false;
  var __AVO_LOGGER__ = null;
  var __AVO_ENV__ = null;
  var __REPORT_FAILURE_AS__ = null;
  var __STRICT__ = null;

  var __WEB_DEBUGGER__ = true;
  const avoInspectorApiKey = "5vuiZFutMlXyEw9yZfmr";
  var __INSPECTOR__ = null;

  // polyfill Array.isArray
  if (!Array.isArray) {
    Array.isArray = function(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  // polyfill Object.assign
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
          ],
          dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [], prop, i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    }());
  }

  // polyfill Array.indexOf
  if (!Array.prototype.indexOf)  Array.prototype.indexOf = (function(Object, max, min){
    "use strict";
    return function indexOf(member, fromIndex) {
      if(this===null||this===undefined)throw TypeError("Array.prototype.indexOf called on null or undefined");

      var that = Object(this), Len = that.length >>> 0, i = min(fromIndex | 0, Len);
      if (i < 0) i = max(0, Len+i); else if (i >= Len) return -1;

      if(member===void 0){ for(; i !== Len; ++i) if(that[i]===void 0 && i in that) return i; // undefined
      }else if(member !== member){   for(; i !== Len; ++i) if(that[i] !== that[i]) return i; // NaN
      }else                           for(; i !== Len; ++i) if(that[i] === member) return i; // all else

      return -1; // if the value was not found, then return -1
    };
  })(Object, Math.max, Math.min);

  var array_difference, AvoAssert, InternalAvoLogger;
  array_difference = function array_difference(a1, a2) {
    var result = [];
    for (var i = 0; i < a1.length; i++) {
      if (a2.indexOf(a1[i]) === -1) {
        result.push(a1[i]);
      }
    }
    return result;
  }

  AvoAssert = {
    assertObject: function assertObject(propertyId, propName, obj) {
      if (typeof obj !== 'object') {
        var message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
        return [{tag: 'expectedObjectType', propertyId: propertyId, message: message, actualType: typeof obj}];
      } else {
        return [];
      }
    },

    assertString: function assertString(propertyId, propName, str) {
      if (typeof str !== 'string') {
        var message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
        return [{tag: 'expectedStringType', propertyId: propertyId, message: message, actualType: typeof str}];
      } else {
        return [];
      }
    },

    assertInt: function assertInt(propertyId, propName, int) {
      if (typeof int === 'number' && int !== Math.round(int)) {
        var message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof int !== 'number') {
        var message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, message: message, actualType: typeof int}];
      } else {
        return [];
      }
    },

    assertLong: function assertLong(propertyId, propName, long) {
      if (typeof long === 'number' && long !== Math.round(long)) {
        var message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof long !== 'number') {
        var message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, message: message, actualType: typeof long}];
      } else {
        return [];
      }
    },

    assertFloat: function assertFloat(propertyId, propName, float) {
      if (typeof float !== 'number') {
        var message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
        return [{tag: 'expectedFloatType', propertyId: propertyId, message: message, actualType: typeof float}];
      } else {
        return [];
      }
    },

    assertBool: function assertBool(propertyId, propName, bool) {
      if (typeof bool !== 'boolean') {
        var message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
        return [{tag: 'expectedBoolType', propertyId: propertyId, message: message, actualType: typeof bool}];
      } else {
        return [];
      }
    },

    assertMax: function assertMax(propertyId, propName, max, value) {
      if (value > max) {
        var message = propName +
          ' has a maximum value of ' +
          max +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMax', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertMin: function assertMin(propertyId, propName, min, value) {
      if (value < min) {
        var message = propName +
          ' has a minimum value of ' +
          min +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMin', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertList: function assertList(propertyId, propName, value) {
      if (!Array.isArray(value)) {
        var message = propName + ' should be of type list but you provided type ' + typeof value;
        return [{tag: 'expectedList', propertyId: propertyId, message: message, actualType: typeof value}];
      } else {
        return [];
      }
    },

    assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    },

    assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    }
  };

    const WebDebuggerAssertions = {
      hasKey(obj, key) {
        return obj && obj[key] != null;
      },
      assertKey(obj, key, assert) {
        if (this.hasKey(obj, key) && assert(obj[key])) {
          return true;
        } else {
          throw new Error('WebDebugger: Object key: "' + key + '" failed assertion.')
        }
      },
      assertKeys(obj, arrayOfAssertions) {
        return arrayOfAssertions.every(assertionForKey => {
          if (this.hasKey(assertionForKey, "key") && this.hasKey(assertionForKey, "assert")) {
            return this.assertKey(obj, assertionForKey.key, assertionForKey.assert);
          } else {
            throw new Error('WebDebugger: Object form for assertion invalid.');
          }
        });
      },
      assertInt(int) {
        return Number.isInteger(int);
      },
    };

    var WebDebuggerPosition = {
      position: {
        BOTTOM_RIGHT: 'BottomRight',
        BOTTOM_LEFT: 'BottomLeft',
        TOP_RIGHT: 'TopRight',
        TOP_LEFT: 'TopLeft',
      },
      BottomRight(parameters) {
        return WebDebuggerAssertions.assertKeys(
          parameters,
          [
            { key: "bottom", assert: WebDebuggerAssertions.assertInt },
            { key: "right", assert: WebDebuggerAssertions.assertInt },
          ],
        ) && {
          position: this.position.BOTTOM_RIGHT,
          bottom: parameters.bottom,
          right: parameters.right,
        };
      },
      BottomLeft(parameters) {
        return WebDebuggerAssertions.assertKeys(
          parameters,
          [
            { key: "bottom", assert: WebDebuggerAssertions.assertInt },
            { key: "left", assert: WebDebuggerAssertions.assertInt },
          ],
        ) && {
          position: this.position.BOTTOM_LEFT,
          bottom: parameters.bottom,
          left: parameters.left,
        };
      },
      TopRight(parameters) {
        return WebDebuggerAssertions.assertKeys(
          parameters,
          [
            { key: "top", assert: WebDebuggerAssertions.assertInt },
            { key: "right", assert: WebDebuggerAssertions.assertInt },
          ],
        ) && {
          position: this.position.TOP_RIGHT,
          top: parameters.top,
          right: parameters.right,
        };
      },
      TopLeft(parameters) {
        return WebDebuggerAssertions.assertKeys(
          parameters,
          [
            { key: "top", assert: WebDebuggerAssertions.assertInt },
            { key: "left", assert: WebDebuggerAssertions.assertInt },
          ],
        ) && {
          position: this.position.TOP_LEFT,
          top: parameters.top,
          left: parameters.left,
        };
      },
    }


  InternalAvoLogger = {
    logEventSent: function logEventSent(eventName, eventProperties, userProperties, groupProperties) {
      const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties) + "Group Props:" + JSON.stringify(groupProperties);

      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties, "Group Props:", groupProperties);
    },

    log: function log(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] " + message);
    },

    warn: function warn(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.warn("[avo] " + message);
    },

    error: function error(message, error) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
        return
      }
      typeof console !== 'undefined' && console.error("[avo] " + message, error);
    }
  };

  var _avo_invoke, _avo_invoke_meta;
var _avo_sampling_rate = 1.0;
function _avo_invoke_payload(body) {
  if (typeof window === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      }).then(function(res) { return res.json(); }).then(function(data) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
  _avo_invoke_payload({
    "ac": "gy3kdOg0lJFO8aLUQ8fo",
    "br": "master",
    "en": env,
    "ev": eventId,
    "ha": hash,
    "sc": "5VWPHSaRaWcJAxzqYkj4",
    "se": (new Date()).toISOString(),
    "so": "eOheVbWjQ",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}

_avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
  _avo_invoke_payload({
    "ac": "gy3kdOg0lJFO8aLUQ8fo",
    "br": "master",
    "en": env,
    "ty": type,
    "sc": "5VWPHSaRaWcJAxzqYkj4",
    "se": (new Date()).toISOString(),
    "so": "eOheVbWjQ",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}


  var _avo_debugger_log;
var _avo_debugger_events_during_boot = [];
var _avo_debugger_ready = false;

if (typeof window !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    var iframe = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      var message = {
        type_: "avo-debugger-boot-events",
        schemaId: "5VWPHSaRaWcJAxzqYkj4",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties, groupProperties) {
  if (typeof window === 'undefined') { return; }
  var event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties: eventProperties,
    userProperties: userProperties,
    groupProperties: groupProperties
  };

  if (_avo_debugger_ready) {
    var message = {type_: "avo-debugger-events", events: [event]};
    document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


  var ScreenName = {
    SIGN_IN: "sign_in",
    FORGOT_PASSWORD: "forgot_password",
    PASSWORD_RESET: "password_reset",
    UPGRADE: "upgrade",
    ENTITY_UNSUPPORTED: "entity_unsupported",
    ENTITY_NOT_FOUND: "entity_not_found",
    CHAT: "chat",
    FORMATION_TRACKER: "formation_tracker",
    ACTION_MENU: "action_menu",
    ONBOARDING: "onboarding",
    ONBOARDING_TRANSITION: "onboarding_transition",
    USER_AGREEMENT: "user_agreement",
    DOCUMENT_FILE_SHARING: "document_file_sharing",
    DOCUMENT_VIEWER: "document_viewer",
    TEMPLATE_VIEWER: "template_viewer",
    DOCUMENT_LIBRARY: "document_library",
    TEMPLATE_LIBRARY: "template_library",
    TEMPLATE_FILE_SHARING: "template_file_sharing",
    PRODUCT_SUITE: "product_suite",
    NO_DOCUMENTS: "no_documents",
    TEMPLATES_LOCKED: "templates_locked",
    PRODUCT_CHECKOUT_FAQ: "product_checkout_faq",
    PRODUCT_CHECKOUT: "product_checkout",
    EXPENSE_DETAIL_EDIT: "expense_detail_edit",
    NO_EXPENSE_CATEGORIZATION: "no_expense_categorization",
    EXPENSE_CATEGORIZATION: "expense_categorization",
    EXPENSE_EDIT_CATEGORIZATION: "expense_edit_categorization",
    EXPENSES_LIST: "expenses_list",
    JOURNEY_LESSON: "journey_lesson",
    JOURNEY_INTRO_HUB: "journey_intro_hub",
    JOURNEY_LESSON_HUB: "journey_lesson_hub",
    JOURNEY_LESSON_REVIEW: "journey_lesson_review",
    EDU_STORY: "edu_story",
    DASHBOARD_HOMEPAGE: "dashboard_homepage",
    TAB_NAVIGATION: "tab_navigation",
    MENU_TAB: "menu_tab",
    MENU: "menu",
    ACTIVE_SUBSCRIPTION_LIST: "active_subscription_list",
    NO_ACTIVE_SUBSCRIPTIONS: "no_active_subscriptions",
    PRODUCT_DETAIL: "product_detail",
    INELIGIBLE_EXPENSES: "ineligible_expenses",
    USER_MANAGEMENT: "user_management",
  };

  var ButtonType = {
    ACTION: "action",
    ICON: "icon",
    PRIMARY: "primary",
    SECONDARY: "secondary",
    TERTIARY: "tertiary",
    LINK_MESSAGE: "link_message",
    DROPDOWN_ITEM: "dropdown_item",
    TOGGLE: "toggle",
    MEDIUM: "medium",
    SMALL: "small",
    SLIDER: "slider",
    CARD: "card",
    LIST_ITEM: "list_item",
    POLL_OPTION: "poll_option",
    TAB_ITEM: "tab_item",
    CALL_TO_ACTION: "call_to_action",
    ITEM_LIST: "item_list",
  };

  var Component = {
    LOGIN: "login",
    HOME: "home",
    ONBOARDING: "onboarding",
    MAIN_CHAT: "main_chat",
    USER_AGREEMENTS: "user_agreements",
    WEB: "web",
    ROOT: "root",
    DOCUMENT_CENTER: "document_center",
    PRODUCT_SUITE: "product_suite",
    CHECKOUT: "checkout",
    EXPENSES: "expenses",
    J2S: "j2s",
    EDU: "edu",
    TAB_NAVIGATION: "tab_navigation",
    MENU: "menu",
    SUBSCRIPTION_MANAGEMENT: "subscription_management",
    ACCOUNT_PAGE: "account_page",
  };

  var ButtonName = {
    ACTION_MENU: "action_menu",
    ACTION_MENU_ITEM: "action_menu_item",
    SETTINGS: "settings",
    SIGN_IN: "sign_in",
    FORGOT_PASSWORD: "forgot_password",
    FORCE_UPGRADE_UPDATE: "force_upgrade_update",
    RESET_PASSWORD: "reset_password",
    LOGOUT: "logout",
    CONTINUE: "continue",
    BACK_TO_LOGIN: "back_to_login",
    FORMATION_CARD_ACTION: "formation_card_action",
    BUSINESS_ENTITY_SELECTOR_ITEM: "business_entity_selector_item",
    SORT_ITEM: "sort_item",
    FILTER_ITEM: "filter_item",
    VIEW_TYPE_TOGGLE: "view_type_toggle",
    FAQ: "faq",
    VIEW_EXPAND_COLLAPSE: "view_expand_collapse",
    CANCEL: "cancel",
    OPEN_DASHBOARD_WEBVIEW: "open_dashboard_webview",
    CHAT_LINK_MESSAGE: "chat_link_message",
    PRODUCT_OFFER_CARD: "product_offer_card",
    PLACE_ORDER: "place_order",
    EXPENSE_ITEM: "expense_item",
    ARCHIVE: "archive",
    DONE: "done",
    DISCARD_CHANGES: "discard_changes",
    KEEP_EDITING: "keep_editing",
    UNDO_APPROVE: "undo_approve",
    APPROVE_EXPENSES: "approve_expenses",
    APPROVE_ALL_EXPENSES: "approve_all_expenses",
    BACK: "back",
    UNDO_APPROVE_ALL: "undo_approve_all",
    UNDO_ARCHIVE: "undo_archive",
    FINISH_LATER: "finish_later",
    NAVIGATION_BACK: "navigation_back",
    LEAVE: "leave",
    NAVIGATION_NEXT: "navigation_next",
    POLL: "poll",
    CALL_TO_ACTION: "call_to_action",
    NEXT_UP_CARD: "next_up_card",
    CHAT_TAB: "chat_tab",
    EXPENSES_TAB: "expenses_tab",
    DOCUMENTS_TAB: "documents_tab",
    MENU_TAB: "menu_tab",
    DISMISS_PROMPT: "dismiss_prompt",
    MENU_ITEM: "menu_item",
    REVIEW_EXPENSES: "review_expenses",
    LEARN_MORE: "learn_more",
    SUBSCRIBE: "subscribe",
    CTA: "cta",
    START_NOW: "start_now",
    EDUCATION_TAB: "education_tab",
    PRODUCT_DETAILS: "product_details",
    DOCUMENT_VIEW: "document_view",
    SEARCH: "search",
    FAVORITE: "favorite",
    FILTER: "filter",
    PRODUCT_CTA: "product_cta",
    RESET_MFA: "reset_mfa",
    ENABLE_MFA: "enable_mfa",
    DISABLE_MFA: "disable_mfa",
  };

  function assertOptionalDestination(destination, label_) {
    var messages = [];
    if (destination !== undefined && destination !== null) {
      messages = messages.concat(AvoAssert.assertString("0ZjM39aRO7", label_ ? "destination" + ": " + label_ : "destination", destination));
    }
    return messages;
  }

  function assertDeviceType(deviceType, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("3dB3qQvbL", label_ ? "device_type" + ": " + label_ : "device_type", deviceType));
    return messages;
  }

  function assertOptionalScreenDetails(screenDetails, label_) {
    var messages = [];
    if (screenDetails !== undefined && screenDetails !== null) {
      messages = messages.concat(AvoAssert.assertString("3gyTDWP8y", label_ ? "screen_details" + ": " + label_ : "screen_details", screenDetails));
    }
    return messages;
  }

  function assertScreenName(screenName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("7-hb0S6NBb", label_ ? "screen_name" + ": " + label_ : "screen_name", screenName));
    if ("sign_in" !== screenName && "forgot_password" !== screenName &&
          "password_reset" !== screenName && "upgrade" !== screenName &&
          "entity_unsupported" !== screenName &&
          "entity_not_found" !== screenName && "chat" !== screenName &&
          "formation_tracker" !== screenName &&
          "action_menu" !== screenName && "onboarding" !== screenName &&
          "onboarding_transition" !== screenName &&
          "user_agreement" !== screenName &&
          "document_file_sharing" !== screenName &&
          "document_viewer" !== screenName &&
          "template_viewer" !== screenName &&
          "document_library" !== screenName &&
          "template_library" !== screenName &&
          "template_file_sharing" !== screenName &&
          "product_suite" !== screenName && "no_documents" !== screenName &&
          "templates_locked" !== screenName &&
          "product_checkout_faq" !== screenName &&
          "product_checkout" !== screenName &&
          "expense_detail_edit" !== screenName &&
          "no_expense_categorization" !== screenName &&
          "expense_categorization" !== screenName &&
          "expense_edit_categorization" !== screenName &&
          "expenses_list" !== screenName &&
          "journey_lesson" !== screenName &&
          "journey_intro_hub" !== screenName &&
          "journey_lesson_hub" !== screenName &&
          "journey_lesson_review" !== screenName &&
          "edu_story" !== screenName &&
          "dashboard_homepage" !== screenName &&
          "tab_navigation" !== screenName && "menu_tab" !== screenName &&
          "menu" !== screenName &&
          "active_subscription_list" !== screenName &&
          "no_active_subscriptions" !== screenName &&
          "product_detail" !== screenName &&
          "ineligible_expenses" !== screenName &&
          "user_management" !== screenName) {
      var message = (label_ ? "screen_name" + ": " + label_ : "screen_name") + " should match one of the following values [ sign_in | forgot_password | password_reset | upgrade | entity_unsupported | entity_not_found | chat | formation_tracker | action_menu | onboarding | onboarding_transition | user_agreement | document_file_sharing | document_viewer | template_viewer | document_library | template_library | template_file_sharing | product_suite | no_documents | templates_locked | product_checkout_faq | product_checkout | expense_detail_edit | no_expense_categorization | expense_categorization | expense_edit_categorization | expenses_list | journey_lesson | journey_intro_hub | journey_lesson_hub | journey_lesson_review | edu_story | dashboard_homepage | tab_navigation | menu_tab | menu | active_subscription_list | no_active_subscriptions | product_detail | ineligible_expenses | user_management ] but you provided the value " + screenName
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "7-hb0S6NBb", message: message}]);
    }
    return messages;
  }

  function assertOptionalButtonValue(buttonValue, label_) {
    var messages = [];
    if (buttonValue !== undefined && buttonValue !== null) {
      messages = messages.concat(AvoAssert.assertString("80_ryv9tLt", label_ ? "button_value" + ": " + label_ : "button_value", buttonValue));
    }
    return messages;
  }

  function assertButtonType(buttonType, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("T3YH0BL9-n", label_ ? "button_type" + ": " + label_ : "button_type", buttonType));
    if ("action" !== buttonType && "icon" !== buttonType &&
          "primary" !== buttonType && "secondary" !== buttonType &&
          "tertiary" !== buttonType && "link_message" !== buttonType &&
          "dropdown_item" !== buttonType && "toggle" !== buttonType &&
          "medium" !== buttonType && "small" !== buttonType &&
          "slider" !== buttonType && "card" !== buttonType &&
          "list_item" !== buttonType && "poll_option" !== buttonType &&
          "tab_item" !== buttonType && "call_to_action" !== buttonType &&
          "item_list" !== buttonType) {
      var message = (label_ ? "button_type" + ": " + label_ : "button_type") + " should match one of the following values [ action | icon | primary | secondary | tertiary | link_message | dropdown_item | toggle | medium | small | slider | card | list_item | poll_option | tab_item | call_to_action | item_list ] but you provided the value " + buttonType
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "T3YH0BL9-n", message: message}]);
    }
    return messages;
  }

  function assertOptionalButtonText(buttonText, label_) {
    var messages = [];
    if (buttonText !== undefined && buttonText !== null) {
      messages = messages.concat(AvoAssert.assertString("Uo6pFY11e3", label_ ? "button_text" + ": " + label_ : "button_text", buttonText));
    }
    return messages;
  }

  function assertComponent(component, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("cMzkv_Qm1Q", label_ ? "component" + ": " + label_ : "component", component));
    if ("login" !== component && "home" !== component &&
          "onboarding" !== component && "main_chat" !== component &&
          "user_agreements" !== component && "web" !== component &&
          "root" !== component && "document_center" !== component &&
          "product_suite" !== component && "checkout" !== component &&
          "expenses" !== component && "j2s" !== component &&
          "edu" !== component && "tab_navigation" !== component &&
          "menu" !== component && "subscription_management" !== component &&
          "account_page" !== component) {
      var message = (label_ ? "component" + ": " + label_ : "component") + " should match one of the following values [ login | home | onboarding | main_chat | user_agreements | web | root | document_center | product_suite | checkout | expenses | j2s | edu | tab_navigation | menu | subscription_management | account_page ] but you provided the value " + component
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "cMzkv_Qm1Q", message: message}]);
    }
    return messages;
  }

  function assertPageType(pageType, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("l8x9M6i2L", label_ ? "page_type" + ": " + label_ : "page_type", pageType));
    return messages;
  }

  function assertOptionalExperimentName(experimentName, label_) {
    var messages = [];
    if (experimentName !== undefined && experimentName !== null) {
      messages = messages.concat(AvoAssert.assertString("qr1EucD2s", label_ ? "experiment_name" + ": " + label_ : "experiment_name", experimentName));
    }
    return messages;
  }

  function assertButtonName(buttonName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("rfMghFZJ2R", label_ ? "button_name" + ": " + label_ : "button_name", buttonName));
    if ("action_menu" !== buttonName && "action_menu_item" !== buttonName &&
          "settings" !== buttonName && "sign_in" !== buttonName &&
          "forgot_password" !== buttonName &&
          "force_upgrade_update" !== buttonName &&
          "reset_password" !== buttonName && "logout" !== buttonName &&
          "continue" !== buttonName && "back_to_login" !== buttonName &&
          "formation_card_action" !== buttonName &&
          "business_entity_selector_item" !== buttonName &&
          "sort_item" !== buttonName && "filter_item" !== buttonName &&
          "view_type_toggle" !== buttonName && "faq" !== buttonName &&
          "view_expand_collapse" !== buttonName && "cancel" !== buttonName &&
          "open_dashboard_webview" !== buttonName &&
          "chat_link_message" !== buttonName &&
          "product_offer_card" !== buttonName &&
          "place_order" !== buttonName && "expense_item" !== buttonName &&
          "archive" !== buttonName && "done" !== buttonName &&
          "discard_changes" !== buttonName &&
          "keep_editing" !== buttonName && "undo_approve" !== buttonName &&
          "approve_expenses" !== buttonName &&
          "approve_all_expenses" !== buttonName && "back" !== buttonName &&
          "undo_approve_all" !== buttonName &&
          "undo_archive" !== buttonName && "finish_later" !== buttonName &&
          "navigation_back" !== buttonName && "leave" !== buttonName &&
          "navigation_next" !== buttonName && "poll" !== buttonName &&
          "call_to_action" !== buttonName && "next_up_card" !== buttonName &&
          "chat_tab" !== buttonName && "expenses_tab" !== buttonName &&
          "documents_tab" !== buttonName && "menu_tab" !== buttonName &&
          "dismiss_prompt" !== buttonName && "menu_item" !== buttonName &&
          "review_expenses" !== buttonName && "learn_more" !== buttonName &&
          "subscribe" !== buttonName && "cta" !== buttonName &&
          "start_now" !== buttonName && "education_tab" !== buttonName &&
          "product_details" !== buttonName &&
          "document_view" !== buttonName && "search" !== buttonName &&
          "favorite" !== buttonName && "filter" !== buttonName &&
          "product_cta" !== buttonName && "reset_mfa" !== buttonName &&
          "enable_mfa" !== buttonName && "disable_mfa" !== buttonName) {
      var message = (label_ ? "button_name" + ": " + label_ : "button_name") + " should match one of the following values [ action_menu | action_menu_item | settings | sign_in | forgot_password | force_upgrade_update | reset_password | logout | continue | back_to_login | formation_card_action | business_entity_selector_item | sort_item | filter_item | view_type_toggle | faq | view_expand_collapse | cancel | open_dashboard_webview | chat_link_message | product_offer_card | place_order | expense_item | archive | done | discard_changes | keep_editing | undo_approve | approve_expenses | approve_all_expenses | back | undo_approve_all | undo_archive | finish_later | navigation_back | leave | navigation_next | poll | call_to_action | next_up_card | chat_tab | expenses_tab | documents_tab | menu_tab | dismiss_prompt | menu_item | review_expenses | learn_more | subscribe | cta | start_now | education_tab | product_details | document_view | search | favorite | filter | product_cta | reset_mfa | enable_mfa | disable_mfa ] but you provided the value " + buttonName
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "rfMghFZJ2R", message: message}]);
    }
    return messages;
  }

  function assertOptionalExperimentVariation(experimentVariation, label_) {
    var messages = [];
    if (experimentVariation !== undefined && experimentVariation !== null) {
      messages = messages.concat(AvoAssert.assertString("uqmNDBGLL", label_ ? "experiment_variation" + ": " + label_ : "experiment_variation", experimentVariation));
    }
    return messages;
  }

  function setAvoLogger(avoLogger) {
    __AVO_LOGGER__ = avoLogger;
  }


  var RudderStackNewEvents;
  function setup_(options, systemProperties, RudderStackNewEventsDestination,
    destinationOptions) {
    __WEB_DEBUGGER__ = false;
    if (options.validateProperties === true) {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (!__AVO_NOOP__) {
      if (options.useProductionKey) {
      } else {
      }

      RudderStackNewEvents = RudderStackNewEventsDestination;
      RudderStackNewEvents.make(options.useProductionKey);
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'setup', [], 'setup');
      }
    }
  }

  function initAvo(options, systemProperties, destinationOptions,
    RudderStackNewEventsDestination) {
    if (__AVO_ENV__ !== null) {
      return;
    }
    __AVO_ENV__ = options.env.toLowerCase();
    if (options.avoLogger !== undefined) {
      __AVO_LOGGER__ = options.avoLogger;
    }
    if (options.noop === true) {
      __AVO_NOOP__ = true;
    }
    if (__AVO_NOOP__ && __AVO_ENV__ == 'prod') {
      InternalAvoLogger.warn("****************************************************");
      InternalAvoLogger.warn("WARNING Avo cannot be initialized in noop mode in production:");
      InternalAvoLogger.warn("- Overwriting configuration with noop=false.");
      InternalAvoLogger.warn("- Please reach out if you want to be able to run Avo in production mode with noop=true");
      InternalAvoLogger.warn("****************************************************");
      __AVO_NOOP__ = false;
    }
    if (__AVO_NOOP__) {
      InternalAvoLogger.log("****************************************************");
      InternalAvoLogger.log("Avo is now initialized in noop mode. This means:");
      InternalAvoLogger.log("- No events will be sent");
      InternalAvoLogger.log("- No network requests are made");
      InternalAvoLogger.log("****************************************************");
    }
    if (options.strict !== undefined) {
      __STRICT__ = options.strict !== false;
    }
    if (options.reportFailureAs !== undefined) {
      __REPORT_FAILURE_AS__ = options.reportFailureAs;
    }
    __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && window.location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== 'prod'));
    if (!__AVO_NOOP__ && options.inspector !== undefined) {
      __INSPECTOR__ = options.inspector;
    } else if (__AVO_ENV__ !== 'prod') {
      InternalAvoLogger.warn("Avo Inspector not provided in initAvo() call");
    }
    if (__AVO_ENV__ !== 'prod') {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions && options.webDebuggerOptions.position) {
      const {
        BOTTOM_RIGHT,
        BOTTOM_LEFT,
        TOP_RIGHT,
        TOP_LEFT,
        } =
        WebDebuggerPosition.position;

      try {
        WebDebuggerAssertions.assertKey(
          options.webDebuggerOptions.position,
          "position",
          position => {
            return position === BOTTOM_RIGHT ||
            position === BOTTOM_LEFT ||
            position === TOP_RIGHT ||
            position === TOP_LEFT;
          }
        )
      } catch (e) {
        throw new Error('WebDebugger: Position object incorrect. Use methods from WebDebuggerPosition.');
      }

      const top = options.webDebuggerOptions.position.top || 0;
      const right = options.webDebuggerOptions.position.right || 0;
      const bottom = options.webDebuggerOptions.position.bottom || 0;
      const left = options.webDebuggerOptions.position.left || 0;

      let positionParams;

      switch (options.webDebuggerOptions.position.position) {
        case BOTTOM_RIGHT:
          positionParams = {
            position: BOTTOM_RIGHT,
            bottom,
            right,
          };
          break;
        case BOTTOM_LEFT:
          positionParams = {
            position: BOTTOM_LEFT,
            bottom,
            left,
          };
          break;
        case TOP_RIGHT:
          positionParams = {
            position: TOP_RIGHT,
            top,
            right,
          };
          break;
        case TOP_LEFT:
          positionParams = {
            position: TOP_LEFT,
            top,
            left,
          };
          break;
        default: throw new Error('WebDebugger: Position object incorrect. Use methods from WebDebuggerPosition.');
      }

      _avo_debugger_send_position(positionParams);
    }

(function() {
  if (typeof window === 'undefined') { return; }
  var init = function() {
    if (document.getElementById("avo-debugger") === null) {
      var iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      iframe.id = "avo-debugger";
      iframe.src = "https://www.avo.app/_debugger";
      iframe.style = "display: none;";
    }
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

    }
    if (!__AVO_NOOP__) {
      if (__AVO_ENV__ === 'prod') {
      }
      if (__AVO_ENV__ === 'dev') {
      }
      if (__AVO_ENV__ === 'staging') {
      }

      RudderStackNewEvents = RudderStackNewEventsDestination;
      if (__AVO_ENV__ === 'prod') {
        RudderStackNewEvents.make && RudderStackNewEvents.make(__AVO_ENV__, null);
      } else if (__AVO_ENV__ === 'dev') {
        RudderStackNewEvents.make && RudderStackNewEvents.make(__AVO_ENV__, "20BaCUnKMa4rNOkARNad9WW1fpm");
      } else {
        RudderStackNewEvents.make && RudderStackNewEvents.make(__AVO_ENV__, null);
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
      }
    }
  }

  /**
   * button_pressed: This event will fire when a button is pressed.
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} [properties.buttonText] - The text that is displayed on a button.
   * @param {string} properties.buttonType - The type of button that is tapped in an app_button_tapped alert.
   * @param {string} properties.buttonName - The name of a button that is tapped.
   * @param {string} [properties.buttonValue] - Underlying value sent with non-textual buttons. For example, list vs. grid toggle in Document Center would send button_value = "list" or button_value = "grid".
   * @param {string} properties.screenName - The name of the screen that triggers the event.
   * @param {string} properties.component - Property that describes the high-level component that the current event is happening in.
   * @param {string} [properties.screenDetails] - Any extra screen details, e.g. [storyname}_branch_1 [optional]
   * @param {string} [properties.destination] - A url destination triggered from a link tap or a button tap.
   * @param {string} [properties.experimentName] - Test name as defined by LaunchDarkly for identifying tests.
   * @param {string} [properties.experimentVariation] - Variation of LaunchDarkly test
   * @param {string} properties.deviceType - Device the user accessed zen from
   * @param {string} properties.pageType - Type of page the click is coming from
   *
   * @see {@link https://www.avo.app/schemas/5VWPHSaRaWcJAxzqYkj4/events/VOzldlf3Ji}
   */
  function buttonPressed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertOptionalButtonText(properties.buttonText));
      messages = messages.concat(assertButtonType(properties.buttonType));
      messages = messages.concat(assertButtonName(properties.buttonName));
      messages = messages.concat(assertOptionalButtonValue(properties.buttonValue));
      messages = messages.concat(assertScreenName(properties.screenName));
      messages = messages.concat(assertComponent(properties.component));
      messages = messages.concat(assertOptionalScreenDetails(properties.screenDetails));
      messages = messages.concat(assertOptionalDestination(properties.destination));
      messages = messages.concat(assertOptionalExperimentName(properties.experimentName));
      messages = messages.concat(assertOptionalExperimentVariation(properties.experimentVariation));
      messages = messages.concat(assertDeviceType(properties.deviceType));
      messages = messages.concat(assertPageType(properties.pageType));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("button_pressed", Object.keys(properties), [
        "buttonText",
        "buttonType",
        "buttonName",
        "buttonValue",
        "screenName",
        "component",
        "screenDetails",
        "destination",
        "experimentName",
        "experimentVariation",
        "deviceType",
        "pageType"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "VOzldlf3Ji", "61e98ceb4a273c75b0dae4852241c40421286c5cbac0e45d4be1114d712f0668", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("button_pressed", {
        "button_text": properties.buttonText,
        "button_type": properties.buttonType,
        "button_name": properties.buttonName,
        "button_value": properties.buttonValue,
        "screen_name": properties.screenName,
        "component": properties.component,
        "screen_details": properties.screenDetails,
        "destination": properties.destination,
        "experiment_name": properties.experimentName,
        "experiment_variation": properties.experimentVariation,
        "device_type": properties.deviceType,
        "page_type": properties.pageType,
        }, {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("VOzldlf3Ji", "button_pressed", messages, [
        {id: "0ZjM39aRO7", name: "destination", value: properties.destination},
        {id: "3dB3qQvbL", name: "device_type", value: properties.deviceType},
        {id: "3gyTDWP8y", name: "screen_details", value: properties.screenDetails},
        {id: "7-hb0S6NBb", name: "screen_name", value: properties.screenName},
        {id: "80_ryv9tLt", name: "button_value", value: properties.buttonValue},
        {id: "T3YH0BL9-n", name: "button_type", value: properties.buttonType},
        {id: "Uo6pFY11e3", name: "button_text", value: properties.buttonText},
        {id: "cMzkv_Qm1Q", name: "component", value: properties.component},
        {id: "l8x9M6i2L", name: "page_type", value: properties.pageType},
        {id: "qr1EucD2s", name: "experiment_name", value: properties.experimentName},
        {id: "rfMghFZJ2R", name: "button_name", value: properties.buttonName},
        {id: "uqmNDBGLL", name: "experiment_variation", value: properties.experimentVariation},
        ], [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'button_pressed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("button_pressed", {
          "button_text": properties.buttonText,
          "button_type": properties.buttonType,
          "button_name": properties.buttonName,
          "button_value": properties.buttonValue,
          "screen_name": properties.screenName,
          "component": properties.component,
          "screen_details": properties.screenDetails,
          "destination": properties.destination,
          "experiment_name": properties.experimentName,
          "experiment_variation": properties.experimentVariation,
          "device_type": properties.deviceType,
          "page_type": properties.pageType,
          }, "VOzldlf3Ji", "61e98ceb4a273c75b0dae4852241c40421286c5cbac0e45d4be1114d712f0668");
      }
      // destination RudderStackNewEvents
      RudderStackNewEvents.logEvent("button_pressed", {
      "button_text": properties.buttonText,
      "button_type": properties.buttonType,
      "button_name": properties.buttonName,
      "button_value": properties.buttonValue,
      "screen_name": properties.screenName,
      "component": properties.component,
      "screen_details": properties.screenDetails,
      "destination": properties.destination,
      "experiment_name": properties.experimentName,
      "experiment_variation": properties.experimentVariation,
      "device_type": properties.deviceType,
      "page_type": properties.pageType,
      });
    } else {
      // do nothing
    }
  }

  exports.ScreenName = ScreenName;
  exports.ButtonType = ButtonType;
  exports.Component = Component;
  exports.ButtonName = ButtonName;
  exports.setAvoLogger = setAvoLogger;
  exports.buttonPressed = buttonPressed;
  exports.setup_ = setup_;
  exports.initAvo = initAvo;
  exports.WebDebuggerPosition = WebDebuggerPosition;
  exports.avoInspectorApiKey = avoInspectorApiKey;
}(typeof exports === 'undefined' ? this.Avo = {} : exports));

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["buttonPressed"]
