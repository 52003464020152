<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered" :class="classes">
      <div class="modal-content" :style="showOverflow ? 'overflow: visible;' : ''">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="title"></slot>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('closeModal')"></button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['fullWidth', 'modalClass', 'showOverflow'],

  computed: {
    classes () {
      const addClasses = []
      if (this.fullWidth) addClasses.push('modal-dialog--full-width')
      if (this.modalClass) addClasses.push(this.modalClass)
      return addClasses.join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.modal {
  &-dialog {
    &--full-width {
      @include mq($from: xs) {
        max-width: 96vw !important;
      }
    }
  }
  &-body {
    overflow-y: auto;
    max-height: 70vh;
  }
  &-header, &-footer {
    background: rgba($stone-5, .25);
  }
}
</style>
