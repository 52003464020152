import {
  returnMissingFields,
  genericMissingFieldsMessage,
  validateCompanyNameLength,
} from "./methodsValidation";
import store from "@/store";
import { getContacts } from "@/store/modules/business-entities/getters";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// TX - Texas
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const validationLLCAnnualReportTX = (orderTask, txChangesEnabled) => {
  const messages = [];
  const businessEntity = orderTask?.order_task_data?.new_data?.business_entity_data;

  validateMissingDataTX(messages, businessEntity, txChangesEnabled);
  validateSomeFieldsTrueTX(messages, businessEntity, txChangesEnabled);
  validateTotalRevenueTX(messages, businessEntity, txChangesEnabled);
  return messages;
};

const validateMissingDataTX = (messages, businessEntity, txChangesEnabled) => {
  const fields = txChangesEnabled
    ? ['total_revenue', 'owns_any_other_business', 'is_owned_by_any_other_business']
    : [
      "is_tiered_partner_rev_adjusted",
      "is_combined_group",
      "is_passive_entity_tx",
      "total_revenue",
      "is_reit_tx",
      "is_new_vet_owned_tx",
      "owns_any_other_business",
      "is_owned_by_any_other_business",
    ];
  const missingFields = fields.filter(
    (field) =>
      businessEntity[field] == null || businessEntity[field].length === 0
  );
  if (missingFields.length > 0) {
    messages.push(
      formatMessageTX(
        "This order cannot be processed until the following questions have been answered",
        missingFields,
        businessEntity,
      )
    );
  }
};

const validateSomeFieldsTrueTX = (messages, businessEntity, txChangesEnabled) => {
  const fields = txChangesEnabled
  ? ['owns_any_other_business', 'is_owned_by_any_other_business']
  : [
    "is_tiered_partner_rev_adjusted",
    "is_combined_group",
    "is_new_vet_owned_tx",
    "owns_any_other_business",
    "is_owned_by_any_other_business",
  ];
  const trueFields = fields.filter((field) => businessEntity[field]);
  if (trueFields.length > 0) {
    messages.push(
      formatMessageTX(
        "This order must be processed manually because the customer answered “Yes” to the following questions",
        trueFields,
        businessEntity,
      )
    );
  }
};

const validateTotalRevenueTX = (messages, businessEntity, txChangesEnabled) => {
  let total = businessEntity["total_revenue"];
  if (total) {
    total = parseInt(total.replace(/,/g, ""), 10);
    if (total > (txChangesEnabled ? 2470000 : 1230000)) {
      messages.push(
        `This order cannot be processed because the customer has reported a total revenue of $${total.toLocaleString()},
      which exceeds the No Tax Limit of ${txChangesEnabled ? 2.47 : 1.23} million.`
      );
    }
  }
};

const formatMessageTX = (message, elements, businessEntity) => {
  const questionsJson = {
    "is_tiered_partner_rev_adjusted": "Will your total revenue be adjusted for the Tiered Partnership Election?",
    "is_combined_group": "Is this the reporting entity of a combined group?",
    "is_passive_entity_tx": "Is this a passive entity as defined in Chapter 171 of the Texas Tax Code?",
    "total_revenue": `What was the exact total revenue for ${businessEntity.year}?`,
    "is_reit_tx": "Is this entity a Real Estate Investment Trust (REIT) that meets the qualifications specified in section 171.0002(c)(4)?",
    "is_new_vet_owned_tx": "Is this entity a New Veteran-Owned Business as defined in Texas Tax Code Sec. 171.0005?",
    "owns_any_other_business": `Does ${businessEntity.business_entity_name} own 10% or more of any other business?`,
    "is_owned_by_any_other_business": `Does any other business own 10% or more of ${businessEntity.business_entity_name}?`,
  }
  const questions = elements.map((elem)=>{
    return questionsJson[elem]
  });
  return { main: message, items: questions};
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// NJ - New Jersey
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const validationLLCAnnualReportNJ = (orderTask, payload, contacts) => {
  if (!payload) {
    return [];
  }
  const messages = [];
  validateMissingDataNJ(messages, payload);
  validateManagementStructureLLCNJ(messages, payload, contacts);
  validateCompanyNamesNJ(messages, payload, contacts);
  validateNewDataFromOrderTask(messages, orderTask);
  validateFutureFilingDate(messages, orderTask);
  return messages;
}

export const validationCorpAnnualReportNJ = (orderTask, payload, contacts) => {
  if (!payload) {
    return [];
  }
  const messages = [];
  validateMissingDataNJ(messages, payload);
  validateManagementStructureCorpNJ(messages, payload, contacts);
  validateCompanyNamesNJ(messages, payload, contacts);
  validateNewDataFromOrderTask(messages, orderTask);
  validateFutureFilingDate(messages, orderTask);
  return messages;
}

const validateMissingDataNJ = (messages, payload) => {
  const fields = [
    "filing_number",
    "entity_type",
    "filing_date",
    "registered_agent.company_name",
    "address.address_1",
    "address.city",
    "address.state",
    "address.zip",
    "principal_address.address_1",
    "principal_address.city",
    "principal_address.state",
    "principal_address.zip",
    "worker_compensation_insurance_required",
    "authorized_signatory.first_name",
    "authorized_signatory.last_name",
    "authorized_signatory.title",
    "authorized_signatory.address_1",
    "authorized_signatory.city",
    "authorized_signatory.state",
    "authorized_signatory.zip",
  ];
  const missingFields = returnMissingFields(fields, payload);
  if (missingFields.length > 0) {
    const completeMessage = genericMissingFieldsMessage(missingFields);
    messages.push(completeMessage);
  }
}

const validateManagementStructureLLCNJ = (messages, payload, contacts) => {
  // Members validation
  if(payload.manager_type === 'all_owners' || payload.manager_type === 'some_owners') {
    const title = `This order cannot be processed because the Management Structure indicates that Members will be managing the entity`;
    const members =
      payload.members || getContacts(contacts, "member");
    if(members) {
      const fieldsFromMembers = [
        'address_1',
        'city',
        'state',
        'zip',
      ]
      const messagesFromMembers = []
      for(const member of members) {
        const messagesFromMember = [];
        if(!member['company_name'] && !(member['first_name'] && member['last_name'])) {
          messagesFromMember.push(`\t Member doesn't have neither company name or first name and last name.\n`);
        }
        const otherFields = returnMissingFields(fieldsFromMembers, member);
        const otherFieldsItems = otherFields.map((field) => `\tMember doesn't have ${field}`);
        if (otherFieldsItems.length > 0) {
          messagesFromMember.push(otherFieldsItems);
        }
        if(messagesFromMember.length>0) {
          messagesFromMembers.push(messagesFromMember.join('\n'));
        }
      }
      if (messagesFromMembers.length > 0) {
        messages.push({
          main:`${title}, but the following information is missing:`,
          items: messagesFromMembers,
        })
      }
    } else {
      messages.push(`${title}, but there aren't members assigned.`)
    }
  }
  if(payload.manager_type === 'some_owners') {
    const members =
      payload.members ||
      store.getters["businessEntities/getContactsByType"]("member");
    const areThereOnlyOneMember = members?.length > 0;
    const areThereAnyMemberAsManager = members.find((m)=>{m.manager===true})?true:false;
    if (areThereOnlyOneMember || !areThereAnyMemberAsManager) {
      messages.push(`This order cannot be processed because the Management Structure indicates that only some members will be managing, but there is only one member listed or there are no members designated as manager.`);
    }
  }
  // Managers validation
  if(payload.manager_type === 'all_owners' || payload.manager_type === 'some_owners') {
    const title = `This order cannot be processed because the Management Structure indicates that managers will be managing the entity`;
    const managers =
      payload.managers || getContacts(contacts, "manager");
    if(managers) {
      const fieldsFromManagers = [
        'address_1',
        'city',
        'state',
        'zip',
      ]
      const messagesFromManagers = []
      for(const manager of managers) {
        const messagesFromManager = [];
        if(!manager['company_name'] && !(manager['first_name'] && manager['last_name'])) {
          messagesFromManager.push(`\t Manager doesn't have neither company name or first name and last name.\n`);
        }
        const otherFields = returnMissingFields(fieldsFromManagers, manager);
        const otherFieldsItems = otherFields.map((field) => `\tManager doesn't have ${field}`);
        if (otherFieldsItems.length > 0) {
          messagesFromManager.push(otherFieldsItems);
        }
        if(messagesFromManager.length>0) {
          messagesFromManagers.push(messagesFromManager.join('\n'));
        }
      }
      if (messagesFromManagers.length > 0) {
        messages.push({
          main:`${title}, but the following information is missing:`,
          items: messagesFromManagers,
        })
      }
    } else {
      messages.push(`${title}, but there aren't managers assigned.`)
    }
  }
}

const validateManagementStructureCorpNJ = (messages, payload, contacts) => {
  // Directors validation
  const title = `This order cannot be processed`;
  const directors =
    payload.directors || getContacts(contacts, "director");
  if(directors) {
    const fieldsFromDirectors = [
      'address_1',
      'city',
      'state',
      'zip',
    ]
    const messagesFromDirectors = []
    for(const director of directors) {
      const messagesFromDirector = [];
      if(!director['company_name'] && !(director['first_name'] && director['last_name'])) {
        messagesFromDirector.push(`\t Director doesn't have neither company name or first name and last name.\n`);
      }
      const otherFields = returnMissingFields(fieldsFromDirectors, director);
      const otherFieldsItems = otherFields.map((field) => `\tDirector doesn't have ${field}`);
      messagesFromDirector.push(otherFieldsItems);
      if(messagesFromDirector.length>0) {
        messagesFromDirectors.push(messagesFromDirector.join('\n'));
      }
    }
    if(messagesFromDirectors.length>0) {
      messages.push(`${title} because the following information is missing:`);
      messages.push(messagesFromDirectors);
    }
  } else {
    messages.push(`${title}, there aren't directors assigned.`)
  }

  // Officers validation
  const officers =
    payload.officers || getContacts(contacts, "officer");
  if(officers) {
    const fieldsFromOfficers = [
      'address_1',
      'city',
      'state',
      'zip',
    ]
    const messagesFromOfficers = []
    for(const officer of officers) {
      const messagesFromOfficer = [];
      if(!officer['company_name'] && !(officer['first_name'] && officer['last_name'])) {
        messagesFromOfficer.push(`\t Officer doesn't have neither company name or first name and last name.\n`);
      }
      const otherFields = returnMissingFields(fieldsFromOfficers, officer);
      const otherFieldsItems = otherFields.map((field) => `\tOfficer doesn't have ${field}`);
      messagesFromOfficer.push(otherFieldsItems);
      if(messagesFromOfficer.length>0) {
        messagesFromOfficers.push(messagesFromOfficer.join('\n'));
      }
    }
    if(messagesFromOfficers.length>0) {
      messages.push(`${title} because the following information is missing:`);
      messages.push(messagesFromOfficers);
    }
  } else {
    messages.push(`${title}, there aren't officers assigned.`)
  }
}

const validateCompanyNamesNJ = (messages, payload, contacts) => {
  const MAX_LENGTH = 25;
  const managerType = payload.manager_type;
  if(managerType==='all_owners' || managerType==-'some_owners') {
    const members =
      payload.members || getContacts(contacts, "member");
    validateCompanyNameLength(messages, members, MAX_LENGTH);
  }
  validateCompanyNameLength(
    messages,
    payload.directors || getContacts(contacts, "director")
  );
  validateCompanyNameLength(
    messages,
    payload.officers || getContacts(contacts, "officer")
  );
};

const validateNewDataFromOrderTask = (messages, orderTask) => {
  const newData = orderTask.order_task_data?.new_data;
  const workerCompensationInsuranceRequired = newData?.worker_componesation_insurance_required;
  if (workerCompensationInsuranceRequired) {
    const isBusinessSelfInsured = newData?.is_business_self_insured;
    if(!isBusinessSelfInsured || isBusinessSelfInsured.length==0) {
      messages.push(`The field is_business_self_insured is not present.`);
    } else if(isBusinessSelfInsured===false) {
      const fields = [
        'insurance_company',
        'policy_number',
        'date_coverage_begin'
      ];
      const missingFields = returnMissingFields(fields, newData);
      if (missingFields.length > 0) {
        const completeMessage = genericMissingFieldsMessage(missingFields);
        messages.push(completeMessage);
      }
    }
  }
}

const validateFutureFilingDate = (messages, orderTask) => {
  if (orderTask.jurisdiction_future_filing_date) {
    messages.push(`This order must be manually processed because the entity was originally formed with a Future File Date (which ZB stores as the Filing Date). NJ requires the original date of submission when submitting an Annual Report, not the Future File Date. Please see the original formation evidence to determine the original date of submission.`);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// CA - California
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const validationLLCAnnualReportCA = (payload) => {
  if (!payload) {
    return [];
  }
  const messages = [];
  validateBusinessPurposeLengthCA(messages, payload)
  validateManagementStructureLLCCA(messages, payload)
  return messages;
};

const validateBusinessPurposeLengthCA = (messages, payload) => {
  const MAX_LENGTH = 50;
  const business_purpose = payload.business_purpose;
  if (business_purpose?.length > MAX_LENGTH) {
    const message = `Business purpose is currently ${business_purpose.length} characters long. It cannot exceed ${MAX_LENGTH} characters.`
    messages.push(message);
  }
};


const validateManagementStructureLLCCA = (messages, payload) => {
  const members = payload.members;
  const managers = payload.managers;
  const areThereAnyMemberAsManager = members.find((m)=>{m.manager===true})? true:false;
  const allMembersAreManagers = members.filter((m)=>m.manager===true)?.length === members.length;
  if(payload.manager_type === 'some_owners') {
    if (!areThereAnyMemberAsManager) {
      messages.push({
          main: `This order cannot be processed because the Management Structure indicates that Members will be managing the entity, but none of the Members are listed as Managers. Please either`,
          items: [
            `confirm Management Structure is “All the owners (Member managed)”`,
            `or add/adjust Members to include one or more Manager = “Yes”.`
        ]
      });
    }
    else if(allMembersAreManagers){
      messages.push({
        main: `This order cannot be processed because the Management Structure indicates that only some Members will be managing the entity, but all of the Members are listed as Managers. Please either`,
        items: [
          `confirm Management Structure is “All the owners (Member managed)”`,
          `or add/adjust Members to include one or more Manager = “Yes”.`
        ]
      });
    }
  }else if(payload.manager_type === 'some_managers' || payload.manager_type === 'all_managers') {
    if(managers?.length === 0 || !areThereAnyMemberAsManager){
      messages.push({
        main: `This order cannot be processed because the Management Structure indicates that Managers will be managing the entity, but there are no Managers listed. Please either`,
        items: [
          `confirm either Management Structure is not “All the owners (Member managed)” or “Only some of the owners (Manager managed)“`,
          `or add Managers.`
        ]
      });
    }
  }
}
