export default {
  jiraIssuePayload: {
    fields: {
      project: {
        id: '11214'
      },
      issuetype: {
        id: '10200'
      }
    }
  },
  jiraUserId: null,
  markDownText: '',
  requestErrors: []
};
