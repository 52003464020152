export function initialState() {
	return {
		deviceInfo: []
	}
}

export const state = {
	...initialState()
};

export default {};
