import { initialState } from './state.js'

export default {
  setAccountUUID: (state, accountUuid) => {
    state.order.account = accountUuid;
  },
  setBusinessEntityUUID: (state, businessEntityUUID) => {
    state.order.business_entity_uuid = businessEntityUUID;
  },
  setOrderStatus: (state, orderStatus) => {
    state.order.order.order_status = orderStatus;
  },
  setBusinessEntityName: (state, businessEntityName) => {
    state.businessEntityName = businessEntityName;
  },
  setBusinessEntityType: (state, businessEntityType) => {
    state.businessEntityType = businessEntityType;
  },
  setBusinessEntity: (state, businessEntity) => {
    state.businessEntity = businessEntity;
  },
  setDomainSelected: (state, domainSelected) => {
    state.domainSelected = domainSelected;
  },
  setJurisdiction: (state, jurisdiction) => {
    state.jurisdiction = jurisdiction;
    if (state.productPricing) {
      state.jurisdictionPricing = state.productPricing[jurisdiction];
    }
  },
  setdomainAvailable: (state, status) => {
    state.domainAvailable = status;
  },
  setSelectedProducts: (state, selectedProducts) => {
    state.selectedProducts = selectedProducts;
  },
  clearSelectedProducts: (state) => {
    state.selectedProducts = [];
  },
  setProducts: (state, products) => {
    state.allProducts = products;
    const allowedProducts = [];
    // only add products we support
    for (const product of products) {
      if ([
        's_corp_designation',
        'llc_amendment',
        'corp_amendment',
        'other',
        'worry_free_service',
        'registered_agent_service',
        'change_of_registered_agent',
        'standard_good_standing',
        'llc_annual_report',
        'operating_agreement',
        'basic_email',
        'domain_name_privacy',
        'domain_name_reg',
        'static_website',
        'website_tools_bundle',
        'corp_annual_report',
        'future_file_date',
        'extra_credits',
        'wfg_initial_report',
        'dba_entity',
        'dba_compliance',
        'tax_filing',
        'audit_protection',
        'tax_payment_assistance',
        'rush',
        'expedite',
        'ny_publication',
        'corporate_docs',
        'dissolution',
        'reinstatement',
        'resignation',
        'business_kit',
        'expense_tracking_with_tax_filing',
        'adjustment',
        'zb_money_pro',
        'zb_money_essential',
        'zb_banking_preferred',
        'ein_creation'
      ].includes(product.service)) {
        allowedProducts.push(product);
      }
    }

    // special case for Plan
    allowedProducts.push({
      service: 'plan',
      display_name: 'Plan',
      recurring: true
    });

    state.products = allowedProducts;
  },
  setPlans: (state, plans) => {
    state.plans = plans;
  },
  setProductPricing: (state, productPricing) => {
    state.productPricing = productPricing;
    if (state.jurisdiction) {
      state.jurisdictionPricing = state.productPricing[state.jurisdiction];
    }
  },
  setProductFees: (state, { fees, product }) => {
    state.productFees[product] = fees;
  },
  setProductPrices: (state, { prices, product }) => {
    state.productPrices[product] = prices;
  },
  clearProductPrices: (state) => {
    state.productPrices = {};
  },
  clearProductFees: (state) => {
    state.productFees = {};
  },
  setEntityName: (state, entityName) => {
    state.entity_name = entityName;
  },
  setPhone: (state, phone) => {
    state.phone = phone;
  },
  resetOrderData: (state) => {
    Object.assign(state, initialState());
  },
  setCoupons: (state, coupons) => {
    state.coupons = coupons;
  },
  setAnnualReportEligibility: (state, eligibility) => {
    state.annualReportEligibility = eligibility;
  },
  addValidationProduct: (state, validation) => {
    if(validation)
      state.validationProducts.push(validation);
  },
  updateValidationProduct: (state, validation) => {
    if(validation)
      state.validationProducts = state.validationProducts.map(value => value.serviceName === validation.serviceName ? validation : value);
  },
  removeValidationProduct: (state, validation) => {
    if(validation)
      state.validationProducts = state.validationProducts.filter(value => value.serviceName !== validation.serviceName);
  },
};
