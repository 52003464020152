import { FILING_SUBMISSION_STATUSES, FILING_SUBMISSION_REQUESTS, BUTTON_STATE } from '../../../assets/constants/bofFilings';

const FILING_STATUS_MAPPING = {
  [FILING_SUBMISSION_STATUSES.FILING_UPDATE_NOT_FOUND]: {
    beginButton: BUTTON_STATE.ENABLED,
    filingStatus: FILING_SUBMISSION_STATUSES.INCOMPLETE
  },
  [FILING_SUBMISSION_STATUSES.PENDING]: {
    beginButton: BUTTON_STATE.DISABLED,
    filingStatus: FILING_SUBMISSION_STATUSES.PENDING
  },
  [FILING_SUBMISSION_STATUSES.COMPLETED]: {
    beginButton: BUTTON_STATE.DISABLED,
    filingStatus: FILING_SUBMISSION_STATUSES.COMPLETED
  },
  [FILING_SUBMISSION_STATUSES.FAILED]: {
    beginButton: BUTTON_STATE.ENABLED,
    filingStatus: FILING_SUBMISSION_STATUSES.FAILED
  },
  [FILING_SUBMISSION_STATUSES.REJECTED]: {
    beginButton: BUTTON_STATE.ENABLED,
    filingStatus: FILING_SUBMISSION_STATUSES.REJECTED
  }
};

const BEGIN_BUTTON_STATUS_MAPPING = {
  [FILING_SUBMISSION_REQUESTS.PROCESSING]: {
    beginButton: BUTTON_STATE.DISABLED,
  }
}

export default {
  setBofLatestFilingStatus: (state, filingStatus) => {
    const mapping = FILING_STATUS_MAPPING[filingStatus] || {};
    state.beginButton = mapping.beginButton !== undefined ? mapping.beginButton : state.beginButton;
    state.filingStatus = mapping.filingStatus || state.filingStatus;
  },
  setBeginButtonStatus: (state, beginButtonStatus) => {
    const mapping = BEGIN_BUTTON_STATUS_MAPPING[beginButtonStatus] || {};
    if(state.filingStatus === FILING_SUBMISSION_STATUSES.PENDING) {
      state.beginButton = BUTTON_STATE.DISABLED;
    }
    state.beginButton = mapping.beginButton !== undefined ? mapping.beginButton : state.beginButton;
  },
  setFilingNotes: (state, filingNotes) => {
   filingNotes !== '' ? state.filingNotes = JSON.stringify(filingNotes) : state.filingNotes = '';
  }
};
