import httpclient from '../../httpclient';
import messages from '../../../assets/messages';
import { httpRequestErrorHandling, validateParams } from '../utils';

export const actions = {
    fetchDomains({ commit }, { businessEntityUuid }) {
      if (!validateParams.UUID(businessEntityUuid)) {
        return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
      } else {
        commit('setDomains', []);
        return httpclient.get(`/api/v2/domains`,
          { params: { business_entity_uuid: businessEntityUuid } })
          .then((response) => {
            commit('setDomains', response.data);
          })
          .catch((e) => { httpRequestErrorHandling(e, commit) });
      }
    },
    fetchEmails({ commit }, { businessEntityUuid }) {
      if (!validateParams.UUID(businessEntityUuid)) {
        return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
      } else {
        commit('setEmails', []);
        return httpclient.get(`/api/domains/emails/business-entity/${businessEntityUuid}`)
          .then((response) => {
            commit('setEmails', response.data);
          })
          .catch((e) => { httpRequestErrorHandling(e, commit) });
      }
    },
  };

export default {};
