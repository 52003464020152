import Vue from 'vue';

export default {
  setAutomationPayload: (state, params ) => {
    const { payload, orderTask } = params;
    const orderTaskType = orderTask?.order_task_type;
    if(orderTaskType) {
      Vue.set(state.payload, orderTaskType, payload);
    }
  },
  clearAutomationPayload: (state, orderTaskType) => {
    if(orderTaskType) {
      state.payload[orderTaskType] = null;
      state.automationMessages[orderTaskType] = null;
    } else {
      state.payload = {};
      state.automationMessages = {};
    }
  },
};
