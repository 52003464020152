import httpclient from '../../httpclient';
import messages from '../../../assets/messages';
import { validateParams, httpRequestErrorHandling } from '../utils';
import authService from '../../../auth/AuthService';

export default {
    fetchPartnerRequests ({ commit }, { uuid }) {
      if(!validateParams.UUID(uuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
      } else {
        return httpclient.get('/api/partner-request/order/' + uuid)
          .then(response => {
            commit('setPartnerRequests', response.data)
          })
          .catch((error) => {
            commit('setPartnerRequests', [])
            httpRequestErrorHandling(error, commit)
          });
      }
    },
    sendPartnerRequest({ commit }, { partnerRequestUuid }) {
      if(!validateParams.UUID(partnerRequestUuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
      } else {
        return httpclient.get(`/api/partner-request/${partnerRequestUuid}/send`)
          .then(() => {
            commit('setAlertMessage', messages.sendPartnerRequest.success, { root: true });
            location.reload();
          })
          .catch((error) => {
            commit('setAlert', true, { root: true });
            if (error.response.data.includes('Error Message:')) {
              messages.sendPartnerRequest.responseError.primary = error.response.data.split('Error Message:')[1].trim();
              commit('setAlertMessage', messages.sendPartnerRequest.responseError, { root: true });
            } else {
              httpRequestErrorHandling(error, commit, messages.sendPartnerRequest)
            }
          });
      }
    },
    completeSendPartnerRequest({ commit }, { partnerRequestUuid }) {
      if(!validateParams.UUID(partnerRequestUuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
      } else {
        return httpclient.post(`/api/partner-request/${partnerRequestUuid}/send-complete`)
          .then(() => {
            commit('setAlertMessage', messages.sendPartnerRequest.success, { root: true });
            location.reload();
          })
          .catch((e) => {httpRequestErrorHandling(e, commit, messages.sendPartnerRequest)});
      }
    },
    repairDomain({ commit }, { pr_uuid, domainName }) {
      if(!validateParams.UUID(pr_uuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
      } else {
        return httpclient.post(`/api/partner-request/${pr_uuid}/repair`, { domain_name: domainName })
          .then(() => {
            commit('setAlertMessage', messages.repairDomain.success, { root: true });
            location.reload();
          })
          .catch((error) => {
            httpRequestErrorHandling(error, commit, messages.repairDomain)
          });
      }
    },
    repairDomainRefresh({ commit }, { pr_uuid, order_uuid }) {
      if(!validateParams.UUID(pr_uuid) || !validateParams.UUID(order_uuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
      } else {
        return httpclient.post(`/api/partner-request/${pr_uuid}/repair_refresh`, { order_uuid: order_uuid })
          .then(() => {
            commit('setAlertMessage', messages.repairDomain.success, { root: true });
            location.reload();
          })
          .catch((error) => {
            httpRequestErrorHandling(error, commit, messages.repairDomain)
          });
      }
    },
    resetPartnerRequest({ commit }, { uuid }) {
      if(!validateParams.UUID(uuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
      } else {
        return httpclient.post(`/api/partner-request/${uuid}/reset`)
          .then(() => {
            commit('setAlertMessage', messages.resetPartnerRequest.success, { root: true });
            location.reload();
          })
          .catch((e) => {httpRequestErrorHandling(e, commit, messages.resetPartnerRequest)});
      }
    },
    fetchPartnerRequestsDomain({ commit }, { uuid, partnerType = 'domain_name_reg' }) {
      if(!validateParams.UUID(uuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
      } else {
        return httpclient.get(`/api/partner-request/business_entity/${uuid}?partner_type=${partnerType}`)
          .then((response) => {
            commit('setPartnerRequestDomain', response.data);
          })
          .catch((e) => {httpRequestErrorHandling(e, commit)});
      }
    },
    processExchangePartnerRequest({ commit }, { partnerRequestUUID1, partnerRequestUUID2 }) {
      if(!(validateParams.UUID(partnerRequestUUID1) && validateParams.UUID(partnerRequestUUID2))) {
        httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
      } else {
        return httpclient.post('/api/partner-request/exchange_order_task', {
          partner_request_uuid_1: partnerRequestUUID1,
          partner_request_uuid_2: partnerRequestUUID2,
          exchanged_by: authService.getLoggedInEmail()
        })
          .then(() => {
            commit('setAlertMessage', messages.exchangePartnerRequestDomain.success, { root: true });
          })
          .catch((e) => {httpRequestErrorHandling(e, commit, messages.exchangePartnerRequestDomain)});
      }
    },
    fetchBankingPartnerRequests({ commit }, { uuid }) {
      if(!validateParams.UUID(uuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
      } else {
        return httpclient.get(`/api/partner-request/order/${uuid}`)
        .then((response) => {
          commit('setPartnerRequests', response.data);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
      }
    },
    validateBankingOrder({ commit }, { partnerRequestUuid }) {
      if(!validateParams.UUID(partnerRequestUuid)) {
        httpRequestErrorHandling(new Error(messages.validationUUID), commit, messages.validationUUID)
      } else {
        return httpclient.get(`/api/v2/partner-request/${partnerRequestUuid}/validation`)
        .catch((e) => {httpRequestErrorHandling(e, commit)});
      }
    }
  };
