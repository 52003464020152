<template>
  <div class="col-12 col-md-10 col-lg-6 mx-auto mt-5">
    <CircleLogoTeal class="mb-5" logoSize="60"/>
    <div class="mb-5">
      <h1 class="login-title px-0 text-center">Log in</h1>
      <h2 class="login-sub-title mt-2">Welcome to ZenGarden, have a great day!</h2>
    </div>
    <div>
      <form @submit.prevent="handleOktaRedirect">
        <ZgButton class="my-3 zg-btn-default text-upper fullwidth" type="submit">
          <template v-slot:title>
            Log in with Okta
          </template>
        </ZgButton>
      </form>
    </div>
  </div>
</template>

<script>
import CircleLogoTeal from '@/components/common/images/CircleLogoTeal.vue';
import ZgButton from '../common/Generics/ZgButton.vue';

export default {
  name: 'Login',

  components: {
    CircleLogoTeal,
    ZgButton
  },

  props: ['auth'],

  methods: {
    handleOktaRedirect () {
      const opts = {}
      if (this.$route.query.redirectTo) {
        opts.redirectTo = this.$route.query.redirectTo
      }
      this.auth.universalLogin(opts)
    }
  }
};
</script>
<style lang="scss" scoped>
  .login-title {
    font-size: rem(40);
    line-height: em(38, 40);
  }
  .login-sub-title {
    font-size: rem(18);
    line-height: em(24, 18);
    color: $stone-4;
  }
</style>
