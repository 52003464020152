export const state = {
    businessEntities: [],
    businessEntity: {
      business_entity_data: {
        address: {},
        registered_agent: {},
        contact: {},
        authorized_signatory: {}
      },
      business_industry: ''
    },
    businessEntityTasks: [],
    addresses: [],
    contacts: [],
    companyApplicants: [],
    directors: [],
    beneficialOwners: [],
    managers: [],
    members: [],
    officers: [],
    primaryContact: {},
    responsibleParty: {},
    sopContact: [],
    registeredAgents: [],
    fkas: [],
    jurisdictions: [],
    documents: [],
    eventConfigs: {
      AK: [], AL: [], AZ: [], AR: [], CA: [], CO: [], CT: [], DE: [], DC: [], FL: [], GA: [], HI: [], ID: [], IL: [], IN: [],
      IA: [], KS: [], KY: [], LA: [], ME: [], MD: [], MA: [], MI: [], MN: [], MS: [], MO: [], MT: [], NE: [], NV: [], NH: [],
      NJ: [], NM: [], NY: [], NC: [], ND: [], OH: [], OK: [], OR: [], PA: [], RI: [], SC: [], SD: [], TN: [], TX: [], UT: [],
      VT: [], VA: [], WA: [], WV: [], WI: [], WY: []
    },
    creditBalances: [],
    entityFilingChanges: [],
    contactTypes: [],
    complianceEvents: [],
    audit_records: {},
    raTrackingRecordsByJurisdiction: {}
  };

export default {};
